import { useState, useEffect } from "react"
import { projectAuth, googleProvider, facebookProvider } from "../firebase/config"
import { useAuthContext } from "./useAuthContext"
import usePrompt from "./usePrompt"
import {firebaseAuth} from '../firebase/config'


export const useLogin =()=>{
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    // const [message, setMessage] = useState(null)
    const [isPending, setIsPending] = useState(null)
    const { dispatch, user} = useAuthContext()

    const { prompt, promptChoice, promptPassword } = usePrompt()

    

    const getProviderForProviderId =(providerId)=>{
        console.log(providerId)
        switch (providerId) {
            case 'facebook.com':
                return facebookProvider
            case 'google.com':
                return googleProvider

            default:
                break;
        }
    }

    const login = async (email, password)=>{
        setError(null)
        setIsPending(true)

        try{
            const response = await projectAuth.signInWithEmailAndPassword(email, password) 
    
            if(!response){
                throw new Error('Could not complete Login')
            }

            // dispatch login action
            dispatch({
                type: 'LOGIN',
                payload: response.user
            })

            if(!isCancelled){
                setIsPending(false)
                setError(null)
            }

        } catch (error) {
            if(!isCancelled){
                console.log(error.message)
                setError(error.message)
                setIsPending(false)
  
            }
        }

        
    }

    const phoneLogin =(phoneNumber, appVerifier)=>{
        setError(null)
        setIsPending(true)

        projectAuth.signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            // const code = window.prompt('Enter OTP');

            // console.log(confirmationResult)

            promptPassword({title: 'Please enter the Code you received from us.'})
            .then(code => {
                
                // console.log('AAAA', code)
                // const credential = projectAuth.PhoneAuthProvider.credential(confirmationResult.verificationId, code);
                // projectAuth.PhoneAuthProvider.credential(confirmationResult.verificationId, code)
                // .then(credential => {
                //     console.log('AAAA', credential)
                // })
                var credential = firebaseAuth.PhoneAuthProvider.credential(confirmationResult.verificationId, code);

                user.linkWithCredential(credential).then(function(usercred) {
                    dispatch({
                        type: 'LOGIN',
                        payload: user
                    })
        
                    if(!isCancelled){
                        setIsPending(false)
                        setError(null)
                    }
                });
            })
            .catch(function(error) {
                console.log(error)
                throw new Error('Could not complete Login' + error.message)
            });
            
            // confirmationResult
            // .confirm(code)
            // .then((result) => {
            //     const user = result.user;
            //     console.log('user is signed in..', user)

            // }).catch((error) => {
            // // User couldn't sign in (bad verification code?)
            // // ...
            // });

        }).catch((error) => {
        // Error; SMS not sent
        // ...
        });
    }

    const socialLogin = async (provider)=>{
        setError(null)
        setIsPending(true)

        try{
            const response = await projectAuth.signInWithPopup(provider)

            console.log('STARTING LOGIN')
            // var credential = response.credential;
            // // This gives you a Google Access Token. You can use it to access the Google API.
            // var token = credential.accessToken;
            // // The signed-in user info.
            // var user = result.user;


    
            if(!response){
                throw new Error('Could not complete Login')
            }

            console.log(response);

            // dispatch login action

            dispatch({
                type: 'LOGIN',
                payload: response.user
            })

            if(!isCancelled){
                setIsPending(false)
                setError(null)
            }

            console.log(response.user)

            // projectAuth.fetchSignInMethodsForEmail(email).then(data=>{
            //     console.log(data);
                
            // })


        } catch (error) {
            if(!isCancelled){
                console.log(error.message)
                if(error.code === 'auth/internal-error'){
                    setError('Something went wrong.. Please try again')
                    setIsPending(false)
                }else{
                    setError(error.message)
                    setIsPending(false)
                }
            }

            console.log(error)

            if (error.code === 'auth/account-exists-with-different-credential') {
                // Step 2.
                // User's email already exists.
                // The pending Google credential.
                var pendingCred = error.credential;
                // The provider account's email address.
                var email = error.email;
                // Get sign-in methods for this email.
                projectAuth.fetchSignInMethodsForEmail(email).then(function(methods) {
                    
                    console.log('PROVIDER METHODS', methods);
                    // Step 3.
                    // If the user has several sign-in methods,
                    // the first method in the list will be the "recommended" method to use.
                    if (methods[0] === 'password') {
                    // Asks the user their password.
                        // setMessage("You Already have an account with us using this email" + email + ". Please provide a password to link them.")
                        console.log("You Already have an account with us using this email" + email + ". Please provide a password to link them.")
                        // In real scenario, you should handle this asynchronously.

                        // TODO: implement promptUserForPassword.
                        promptPassword({title: "Theres already an account with us using this email " + email + ". Please provide the password to link them."})
                        .then(password => {
                            // var password = ""; // TODO: implement promptUserForPassword.
                            projectAuth.signInWithEmailAndPassword(email, password).then(function(result) {
                                // Step 4a.
                                return result.user.linkWithCredential(pendingCred);
                            }).then(function(result) {
                                // Google account successfully linked to the existing Firebase user.
                                // goToApp();
                                dispatch({
                                    type: 'LOGIN',
                                    payload: result.user
                                })

                                if(!isCancelled){
                                    setIsPending(false)
                                    setError(null)
                                }
                            })
                            .catch(function(error) {
                                throw new Error('Could not complete Login' + error.message)
                            });
                        })
                        .catch(function(error) {
                            throw new Error('Could not complete Login' + error.message)
                        });

                        return;
                    }
                    // All the other cases are external providers.
                    // Construct provider object for that provider.
                    // TODO: implement getProviderForProviderId.
                    // var provider = getProviderForProviderId(methods[0]);
                    var provider = getProviderForProviderId(methods[0]);
                    
                    // At this point, you should let the user know that they already have an account
                    // but with a different provider, and let them validate the fact they want to
                    // sign in with this provider.
                    // Sign in to provider. Note: browsers usually block popup triggered asynchronously,
                    // so in real scenario you should ask the user to click on a "continue" button
                    // that will trigger the signInWithPopup.
                    // setMessage("You Already have an account with us using this provider. Would you like to link them?")
                    // promptChoice("You Already have an account with us using this provider. Would you like to link them?")
                    console.log("You Already have an account with us using this provider. Would you like to link them?")
                    promptChoice({title: "You Already have an account with us using this provider. Would you like to link them?"})
                    .then(() => {
                        projectAuth.signInWithPopup(provider).then(function(result) {
                            // Remember that the user may have signed in with an account that has a different email
                            // address than the first one. This can happen as Firebase doesn't control the provider's
                            // sign in flow and the user is free to login using whichever account they own.
                            // Step 4b.
                            // Link to Google credential.
                            // As we have access to the pending credential, we can directly call the link method.
    
                            // used linkWithCredential instead of linkAndRetrieveDataWithCredential
                            result.user.linkWithCredential(pendingCred).then(function(usercred) {
                                // Google account successfully linked to the existing Firebase user.
                                // goToApp();
                                dispatch({
                                    type: 'LOGIN',
                                    payload: result.user
                                })
                    
                                if(!isCancelled){
                                    setIsPending(false)
                                    setError(null)
                                }
                            });
                        }).catch(error => {
                            throw new Error('Could not complete Login. ' + error.message)
                        });
                    }).catch(() => {
                        throw new Error('Could not complete Login')
                    });


                    
                });
            }

        }

        
    }

    const googleLogin = async ()=>{
        socialLogin(googleProvider)
    }

    const facebookLogin = async ()=>{
        socialLogin(facebookProvider)
        // setup facebook app
        //  go to https://developers.facebook.com/
        // create app, and get appid and secret 
        // on firebase auth, paste in appid and app secret to facebook proivider settings, then copy the OAuth URI
        // go back to facebook app then get facebook login product and paste the OAuth URI to "Valid OAuth Redirect URIs" input on the app
        
    }






    useEffect(() => {
            
        return () => {
            // cleanup
            return ()=>{
                setIsCancelled(true)
            }
        };
    }, [])


    return { error, isPending, phoneLogin, login, googleLogin, facebookLogin, prompt }

}