import { useRef } from 'react'
import ReactDOM from 'react-dom'


import './Modal.css'

export default function Modal({ children, closeModal }) {

    const modalRef = useRef(null)

    const handleClick =e=>{
        if(e.target !== modalRef.current){
            return
        }else{
            closeModal();
        }
    }

    return ReactDOM.createPortal((
        <div onClick={handleClick} className="modal-container-backdrop" ref={modalRef}>
            <div className="modal-container">
                <img onClick={closeModal} src="/icons/close_black_48dp.svg" alt="" className="modal-close" />
                {children}
            </div>
        </div>
    ), document.getElementById('modal'))
}


