import { useReducer, useEffect, useState } from "react";
import { projectFirestore, projectStorage, timestamp } from "../firebase/config";


let initState = {
    document: null,
    isPending: false,
    error: null,
    success: null
}

const firestoreReducer =(state, action)=>{
    console.log('DINC', action)
    switch (action.type) {
        case 'IS_PENDING':
            return {isPending: true, document: null, success: false, error: null}
        case 'ADDED_DOC':
            return { isPending: false, document: action.payload, success: true, error: null}
        case 'DELETED_DOC':
            return { isPending: false, document: null, success: true, error: null}
        case 'UPDATED_DOC':
            return { isPending: false, document: action.payload, success: true, error: null}
        case 'ERROR':
            return { isPending: false, document: null, success: true, error: action.payload}
    
        default:
            return state
    }

}

export const useFirestore =(collection)=>{
    const [response, dispatch] = useReducer(firestoreReducer, initState)
    const [isCancelled, setIsCancelled] = useState(false)

    //collection ref
    const store = projectFirestore.collection(collection)

    // only Dispatch If Not Cancelled
    const DINC = action =>{
        if(!isCancelled){
            dispatch(action)
        }
    }

    // adding document
    const addDocument = async doc =>{
        dispatch({
            type: 'IS_PENDING'
        })

        try {
            const createdAt = timestamp.fromDate(new Date())
            const addedDoc = await store.add({...doc, createdAt})
            DINC({
                type: 'ADDED_DOC',
                payload: addedDoc
            })
            
        } catch (err) {
            DINC({
                type: 'ERROR',
                payload: err.message
            })
        }
    }

    const setDocument = async (id, doc) =>{
        dispatch({
            type: 'IS_PENDING'
        })

        try {
            const createdAt = timestamp.fromDate(new Date())
            const addedDoc = await store.doc(id).set({...doc, createdAt})
            DINC({
                type: 'ADDED_DOC',
                payload: addedDoc
            })
            
        } catch (err) {
            DINC({
                type: 'ERROR',
                payload: err.message
            })
        }
    }

    const updateDocument = async (id, doc) =>{
        return new Promise((res, rej)=>{
            dispatch({
                type: 'IS_PENDING'
            })
            store.doc(id).update(doc).then(updatedDoc => {
                DINC({
                    type: 'UPDATED_DOC',
                    payload: updatedDoc
                })
                res(updatedDoc)
            })
            .catch(err => {
                DINC({
                    type: 'ERROR',
                    payload: err.message
                })
                rej()
            })
        });
    }

    const deleteDocument = async id => {
        dispatch({
            type: 'IS_PENDING'
        })

        try {
            
            await store.doc(id).delete()

            DINC({
                type: 'DELETED_DOC'
            })
            
        } catch (err) {
            DINC({
                type: 'ERROR',
                payload: err.message
            })
        }

    }

    const addFile = async (path, file) =>{
        
        return new Promise((res, rej)=>{
            dispatch({
                type: 'IS_PENDING'
            })
            projectStorage.ref(path).put(file)
            .then(img => {
                img.ref.getDownloadURL()
                .then(url => {
                    DINC({
                        type: 'ADDED_FILE',
                        payload: url
                    })
                    res(url)
                })
            })
            .catch(err => {
                DINC({
                    type: 'ERROR',
                    payload: err.message
                })
                rej(err.message)
            })
        });

    }

    const deleteFile = async url => {
        dispatch({
            type: 'IS_PENDING'
        })
        return new Promise((res, rej)=>{
            projectStorage.refFromURL(url).delete()
            .then(()=>{
                DINC({
                    type: 'DELETED_FILE'
                })
                res()
            })
            .catch(err => {
                DINC({
                    type: 'ERROR',
                    payload: err.message
                })
                rej()
            })
        });

    }

    const testUsernameExists = async (username) => {
        return new Promise((res, rej)=>{
            const ref = projectFirestore.collection('userinfo').where('username', '==', username)

            try {
                ref.get().then(doc => {
                    // console.log(doc.docs)
                    if(doc.docs.length > 0){
                        res(doc.docs[0].data())
                    }else{
                        rej(true)
                    }
                })
            } catch (error) {
                rej(false)
            }

        });
    }

    // const getMultiItems = async (items) => {
    //     let documentRef = []
    //     return new Promise((res, rej)=>{
    //         try {
    //             items.forEach(item => {
    //                 documentRef.push(projectFirestore.doc(`${collection}/${item.itemid}`))
    //             })
                
    //             console.log(documentRef)
    //             const query = projectFirestore.collection(collection)



    //         } catch (error) {
    //             rej(error.message)
    //         }
    //     });
    // }


    useEffect(() => {

        return () => setIsCancelled(true)
    }, [])

    return {addDocument, setDocument, deleteDocument, updateDocument, addFile, deleteFile, testUsernameExists, response}

}