
import { useIsMobile } from '../../hooks/useIsMobile'
import { FaCheckCircle, FaSearch, FaTimes, FaBellSlash } from "react-icons/fa";
import { useAuthContext } from '../../hooks/useAuthContext';
import { useRef } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { useChikkas } from '../../model/useChikkas';

export default function ChikkaList({ dispatch, state }) {
    const { isMobile } = useIsMobile()
    const { user } = useAuthContext()
    // console.log(chikkas)

    const { chikkas, readAllMessages } = useChikkas()

    const handleChikkaClick =(chikka)=>{
        dispatch({type: 'SET_CURRENT_MESSAGE', payload: {id: chikka.id, type: 'item'} })
        // console.log('state.currentMessage', chikka.id)
        readAllMessages(chikka.id)
    }


    return (
        <div className="chikka-list flex-col-center-start">

            <div className="search">
                <div className="search-con flex-row-center-start">
                    <input type="text" placeholder='Search' />
                    <FaSearch />
                </div>
            </div>
            <div  className="widget-container flex-col-center-start">

                {chikkas && chikkas.map(chikka => {
                    chikka.messages.sort((a, b)=> {return new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate())})
                    const chatLogo = chikka.owner === user.uid ? chikka.userinfo[chikka.buyer].photoURL : chikka.userinfo[chikka.businessid].photoURL
                    const businessLogo = chikka.owner === user.uid ? chikka.userinfo[chikka.businessid].photoURL : null
                    const chatName = chikka.owner === user.uid ? chikka.userinfo[chikka.buyer].displayName : chikka.userinfo[chikka.businessid].displayName
                    const showMessage = chikka.messages[0]
                    const _lastMsgTime = showMessage && formatDistanceToNow(showMessage.createdAt.toDate(), {includeSeconds: true}).split(' ')

                    const t = _lastMsgTime && _lastMsgTime[_lastMsgTime.length - 1]
                    let ts
                    switch (t) {
                        case 'second':
                            ts = 's'
                            break;
                        case 'seconds':
                            ts = 's'
                            break;
                        case 'minute':
                            ts = 'm'
                            break;
                        case 'minutes':
                            ts = 'm'
                            break;
                        case 'hour':
                            ts = 'h'
                            break;
                        case 'hours':
                            ts = 'h'
                            break;
                        case 'day':
                            ts = 'd'
                            break;
                        case 'days':
                            ts = 'd'
                            break;
                        case 'month':
                            ts = 'm'
                            break;
                        case 'months':
                            ts = 'm'
                            break;
                        case 'year':
                            ts = 's'
                            break;
                        case 'years':
                            ts = 'y'
                            break;
                    
                        default:
                            break;
                    }
                    const lastMsgTime = _lastMsgTime && `${_lastMsgTime[_lastMsgTime.length - 2]}${ts}`
                    const readState = chikka.messages.length > 0 && chikka.messages[0].read.includes(user.uid) ? "read" : "unread"

                    const fmsg = chikka.messages[0]
                    {/* console.log(chikka.messages, fmsg) */}
                    let indicator
                    if(chikka.mute){
                        indicator = <FaBellSlash className='icon-indicator error' />
                    }if(chikka.messages.length > 0){
                        if(!fmsg.read.includes(user.uid)){
                            indicator = <div className="dot-indicator unread"></div>
                        }

                        if(fmsg.sender === user.uid){
                            if( user.uid === chikka.buyer ){
                                if(!fmsg.read.includes(chikka.owner)){
                                    indicator = <FaCheckCircle className='icon-indicator error' />
                                }else{
                                    indicator = <img className='image-indicator' src={chikka.userinfo[chikka.businessid].photoURL} alt="" />
                                }
                            }else{
                                if(!fmsg.read.includes(chikka.buyer)){
                                    indicator = <FaCheckCircle className='icon-indicator error' />
                                }else{
                                    indicator = <img className='image-indicator' src={chikka.userinfo[chikka.buyer].photoURL} alt="" />
                                }
                            }
                        }
                    }
                    
                    return(
                        <div key={chikka.id} onClick={()=> handleChikkaClick(chikka)} className={`list-widget flex-row-center-between ${readState} ${state.currentMessage === chikka.id ? "active" : "" }`}>
                            <img className='chat-logo' src={chatLogo} alt="" />
                            {businessLogo && <img className='business-logo' src={businessLogo} alt="" />}
                            <div className="details flex-col-start-center">
                                <span className="title">{chatName}</span>
                                <div className="subtitle flex-row-center-between">
                                    <span className={`message ${chikka.assignedUsers.length < 2 && "error"}`}>{chikka.assignedUsers.length < 2 ? chikka.completed ? "user closed the order" : "user cancelled the order" : chikka.messages.length > 0 ? showMessage.message : "conversation start" }</span>
                                    <p className="date">&bull; {lastMsgTime}</p>
                                </div>
                                {/* <div className="user-icons flex-row-center-start">
                                    <img src="/sampleuser.png" alt="" />
                                    <img src="/sampleuser.png" alt="" />
                                    <img src="/sampleuser.png" alt="" />
                                    <img src="/sampleuser.png" alt="" />
                                </div> */}
                            </div>
                            <div className="indicators flex-col-center-even">
                                {indicator}
                            </div>
                        </div>
                    )
                })}

                {/* <div className="list-widget flex-row-center-between active">
                    <img className='chat-logo' src="/samplefood.jpg" alt="" />
                    <div className="details flex-col-start-center">
                        <span className="title">Chookan Bucket Group</span>
                        <div className="subtitle flex-row-center-between">
                            <span className="message">KanChookan Item Name very longs</span>
                            <p className="date">&bull; 16h</p>
                        </div>
                        <div className="user-icons flex-row-center-start">
                            <img src="/sampleuser.png" alt="" />
                            <img src="/sampleuser.png" alt="" />
                            <img src="/sampleuser.png" alt="" />
                            <img src="/sampleuser.png" alt="" />
                            <img src="/sampleuser.png" alt="" />
                            <img src="/sampleuser.png" alt="" />
                            <img src="/sampleuser.png" alt="" />
                            <span className='subtitle'>+2 more</span>
                        </div>
                    </div>
                    <div className="indicators flex-col-center-even">
                        <div className="dot-indicator unread"></div>
                        <img className='image-indicator' src="/sampleuser.png" alt="" />
                        <FaCheckCircle className='icon-indicator error' />
                    </div>
                </div> */}

            </div>

        </div>
    )
}
