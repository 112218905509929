import './BusinessManage.css'

import { useParams } from 'react-router'
import { useState } from 'react'
// import { rngPassword } from '../../../helper/helper'
import { useIsMobile } from '../../../hooks/useIsMobile'

import BusinessManageAbout from './BusinessManageAbout'
import BusinessManageCategory from './BusinessManageCategory'
import BusinessManageItems from './items/BusinessManageItems'
import BusinessManageReviews from './BusinessManageReviews'
import BusinessManagePromotions from './BusinessManagePromotions'

// import { useFirestore } from '../../../hooks/useFirestore'
// import { useAuthContext } from '../../../hooks/useAuthContext'

// import { useCollection } from '../../../hooks/useCollection'
import { useDocument } from '../../../hooks/useDocument'
// import { useNavigate } from 'react-router-dom';




export default function BusinessManage() {

    const [activeTab, setActiveTab] = useState('items')
    const { businessid } = useParams()

    const { isMobile } = useIsMobile()


    const { document, error } = useDocument('business', businessid)

    return (
        <div className="business-manage-main-container flex-col-center-start">
            <div id="business-manage" className=" content-widget flex-col-center-start ">
                {!isMobile && 
                    <div className="nav flex-row-center-between w100">
                        <span className={activeTab === "about" ? "active" : ""} onClick={()=> setActiveTab('about')} >About</span>
                        <span className={activeTab === "category" ? "active" : ""} onClick={()=> setActiveTab('category')} >Category</span>
                        <span className={activeTab === "items" ? "active" : ""} onClick={()=> setActiveTab('items')} >Items</span>
                        <span className={activeTab === "promotions" ? "active" : ""} onClick={()=> setActiveTab('promotions')} >Promotions</span>
                        <span className={activeTab === "reviews" ? "active" : ""} onClick={()=> setActiveTab('reviews')} >Reviews</span>
                    </div>
                }
                {isMobile && 
                    <div className="nav flex-row-center-even w100 mobile">
                        {/* <a onClick={()=> setActiveTab('about')}  className="active">About</a>
                        <a onClick={()=> setActiveTab('category')} >Category</a>
                        <a onClick={()=> setActiveTab('items')} >Items</a>
                        <a onClick={()=> setActiveTab('reviews')} >Reviews</a> */}
                        <img className={` ${activeTab === "reviews" ? "active" : "" }`} onClick={ ()=> setActiveTab('reviews') } src="/icons/reviews_white_48dp.svg" alt="" />
                        <img className={` ${activeTab === "promotions" ? "active" : "" }`} onClick={()=> setActiveTab('promotions')} src="/icons/campaign_white_48dp.svg" alt="" />
                        <img className={` ${activeTab === "about" ? "active" : "" } super`} onClick={()=> setActiveTab('about')} src="/icons/store_white_48dp.svg" alt="" />
                        <img className={` ${activeTab === "category" ? "active" : "" }`} onClick={()=> setActiveTab('category')} src="/icons/menu_book_white_48dp.svg" alt="" />
                        <img className={` ${activeTab === "items" ? "active" : "" }`} onClick={()=> setActiveTab('items')} src="/icons/category_white_48dp.svg" alt="" />

                        {/* <a onClick={()=> setActiveTab('save')} >Save</a> */}
                    </div>
                }
                <br />
                    {document && !error && <>
                        {activeTab === 'about' ? <BusinessManageAbout document={document} /> : null}
                        {activeTab === 'category' ? <BusinessManageCategory document={document} /> : null}
                        {activeTab === 'items' ? <BusinessManageItems /> : null}
                        {activeTab === 'reviews' ? <BusinessManageReviews /> : null}
                        {activeTab === 'promotions' ? <BusinessManagePromotions /> : null}
                    </>}

            </div>
        </div>
    )
}
