import { createContext, useEffect, useReducer } from "react";
import { projectAuth } from "../firebase/config";
import { getDateNow, getDateNowText, getMonthObject } from "../helper/helper";
import { projectFirestore } from "../firebase/config";

export const AuthContext = createContext()

export const authReducer =(state, action)=>{
    switch (action.type) {
        case 'LOGIN' :
            return { ...state, user: action.payload }
        case 'LOGOUT' :
            return { ...state, user: null }
        case 'PHOTO_PROVIDER' :
            return { ...state, photoProvider: action.payload}
        case 'AUTH_IS_READY' :
            return { ...state, user: action.payload, authIsReady: true }
        case 'SET_DATE' :
            return { ...state, selectedDate: action.payload }
        case 'SET_ALL_DATE_STATE' :
            return { ...state, selectedDate: action.payload, selectedMonthObj: getMonthObject(action.payload), selectedMonth: action.payload.split('-')[0], selectedYear: action.payload.split('-')[2] }
        case 'UPDATE_MONTH_OBJECT' :
            return { ...state, selectedMonthObj: getMonthObject(state.selectedDate) }
        case 'SET_MONTH' :
            return { ...state, selectedMonth: action.payload }
        case 'SET_YEAR' :
            return { ...state, selectedYear: action.payload }
        case 'SET_WEEK' :
            return { ...state, selectedWeek: action.payload }
            
        default:
            return state
    }
}



export const AuthContextProvider =({children})=>{

    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        authIsReady: false,
        selectedDate: getDateNowText(),
        selectedMonthObj: getMonthObject(getDateNowText()),
        selectedMonth: getDateNow().month,
        selectedYear: getDateNow().year,
        selectedWeek: 0,
        photoProvider: null
    })
    console.log(state)

    // console.log(state)

    useEffect(() => {
        // effect
        const unsub = projectAuth.onAuthStateChanged(user=>{
            dispatch({
                type: 'AUTH_IS_READY',
                payload: user
            })
        })
        return () => {
            // cleanup
            unsub()
        };
    }, []);

    useEffect(() => {
        let unsub = ()=>{}
        if(state.user){
            let store = projectFirestore.collection('userinfo').doc(state.user.uid)
            
            unsub = store.onSnapshot(snapshot => {
                // console.log('snapshot', snapshot.data())
                if(snapshot && snapshot.data()){
                    // setPhotoProvider(snapshot.data().photoProvider)
                    dispatch({
                        type: 'PHOTO_PROVIDER',
                        payload: snapshot.data().photoProvider
                    })
                }
            })
        }

        return () => {
            // cleanup
            unsub()
        };
    }, [state.user]);


    // console.log('AuthContext state:', state);

    return (
        <AuthContext.Provider value={{ ...state, dispatch }}>
            { children }
        </AuthContext.Provider>
    )
}