import { useEffect, useRef, useState } from 'react';
import { getMaxFileSize } from '../../../helper/helper';
import { useMap } from '../../../hooks/useMap'
import { useParams } from 'react-router';
// import { projectStorage } from '../../../firebase/config';

// import { useAuthContext } from '../../../hooks/useAuthContext';
import { useFirestore } from '../../../hooks/useFirestore';
import { useToast } from '../../../hooks/useToast'
import { useGeoFire } from '../../../hooks/useGeoFire';


export default function BusinessManageAbout({ document }) {

    const { businessid } = useParams()

    const { updateDocument, addFile, deleteFile } = useFirestore('business')
    const { getHash } = useGeoFire()
    const { toast, showToast } = useToast(2000)


    const [name, setName] = useState(document.about.name);
    // const [currency, setCurrency] = useState(document.about.currency);
    const [phone, setPhone] = useState(document.about.phone);
    const [email, setEmail] = useState(document.about.email);
    const [weblink, setWeblink] = useState(document.about.weblink);
    const [logoURL, setLogoURL] = useState(document.about.logoURL);
    const [bannerURL, setBannerURL] = useState(document.about.bannerURL);
    const [delivery, setDelivery] = useState(document.about.delivery);
    
    const [logoURLError, setLogoURLError] = useState(null);
    const [bannerURLError, setBannerURLError] = useState(null);
    const [logoURLThumbnail, setLogoURLThumbnail] = useState(null);
    const [bannerURLThumbnail, setBannerURLThumbnail] = useState(null);
    const [showCircle, setShowCircle] = useState(false);

    const backgroundURLRef = useRef()
    const logoURLRef = useRef()

    const [mapCenterInit, setMapCenterInit] = useState({
        lat: 0,
        lng: 0
    })

    const onMapClick=(e)=>{
        setMapCenterInit({
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        })
    }
    const { isLoaded, Search, ZMap } = useMap({

        center: mapCenterInit,
        isHomeMarker: true,
        markerIcon: logoURL,
        zoom: 14,
        circle: {
            showCircle,
            radius: delivery.distance
        },
        onMapClick: onMapClick

    })

    


    useEffect(() => {
        if(document){
            setMapCenterInit({
                lat: document.about.location.lat, 
                lng: document.about.location.lng,
            })
        }
    }, [document])

    const handleBackgroundChange =(e)=>{
        setBannerURLError(null)
        const selected = e.target.files[0];
        // console.log('file', f);
        

        if(!selected){
            setBannerURLError("Please select a file.")
            setTimeout(() => {
                setBannerURLError(null)
            }, 2000);
            return
        }
        if(!selected.type.includes("image")){
            setBannerURLError("Selected File must be an image.")
            setTimeout(() => {
                setBannerURLError(null)
            }, 2000);
            return
        }
        console.log(selected.size );
        if(selected.size > getMaxFileSize()){
            
            setBannerURLError("Your Image is too Powerful!")
            setTimeout(() => {
                setBannerURLError(null)
            }, 2000);
            return
        }

        var reader = new FileReader();
        reader.readAsDataURL(selected);
        reader.onloadend = function (e) {
            setBannerURL(reader.result);
        };
        setBannerURLThumbnail(selected)
    }
    const handleProfileChange =(e)=>{
        setLogoURLError(null)
        const selected = e.target.files[0];
        // console.log('file', f);
        

        if(!selected){
            setLogoURLError("Please select a file.")
            setTimeout(() => {
                setLogoURLError(null)
            }, 2000);
            return
        }
        if(!selected.type.includes("image")){
            setLogoURLError("Selected File must be an image.")
            setTimeout(() => {
                setLogoURLError(null)
            }, 2000);
            return
        }
        // console.log(selected.size );
        if(selected.size > getMaxFileSize()){
            
            setLogoURLError("Your Image is too Powerful!")
            setTimeout(() => {
                setLogoURLError(null)
            }, 2000);
            return
        }

        var reader = new FileReader();
        reader.readAsDataURL(selected);
        reader.onloadend = function (e) {
            setLogoURL(reader.result);
        };
        setLogoURLThumbnail(selected)
    }




    async function SaveAboutData(){
        const about = {
            name,
            type: "food",
            currency: 'cad',
            phone,
            email,
            weblink,
            logoURL,
            bannerURL,
            location: {
                lat: mapCenterInit.lat,
                lng: mapCenterInit.lng,
            },
            delivery
        }
        function saveAndDeletePhoto(thumbnail, oldUrl){
            return new Promise((res, rej)=>{
                // res({thumbnail, oldUrl})
                if(thumbnail){
                    const uploadPath = `restaurant/${businessid}/${thumbnail.name}`
                    // projectStorage.ref(uploadPath).put(thumbnail)
                    addFile(uploadPath, thumbnail)
                    .then(photoURL => {
                        // img.ref.getDownloadURL()
                        // .then(photoURL => {
                        setBannerURL(photoURL)
    
                        // console.log(document.about.bannerURL)
                        if(oldUrl){
                            // projectStorage.refFromURL(document.about.bannerURL)
                            // .delete()
                            deleteFile(document.about.bannerURL)
                            .then(()=>{
                                res({photoURL, message: 'success'})
                            })
                            .catch(error => {
                                res({photoURL, message: error.message})
                            })
                        }else{
                            res({photoURL, message: 'success'})
                        }
                            // reset ui
                        // })
                    })
                    .catch(error => {
                        console.log(error)
                    })
        
                }else{
                    res({photoURL: oldUrl, message: 'Nothing to execute'})
                }
            });
        }



        saveAndDeletePhoto(logoURLThumbnail, document.about.logoURL)
        .then(res1 => {
            console.log('LOGO: ', res1.message)
            saveAndDeletePhoto(bannerURLThumbnail, document.about.bannerURL)
            .then(res2 => {
                console.log('BANNER: ', res2.message)
                about.logoURL = res1.photoURL
                about.bannerURL = res2.photoURL
                updateDocument(businessid, {about} )
                updateDocument(businessid, {geohash: getHash({
                    lat: about.location.lat,
                    lng: about.location.lng,
                })})
                setLogoURL(res1.photoURL)
                setBannerURL(res2.photoURL)
                setLogoURLThumbnail(null)
                setBannerURLThumbnail(null)
                showToast({message: 'Save Complete'})
                console.log('FINISHED')
            })
        })

        // console.log(about)
    }


    

    return (
        <div className="business-manage-container flex-col-center-start">
            {toast}
            <div className="store-profile">
                <div className="store-background">
                    <img src={bannerURL ? bannerURL : '/logo.png'} alt="" />
                    <div onClick={()=> backgroundURLRef.current.click() } className="fg flex-col-center-center">Change</div>
                    <input ref={backgroundURLRef} type="file" onChange={handleBackgroundChange} style={{display: 'none'}} />
                </div>
                <div className="store-image">
                    <img src={logoURL ? logoURL : '/logo.png'} alt="" />
                    <div onClick={()=> logoURLRef.current.click() } className="fg flex-col-center-center">Change</div>
                    <input ref={logoURLRef} type="file" onChange={handleProfileChange} style={{display: 'none'}} />
                </div>
            </div>
            {logoURLError && logoURLError}
            {bannerURLError && bannerURLError}
            <br />
            <br />
            <br />
            <div className="field flex-col-start-center">
                <span>Business Name <span className="red">*</span></span>
                <input onChange={e => setName(e.target.value)} type="text" required value={name} />
            </div>
            <div className="field flex-col-start-center">
                <span>Contact Phone </span>
                <input onChange={e => setPhone(e.target.value)} type="tel" value={phone} />
            </div>
            <div className="field flex-col-start-center">
                <span>Contact Email </span>
                <input onChange={e => setEmail(e.target.value)} type="email" value={email} />
            </div>
            <br />
            <div className="field flex-col-start-center">
                <span>Address </span>
                {isLoaded && <Search />}
                {isLoaded && ZMap}
            </div>
            
            <div className="field flex-col-start-center">
                <span>Delivery Options</span>
                <select onChange={e => setDelivery(ps => {
                    return {...ps, type: e.target.value}
                }) } id="delivery-options" value={delivery.type} required>
                    <option value="na" disabled>Select One</option>
                    <option value="paid">Paid</option>
                    <option value="free">Free</option>
                    <option value="pickup">Pickup Only</option>
                    <option value="freeondistance">Free on Distance</option>
                    <option value="freeonminimumprice">Free on Minimum Price</option>
                    <option value="freeonminimumpriceanddistance">Free on Minimum Price and Distance</option>
                </select>
            </div>
            {delivery.type === "paid" || delivery.type === "freeonminimumprice" || delivery.type === "freeonminimumpriceanddistance"   || delivery.type === "freeondistance" ? (
                <div className="field flex-col-start-center">
                    <span>Delivery Price</span>
                    <input onChange={e => setDelivery(ps => {
                        return {...ps, price: e.target.value}
                    })} type="text" required value={delivery.price} />
                </div>
            ) : ""}
            {delivery.type === "freeonminimumprice" || delivery.type === "freeonminimumpriceanddistance" ? (
                <div className="field flex-col-start-center">
                    <span>Min Price</span>
                    <input onChange={e => setDelivery(ps => {
                        return {...ps, minimum: e.target.value}
                    })} type="text" required value={delivery.minimum} />
                </div>
            ) : ""}
            {delivery.type === "freeondistance" || delivery.type === "freeonminimumpriceanddistance" ? (
                <div onMouseOut={() => setShowCircle(false)} onMouseOver={() => setShowCircle(true)}  className="field flex-col-start-center">
                    <span className="flex-row-center-between w100">Distance <span className="right">{delivery.distance}</span> </span>
                    <input onChange={e => setDelivery(ps => {
                        return {...ps, distance: e.target.value}
                    })} onTouchStart={() => setShowCircle(true)} onTouchEnd={() => setShowCircle(false)} type="range" min="1" max="20" step="1" value={delivery.distance} />
                </div>
            ) : ""}
            <br />
            <div className="field flex-col-start-center">
                <span className="flex-row-center-between w100">Website Link <span className="premium">Premium Only</span></span>
                <input type="text" onChange={e => setWeblink(e.target.value)} value={weblink ? weblink : ""}/>
            </div>
            <br />
            <br />
            <button onClick={SaveAboutData} type="submit">Save Details Data</button>
            <br />
            <br />
        </div>
    )
}
