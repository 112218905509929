


export default function CategoryWidget({ value, set, title, icon, v }) {
    return (
        <div onClick={()=> set(title, v)} className={`widget flex-col-center-center ${value === title && "active"}`}>
            <img src={icon} alt="" />
            <span className="title">{title}</span>
        </div>
    )
}
