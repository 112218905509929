import { Link } from "react-router-dom"
import { FaStar } from "react-icons/fa"

export default function RestaurantWidgetCard({ id, img, title, p1, p2, p3 }) {
    return (
        <Link to={`/store/${id}`} className="restaurant-card flex-col-center-center">
            <img src={img} alt="" />
            <span className="title">{title}</span>
            <div className="subtitle flex-row-center-between">
                <span> <FaStar/> {p1} &bull; {p2}</span>
                <span>{p3}</span>
            </div>
        </Link>
    )
}
