import './Store.css'

import { createContext, useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useDocument } from '../../hooks/useDocument'
// import { useIsMobile } from '../../hooks/useIsMobile'
import { encodeCipher, getDeliveryTypeText, getZoomOnDistance } from '../../helper/helper'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useGeocode } from '../../hooks/useGeocode'
import { useMap } from '../../hooks/useMap'
import * as geofire from 'geofire-common'
import StoreItems from './StoreItems'
import { Link } from 'react-router-dom'
import StoreOrders from './StoreOrders'
import { FaShoppingBasket } from 'react-icons/fa'


export const ItemContext = createContext({})

export default function Store() {

    const { businessid, itemid } = useParams()
    const navigate = useNavigate()
    // const { isMobile } = useIsMobile()
    const { user } = useAuthContext()
    const { geocodeAddress } = useGeocode()
    const chatID = encodeCipher({
        businessid,
        userid: user? user.uid : "guest"
    })



    const [businessUID, setBusinessUID] = useState('')
    const [businessobj, setBusinessobj] = useState(null)
    const { document: business, error: businessError } = useDocument('business', businessid)
    const { document: itemChatDoc, error: itemChatError } = useDocument('itemchat', chatID)
    const { document: userinfo } = useDocument('userinfo', businessUID)
    const { document: curuserinfo } = useDocument('userinfo', user && user.uid)

    const [businessLocation, setBusinessLocation] = useState({
        lat: 0,
        lng: 0
    })

    

    const [distanceBetween, setDistanceBetween] = useState(null)

    const [mapMarkerIcon, setMapMarkerIcon] = useState(null)
    const [mapZoom, setMapZoom] = useState(14)
    const [mapCircle, setMapCircle] = useState({
        showCircle: false,
        radius: 1
    })

    const { ZMap } = useMap({
        home: {
            lat: businessobj && businessobj.about.location.lat, 
            lng: businessobj && businessobj.about.location.lng,
            isVisible: true,
            onClick: e => console.log(e),
            url: '/logo.png'
        },
        center: businessLocation,
        isHomeMarker: true,
        markerIcon: mapMarkerIcon,
        zoom: mapZoom,
        circle: mapCircle
    })

    

    const [isOrdersOpen, setisOrdersOpen] = useState(false)



    useEffect(() => {
        if(curuserinfo){
            geocodeAddress(curuserinfo.address)
            .then(res => {
                var distance = geofire.distanceBetween([res.lat, res.lng], [businessLocation.lat, businessLocation.lng]) ;
                // console.log('CURLOC', distance)
                setDistanceBetween(distance)
            })
            .catch(err => {
                console.log(err);
            })
        }
        // eslint-disable-next-line
    }, [curuserinfo]);
    
    useEffect(() => {
        if(business){
            // console.log(business);
            setBusinessUID(business.uid)
            setBusinessLocation(business.about.location)
            
            setMapCircle({
                showCircle: parseInt(business.about.delivery.distance) > 0,
                radius: parseInt(business.about.delivery.distance)
            })
            setMapZoom(parseInt(business.about.delivery.distance) > 0 ? getZoomOnDistance(parseInt(business.about.delivery.distance)) : 14)
            setMapMarkerIcon(business.about.logoURL)
        }
    }, [business]);

    useEffect(() => {
        if(businessError === 'DOCUMENT_NOT_EXIST'){
            navigate('/?error=STORE_INVALID_ID')
        }
        if(business){
            setBusinessobj(business)
        }
        // eslint-disable-next-line
    }, [businessError])



    
    

    return (
        <div className='store-wrapper flex-col-center-start'>
            <div className="store-container flex-col-center-start">
                {business && userinfo && <>
                
                    <Link to={`/store/${businessid}`} className={`store-header flex-col-start-start ${itemid ? "item-mode" : ""}`}>
                        <div className="logo-banner">
                            <img className='logo' src={business.about.logoURL} alt="" />
                            <img className='banner' src={business.about.bannerURL} alt="" />
                            {userinfo.dineSafe.havePass && 
                                <img className='dineSafePass' src="/dinesafe/981a-pass_lrg.png" alt="" />
                            }
                        </div>
                    </Link>
                    {!itemid &&  
                        <div className="store-optional-content">
                            <span className="store-name">{business.about.name}</span>
                            <div className="details flex-col-start-start">
                                {userinfo.premium.status === 'active' && 
                                    <span className="flex-row-start-center"> <img src="/icons/premium_black_48dp.svg" alt="" /> Premium Member</span>
                                }
                                <a href={`tel:${business.about.phone}`} className="flex-row-start-center"> <img src="/icons/phone_black_48dp.svg" alt="" />{business.about.phone}</a>
                                <a href={`mailto:${business.about.email}`} className="flex-row-start-center"> <img src="/icons/mail_black_48dp.svg" alt="" />{business.about.email}</a>
                                <span className="flex-row-start-center"> <img src="/icons/star_black_48dp.svg" alt="" /> 4.4 (800+ ratings) </span>
                                <span className="flex-row-start-center"> <img src="/icons/delivery_dining_black_48dp.svg" alt="" /> {getDeliveryTypeText(business.about.delivery)} </span>
                                <span className="flex-row-start-center"> <img src="/icons/delivery_dining_black_48dp.svg" alt="" /> {business.orders.length} Orders </span>
                                {distanceBetween && 
                                    <span className="flex-row-start-center"> <img src="/icons/my_location_black_48dp.svg" alt="" />{distanceBetween.toFixed(2)}km away</span>
                                }
                            </div>
                            { business && !businessError && ZMap}
                            <span className='title'>Promotions</span>
                            <div className="blank"></div>
                            {business && <StoreItems 
                                business={business} 
                            />}
                            <br />
                            <span className='title'>Reviews</span>
                            <div className="blank"></div>
                            <br />
                        </div>
                    }

                    <ItemContext.Provider value={{ itemChatDoc, itemChatError }}>
                        <Outlet />
                    </ItemContext.Provider>
                </>}
            </div>
            {isOrdersOpen && itemChatDoc && business &&
                <StoreOrders onClose={()=> setisOrdersOpen(false)} data={{ itemChatDoc, itemChatError, business }} />
            }
            {user && !isOrdersOpen && business && user && <>
                {business.uid !== user.uid && <div onClick={()=> setisOrdersOpen(true)} className="store-basket"> <FaShoppingBasket /> </div> }
            </>}
        </div>
    )
}
