import { useEffect, useState, useRef } from "react"
import { projectFirestore } from "../firebase/config"


export const useCollection = (collection, _query, _orderBy) =>{
    const [documents, setDocuments] = useState(null)
    const [error, setError] = useState(null)

    // if we dont use a ref --> infinite loop in useEffect
    // _query is an array and is "different" on every function call
    const query = useRef(_query).current
    const orderBy = useRef(_orderBy).current

    
    useEffect(() => {
        // effect
        let store = projectFirestore.collection(collection)

        if(query){
            query.forEach(q => {
                store = store.where(...q)
            })
        }
        if(orderBy){
            store = store.orderBy(...orderBy)
        }

        const unsub = store.onSnapshot(snapshot =>{
            let res = []
            snapshot.docs.forEach(doc => {
                res.push({ ...doc.data(), id: doc.id })
            })
            // update state
            setDocuments(res)
            setError(null)
        }, err=>{
            console.log(err)
            setError('could not fetch data', err.message)
        })

        return () => unsub()
    }, [collection, query, orderBy])

    return { documents, error }
    
}
