import { Link } from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
import './Navbar.css'
import { useEffect, useRef, useState } from 'react'
import { useFirestore } from '../hooks/useFirestore'
import { useIsMobile } from '../hooks/useIsMobile'
import { FaSearch } from 'react-icons/fa'


export default function Navbar() {
    const { logout } = useLogout()
    const { user, photoProvider } = useAuthContext()
    const { isMobile } = useIsMobile()
    
    const { updateDocument } = useFirestore('userinfo')

    const [isNavPopOpen, setIsNavPopOpen] = useState(false)
    const [isImageSelectOpen, setIsImageSelectOpen] = useState(false)
    const navpopref = useRef();

    const [photoURL, setPhotoURL] = useState(null)

    // console.log('photoURL', photoURL)

    const handleNavCloseClick = ()=>{
        
    }
    const handleNavWidgetClick =()=>{
        setIsNavPopOpen(false)
    }
    const handleNavPopMainClick =e=>{
        // console.log('test', e.target, navpopref.current, e.target === navpopref.current)
        if(e.target !== navpopref.current){
            return
        }else{
            setIsNavPopOpen(false)
        }
    }

    
    useEffect(() => {
        if(isNavPopOpen){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'unset';
        }
    }, [isNavPopOpen])

    useEffect(() => {
        setPhotoURL(null)
        // console.log(user.providerData)
        if(user){
            if(user.providerData){
                let gate = true;
                user.providerData.forEach(d => {
                    if(photoProvider === d.providerId){
                        setPhotoURL(d.photoURL)
                        gate = false
                    }
                })
                if(gate){
                    setPhotoURL(null)
                }
            }
        }
        
    }, [photoProvider, user])
    
    // const svgVariants = {
    //     hidden: { rotate: -180 },
    //     visible: {
    //         rotate: 0,
    //         transition: {duration: 1}
    //     }
    // }
    const handleChangePhtotoProviderClick =(pp)=>{
        setIsImageSelectOpen(false)
        updateDocument(user.uid, {
            photoProvider: pp
        })
    }

    return (
        <div className="navbar flex-row-center-center">
            {isMobile && 
                <div className="container flex-row-center-between">
                    <div className="left flex-row-center-between">
                        <img onClick={()=> setIsNavPopOpen(true)} src="/icons/menu_maincolor_48dp.svg" alt="" />
                        <Link to='/'>
                            <img className="navbar-logo" src="/spork.svg" alt="" />
                        </Link>
                    </div>

                    <div className="navbar-search flex-row-center-center">
                        <input type="text" placeholder='Search'/>
                        <FaSearch />
                    </div>
                    
                </div>
            }
            {!isMobile && 
                <div className="container flex-row-center-between">
                    <div className="left flex-row-center-center">
                        <img onClick={()=> setIsNavPopOpen(true)} src="/icons/menu_maincolor_48dp.svg" alt="" />
                        
                        
                        {/* <Link to='/' className="brandname">LEZZT</Link> */}
                        
                    </div>
                    <div className="center">
                        {/* <Link to='/'>
                        </Link> */}
                        <Link to='/'>
                            <img className="navbar-logo" src="/spork.svg" alt="" />
                            <img className="navbar-buttonlogo" src="/newlogo.svg" alt="" />
                        </Link>
                    </div>
                    <div className="right flex-row-center-center">
                        <div className="navbar-search flex-row-center-center">
                            <input type="text" placeholder='Search'/>
                            <FaSearch />
                        </div>
                        {/* {!isImageSelectOpen && user &&
                            <div className="right flex-row-center-start">
                                {!photoURL && <span onClick={()=> setIsImageSelectOpen(true)} className="username flex-row-center-center">{user.displayName ? user.displayName.charAt(0).toUpperCase() : "A"}</span>}
                                {photoURL && user && user.providerData && user.providerData.map(d => <img key={d.providerId} onClick={()=> setIsImageSelectOpen(true)} src={d.photoURL} alt="" />)}
                            </div>
                        }
                        {isImageSelectOpen && 
                            <div className="right-popup flex-row-center-end">
                                {
                                    user && user.providerData && user.providerData.map(d => {
                                        return d.photoURL !== null ? <img key={d.providerId} onClick={()=> handleChangePhtotoProviderClick(d.providerId)} src={d.photoURL} alt="" />
                                        : <span key="hohoho" onClick={()=> handleChangePhtotoProviderClick("password")} className="username flex-row-center-center">{user.displayName ? user.displayName.charAt(0).toUpperCase() : "A"}</span>
                                    })
                                }
                            </div>
                        } */}
                    </div>
                    
                </div>
            }

            {isNavPopOpen && 
                <div ref={navpopref} onClick={handleNavPopMainClick} className="navpop flex-col-start-start">
                    <div className="container flex-col-center-start">
                        <div onClick={handleNavCloseClick} className="close flex-row-center-end">
                            <img onClick={()=> setIsNavPopOpen(false)} src="/icons/close_black_48dp.svg" alt="" />
                        </div>
                        <div className="widget-con super flex-col-start-start">
                            {user && <>
                                <Link onClick={handleNavWidgetClick} to='/' className="widget flex-row-center-start nav-widget-h">
                                    <img src="/icons/deck_black_48dp.svg" alt="" />
                                    <span>Dashboard</span>
                                    <hr />
                                </Link>
                                <Link onClick={handleNavWidgetClick} to='/nearby' className="widget flex-row-center-start nav-widget-h">
                                    <img src="/icons/pin_drop_black_48dp.svg" alt="" />
                                    <span>Nearby</span>
                                    <hr />
                                </Link>
                                <Link onClick={handleNavWidgetClick} to='/chikkas' className="widget flex-row-center-start nav-widget-h">
                                    <img src="/icons/question_answer_black_48dp.svg" alt="" />
                                    <span>Chikkas</span>
                                    <hr />
                                </Link>
                                <Link onClick={handleNavWidgetClick} to='/account' className="widget flex-row-center-start nav-widget-h">
                                    <img src="/icons/account_circle_black_48dp.svg" alt="" />
                                    <span>Account</span>
                                    <hr />
                                </Link>
                                <Link onClick={handleNavWidgetClick} to='/business' className="widget flex-row-center-start nav-widget-h">
                                    <img src="/icons/store_black_48dp.svg" alt="" />
                                    <span>Business</span>
                                    <hr />
                                </Link>
                                <a onClick={logout} href="/" className="widget flex-row-center-start nav-widget-h">
                                    <img src="/icons/meeting_room_black_48dp.svg" alt="" />
                                    <span>Logout</span>
                                    <hr />
                                </a>
                                </>
                            }
                            {!user && <>
                                <Link onClick={handleNavWidgetClick} to='/' className="widget flex-row-center-start nav-widget-h">
                                    <img src="/icons/deck_black_48dp.svg" alt="" />
                                    <span>Dashboard</span>
                                    <hr />
                                </Link>
                                <Link onClick={handleNavWidgetClick} to='/nearby' className="widget flex-row-center-start nav-widget-h">
                                    <img src="/icons/pin_drop_black_48dp.svg" alt="" />
                                    <span>Nearby</span>
                                    <hr />
                                </Link>
                                <Link onClick={handleNavWidgetClick} to='/signup' className="widget flex-row-center-start nav-widget-h">
                                    <img src="/icons/app_registration_black_48dp.svg" alt="" />
                                    <span>Signup</span>
                                    <hr />
                                </Link>
                                <Link onClick={handleNavWidgetClick} to='/login' className="widget flex-row-center-start nav-widget-h">
                                    <img src="/icons/door_front_black_48dp.svg" alt="" />
                                    <span>Login</span>
                                    <hr />
                                </Link>
                            </>}
                        </div>
                        <br />
                        <div className="widget-con flex-col-start-start">
                        
                            <Link onClick={handleNavWidgetClick} to='/howtouse' className="widget flex-row-center-start nav-widget-h">
                                <span>How to use</span>
                                <hr />
                            </Link>
                            <Link onClick={handleNavWidgetClick} to='/about' className="widget flex-row-center-start nav-widget-h">
                                <span>About This Website</span>
                                <hr />
                            </Link>
                            <Link onClick={handleNavWidgetClick} to='/contact' className="widget flex-row-center-start nav-widget-h">
                                <span>Contact Us</span>
                                <hr />
                            </Link>
                            <Link onClick={handleNavWidgetClick} to='/termsandconditions' className="widget flex-row-center-start nav-widget-h">
                                <span>Terms and Conditions</span>
                                <hr />
                            </Link>
                        </div>

                        

                    </div>
                </div>

            }
        </div>
    )
}

