
import { useState } from "react"
import { FaStar } from "react-icons/fa"


export default function ChikkaRatingWidget({ getRating, itemid, name, rated }) {

    const [stars, setStars] = useState(5)

    const handleStarSubmit =()=>{
        getRating(stars, itemid, name)
    }

    return (
        <div className="widget flex-col-start-center w100">
            <span className="itemname">{name}</span>
            <div className="star-container flex-row-center-center w100">
                <FaStar onClick={()=> setStars(1)} onTouchStart={()=> setStars(1)} className={stars >= 1 && "active" } />
                <FaStar onClick={()=> setStars(2)} onTouchStart={()=> setStars(2)} className={stars >= 2 && "active" } />
                <FaStar onClick={()=> setStars(3)} onTouchStart={()=> setStars(3)} className={stars >= 3 && "active" } />
                <FaStar onClick={()=> setStars(4)} onTouchStart={()=> setStars(4)} className={stars >= 4 && "active" } />
                <FaStar onClick={()=> setStars(5)} onTouchStart={()=> setStars(5)} className={stars >= 5 && "active" } />
            </div>
            {!rated && <button onClick={handleStarSubmit}>submit</button>}
        </div>
    )
}
