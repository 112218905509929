import { useState } from "react"
import { useAuthContext } from "../hooks/useAuthContext"
import { useCollection } from "../hooks/useCollection"
import { useFirestore } from "../hooks/useFirestore"
import { projectFirestore } from "../firebase/config"
import { useEffect } from "react"

export const useChikkas =()=>{
    const { user } = useAuthContext()
    const { updateDocument: itemUpdateDocument } = useFirestore('itemchat')

    const { documents: chikkas } = useCollection('itemchat', [
        ['assignedUsers', 'array-contains', user.uid]
    ])


    const [itemChatDoc, setItemChatDoc] = useState(null);
    const [itemList, setItemList] = useState([])
    const [orderList, setOrderList] = useState([]);





    function getItemList(itemChatDoc){
        // console.log(itemChatDoc);
        return new Promise((res, rej)=>{
            if(itemChatDoc && itemChatDoc.items.length > 0){
                let ret = []
                projectFirestore.collection('items').where('itemid', 'in', itemChatDoc.items).get().then(snapshot =>{
                    // console.log(snapshot);
                    // console.log(snapshot.docs);
                    snapshot.docs.forEach(doc => {
                        if(doc.exists){
                            // console.log(doc.data())
                            ret.push({ ...doc.data(), id: doc.id })
                            // setItemList(ps => [...ps, { ...doc.data(), id: doc.id }])
                        }
                    })
    
                    res(ret)
                }, ()=>{
                    rej('ERROR_DOC')
                })
            }else{
                rej('EMPTY_DOC')
            }
        });
    }

    function getOrderList (itemChatDoc, itemList){
        let ret = []
        if(itemChatDoc){
            itemList.forEach(item => {
                let options = []
                itemChatDoc.itemOptions.forEach(itemop => {
                    let price = item.price.filter(pr => pr.id === itemop.price)[0]
                    let customization = []

                    itemop.customization.forEach(ic => {
                        // const z = item.customization.filter(ic2 => ic2.id === ic)
                        // console.log('z', ic, z);
                        item.customization.forEach(ic2 => {
                            // console.log('z', ic, ic2.id, ic2.id === ic);
                            if(ic2.id === ic){
                                customization.push({
                                    id: ic,
                                    name: ic2.size,
                                    amount: parseFloat(ic2.amount)
                                })
                            }
                        })
                        
                    })

                    if(item.itemid === itemop.itemid){
                        options.push({
                            price,
                            customization,
                            id: itemop.id
                        })
                    }
                })
                ret.push({
                    chikkaID: itemChatDoc.id,
                    id: item.id,
                    name: item.details.name,
                    options
                })
            })
        }
        return ret
    }


    const getOrderListData =()=>{
        return new Promise((res, rej)=>{
            
        });
    }

    useEffect(() => {
        if(chikkas){
            // console.log(chikkas)
            let ret = []
            chikkas.map((itemChatDoc, index) => {
                // console.log(itemChatDoc);
                getItemList(itemChatDoc).then(itemList => {
                    // console.log(itemList);
                    ret.push(getOrderList(itemChatDoc, itemList))
                    if(index === (chikkas.length - 1)){
                        setOrderList(ret)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
            })
        }
    }, [chikkas])


    const addItemMessage =(chatID, msgObj)=>{
        const selectedChat = chikkas.filter(ck => ck.id === chatID)
        // console.log(selectedChat);
        const newMessageObj = [...selectedChat[0].messages, msgObj]
        // console.log(newMessageObj)
        itemUpdateDocument(chatID, {messages: newMessageObj})
    }

    const readAllMessages =(chatID)=>{
        // console.log(chikkas)
        const selectedChat = chikkas && chikkas.filter(ck => ck.id === chatID)[0]
        // console.log(selectedChat)
        const newMessageObj = selectedChat && selectedChat.messages.map(msg => {
            if(!msg.read.includes(user.uid)){
                msg.read.push(user.uid)
            }
            return msg
        })
        // console.log(newMessageObj)
        if(newMessageObj){
            itemUpdateDocument(chatID, {messages: newMessageObj})
        }
    }


    return { chikkas, addItemMessage, readAllMessages, orderList }
}