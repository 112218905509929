import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { textId } from '../../helper/helper';
import usePrompt from '../../hooks/usePrompt';
import { projectAuth, firebaseAuth, projectFirestore } from '../../firebase/config';
import { useFirestore } from '../../hooks/useFirestore';
import { useDocument } from '../../hooks/useDocument';
import { useAuthContext } from '../../hooks/useAuthContext';

export default function BusinessListWidget({ business }) {

    const { user } = useAuthContext()

    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [changeBusinessId, setChangeBusinessId] = useState("")
    const [error, setError] = useState(null)
    const [changeStatus, setChangeStatus] = useState(null)

    const { prompt, promptPassword } = usePrompt()

    const { setDocument, response: setResponse } = useFirestore('business')
    const { deleteDocument } = useFirestore('business')
    const { document: userinfo, error: userinfoError } = useDocument('userinfo', user.uid)

    // console.log(business)

    function getAverageRating(){
        return 4;
    }

    function getCompletedOrders(){
        return 124
    }

    const handleDeleteClick =(id)=>{
        console.log(id)
        promptPassword({title: 'YOU ARE ABOUT TO DELETE THE BUSINESS. Enter password to proceed'}).then(password => {
            const user = projectAuth.currentUser;
            const credential = firebaseAuth.EmailAuthProvider.credential(
                projectAuth.currentUser.email,
                password
            )
            user.reauthenticateWithCredential(credential).then(function() {
                console.log('this user has given correct password')
                deleteDocument(id)

            }).catch(function(error) {
                setError('password entered is wrong.')
                console.log('this user has given wrong password ', error.message )
            });
        })
    }
    const handleChangeClick =(id)=>{
        // console.log(id)
        if(changeStatus === 'yellow'){
            setError('ID should be more than 8 characters')
            return
        }
        if(changeStatus === 'red'){
            setError('ID should be less than 15 characters')
            return
        }
        if(changeBusinessId.length <= 7){
            setError('ID should be atleast 8 characters long')
            return
        }
        if(changeBusinessId.length >= 14){
            setError('ID cannot exceed 15 characters')
            return
        }

        projectFirestore.collection('business').doc(changeBusinessId).get().then(snap => {
            if(snap.exists){
                // console.log(snap.data(), "EXISTS")
                setError('ID already exists. Please select a new one.')
                return
            }else{
                promptPassword({title: 'Please Provide your password to change the Business ID'}).then(password => {
                    console.log('change this shit: ', password)
                    const user = projectAuth.currentUser;
                    const credential = firebaseAuth.EmailAuthProvider.credential(
                        projectAuth.currentUser.email,
                        password
                    )
                    user.reauthenticateWithCredential(credential).then(function() {
                        console.log('this user has given correct password')
                        const businessCopy = {...business}
                        delete businessCopy['id']
                        // console.log('businessCopy',changeBusinessId, businessCopy)
                        
        
                        setDocument(changeBusinessId, businessCopy)
                        if(!setResponse.isPending && !setResponse.error && setResponse.success){
                            deleteDocument(business.id)
                        }else{
                            setError(setResponse.error)
                            deleteDocument(id)
                        }
        
                    }).catch(function(error) {
                        setError('password entered is wrong.')
                        console.log('this user has given wrong password ', error.message )
                    });
        
                })
            }
        })
    }
    const handleBusinessIdChange =(e)=>{
        // console.log(e.target.value)
        if(textId(e.target.value)){
            setError('Must contain letters and numbers only')
            return
        }
        if(changeBusinessId.length < 8){
            setChangeStatus('yellow')
            // return
        }
        if(changeBusinessId.length > 15){
            setChangeStatus('red')
            // return
        }
        if(changeBusinessId.length <= 15 && changeBusinessId.length >= 8){
            setChangeStatus('green')
        }
       

        setChangeBusinessId(e.target.value)
    }
    
    useEffect(() => {
            const x = setTimeout(() => {
                if(error){
                    setError(null)
                }
            }, 2000); 
        return () => clearTimeout(x)
    }, [error])
    

    return (
        
        <div className="business-widget-container flex-col-center-start">
            {prompt}
            <Link to={`/business/${business.id}`} className="business-widget flex-row-start-start ">
                <img src={business.about.logoURL ? business.about.logoURL : '/logo.png'} alt="" />
                <div className="details flex-col-start-start">
                    <span className="store-name">{business.about.name.length ? business.about.name : "Unnamed Business"}</span>
                    <span className="text">{business.reviews.length} reviews &bull; {getAverageRating()} <i className="fas fa-star"></i></span>
                    <span className="text">{business.categories.length} Categories</span>
                    <span className="text">{getCompletedOrders()} Completed Orders</span>
                </div>
            </Link>
            {isPopupOpen && <>
                <div className="business-widget-popup flex-row-center-even">
                    <span onClick={()=> handleDeleteClick(business.id) } className="delete flex-row-center-center">delete <img  src="/icons/delete_black_48dp.svg" alt="" /></span>
                    {userinfo && !userinfoError && userinfo.premium.status === 'active' &&
                        <div  className="change flex-row-center-center">
                            <input value={changeBusinessId} onChange={handleBusinessIdChange} type="text" placeholder="Change Business ID" />
                            <img className={changeStatus} onClick={()=> handleChangeClick(business.id)} src="/icons/edit_note_black_48dp.svg" alt="" />
                        </div>
                    }
                    
                </div>
                {error && 
                    <p className="subtitle">{error}</p>
                }
                </>
            }
            <img onClick={()=> setIsPopupOpen(!isPopupOpen) } src="/icons/settings_black_48dp.svg" alt="" className="delete" />
        </div>
    )
}
