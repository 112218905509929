import './Business.css'

import { rngPassword } from '../../helper/helper'
import BusinessList from './BusinessList'
import { useCollection } from '../../hooks/useCollection'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useDocument } from '../../hooks/useDocument'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

export default function Business() {

    const { user } = useAuthContext()
    const { document, error: documentError } = useDocument('userinfo', user.uid)

    const { documents, error } = useCollection('business', [
        [ 'uid', '==', user.uid ]
    ])

    const [businessId, setBusinessId] = useState(rngPassword())
    const [isPremium, setIsPremium] = useState(false)

    // const btoa = str => new Buffer(str, 'binary').toString('base64');
    const btoa = str => new Buffer.from(str, 'binary').toString('base64');


    // console.log(document)
    const jsonObj = {
        token: user.uid,
        bid: businessId
    }
    const jsonStr = JSON.stringify(jsonObj);
    const createBusinessToken = escape(btoa(jsonStr));
    // console.log('encodedStringBtoA',encodedStringBtoA);
    // const escapedJSON = escape(createBusinessToken)
    


    useEffect(() => {
        if(document){
            // console.log(document.premium);
            if(document.premium.status === 'active'){
                const sd = new Date(document.premium.start.toDate())
                const ed = new Date(document.premium.end.toDate())
                const today = new Date()
                // console.log(sd, ed);
                if(today.getTime() > sd.getTime() && today.getTime() < ed.getTime()){
                    setIsPremium(true)
                }
            }
        }

    }, [document])

    return (
        <div className="business-container flex-col-center-start">
            <h1>My Business</h1>
            
            {!error && documents && <BusinessList business={documents} />}
            <br />

            {document && !documentError && documents && !error && ( (document.premium.status !== "active" && (2 - documents.length)) || document.premium.status === "active" ) ?
                <div className="business-widget-add flex-col-center-start ">
                    <span className="title flex-row-center-center">Add New Business &nbsp; 
                        {document && !documentError && documents && !error && document.premium.status === "active" && <p className="subtitle">unlimited</p> }
                        {document && !documentError && documents && !error && document.premium.status !== "active" && <p className="subtitle">{2 - documents.length} remain</p> }
                    </span>
                    <div className="add-new-business flex-row-center-between">
                        {isPremium && <input type="text" onChange={e => setBusinessId(e.target.value)} value={businessId} />}
                        {!isPremium && <input type="text" value={businessId} disabled/>}
                        <Link to={`/businesscreate/${createBusinessToken}`}><img src="/icons/add_circle_white_48dp.svg" alt="" /></Link>
                    </div>
                    <p className="subtitle">this is your business id. customization is for premium members only.</p>
                    {/* <p className="subtitle">your business url will look like</p> */}
                    <p className="subtitle">{`https://lezzt.com/store/${businessId}`}</p>

                </div> : <div className="business-widget-add w100 flex-col-center-center ">
                    <span className="title w100" style={{textAlign: 'center', backgroundColor: 'var(--BG_WHITE_COLOR)', padding: '10px 0px', color: "grey"}}>Max Business Reached. Consider upgrading to Premium to get more!</span>
                </div>
            }

            <br />
            <br />
        </div>
    )
}
