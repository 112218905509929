import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useCollection } from "../../hooks/useCollection";
import { useDocument } from "../../hooks/useDocument";
import { useAuthContext } from "../../hooks/useAuthContext";
import { textId } from "../../helper/helper";
import { useNavigate } from 'react-router-dom';
import { useFirestore } from "../../hooks/useFirestore";
import { useToast } from "../../hooks/useToast";
import { useGeoFire } from "../../hooks/useGeoFire";

const initialQuestion = {
    havePass: false,
    haveConditionalPass: false,
    q1_1: false,
    q1_2: false,
    q1_3: false,
    q1_4: false,
    q2_1: false,
    q2_2: false,
    q2_3: false,
    q2_4: false,
    q2_5: false,
    q2_6: false,
    q3_1: false,
    q3_2: false,
    q3_3: false,
    q3_4: false,
    q4_1: false,
    q4_2: false,
    q4_3: false,
    q4_4: false,
    q4_5: false,
    q5_1: false,
    q5_2: false,
    q5_3: false,
    q5_4: false,
    q5_5: false,
    q5_6: false,
    q6_1: false,
    q6_2: false,
    q6_3: false,
    q7_1: false,
    q7_2: false,
    q7_3: false,
    q8_1: false,
    q8_2: false,
    q8_3: false,
    q8_4: false,
    accept: false,
}


export default function BusinessCreate() {
    const { businessid } = useParams()
    const { getHash } = useGeoFire()

    // console.log(businessid)
    const { user } = useAuthContext()
    const navigate = useNavigate();

    const { setDocument } = useFirestore('business')
    const { updateDocument } = useFirestore('userinfo')

    const [bid, setBid] = useState(null)
    
    
    const { document: userinfo } = useDocument('userinfo', user.uid)
    const { error } = useDocument('business', bid)
    const { documents } = useCollection('business', [
        [ 'uid', '==', user.uid ]
    ])

    const {toast, showToast} = useToast(2000)


    const [question, setQuestion] = useState(initialQuestion)
    

    // console.log(question)

    const blankBusiness = {
        uid: user.uid,
        about: {
            name: '',
            type: "",
            currency: 'cad',
            phone: '',
            email: '',
            weblink: null,
            logoURL: null,
            bannerURL: null,
            location: {
                lat: 43.664744,
                lng: -79.392364
            },
            delivery: {
                type: 'free',
                price: 0,
                minimum: 0,
                distance: 0,
            },
        },
        geohash: getHash({
            lat: 43.664744,
            lng: -79.392364
        }),
        categories: [],
        reviews: [],
        promotions: [],
        orders: [],
        itemratings: {
            count: 0,
            ratings: [],
            average: 0
        }
    }

    useEffect(() => {
        try {
            const atob = str => Buffer.from(str, 'base64').toString('binary');
            const encodedToken = unescape(atob(businessid));
            const decodedToken = JSON.parse(encodedToken);

            setBid(decodedToken.bid)
            if(user && documents && userinfo && decodedToken){
                
                    // console.log('data loaded. ', decodedToken)
                    console.log(decodedToken.token, user.uid, decodedToken.token === user.uid);
                    
                    if(decodedToken.token === user.uid){
                        // console.log('TOKENS ARE VALID');
                        
                        if( (userinfo.premium.status !== "active" && (2 - documents.length) ) ||  userinfo.premium.status === "active"){
                            // console.log('user met requirements', decodedToken.bid)
                            if(textId(decodedToken.bid)){
                                navigate('/?error=BUSINESS_INVALID_TOKEN')
                                // console.log('string error');
                            }
                            // console.log('decodedToken.bid.length', decodedToken.bid.length);
                            
                            if(decodedToken.bid.length < 8){
                                navigate('/?error=BUSINESS_LESS_ID_LENGTH')
                                // console.log('lesser length error')
                            }
                            if(decodedToken.bid.length > 15){
                                navigate('/?error=BUSINESS_GREAT_ID_LENGTH')
                                // console.log('higher length error');
                                
                            }
                            if(decodedToken.bid.length <= 15 && decodedToken.bid.length >= 8){
                                // setDocument(businessid, blankBusiness)
                                if(error !== 'FAILED_DOCUMENT'){
                                    if(error === 'DOCUMENT_NOT_EXIST' && error){
                                        console.log('set document');
                                    }else if(error !== 'DOCUMENT_NOT_EXIST' && error){
                                        console.log('document exists')
                                    }
                                }
                            }

                        }else{
                            console.log('Redirect this user. did not meet requirements')
                            navigate('/?error=BUSINESS_REQUIREMENTS_NOT_MET')
                        }
                    }else{
                        navigate('/?error=BUSINESS_INVALID_AUTH')
                    }
                

                if(userinfo.dineSafe){
                    setQuestion({...userinfo.dineSafe})
                }
            }
        } catch (err) {
            navigate('/?error=BUSINESS_INVALID_TOKEN')
        }
        // eslint-disable-next-line
    }, [documents, userinfo, error])

    const handleQuestionChange =(e, key)=>{
        let isChecked = e.target.checked;

        setQuestion(ps => {
            return {...ps, [key]: isChecked }
        })
    }

    useEffect(() => {
        if(question.havePass){
            // console.log('Entered the Dragon');
            
            Object.keys(question).forEach(function(key) {
                if(key === 'haveConditionalPass' || key === 'accept'){
                    return
                }else{
                    setQuestion(ps => {
                        return {...ps, [key] : true}
                    })
                }
            });
        }
        // eslint-disable-next-line
    }, [question.havePass]);

    const handleCreateClick =()=>{
        if(!question.accept){
            showToast({message: "Please accept the terms and conditions."})
        }else{
            setDocument(bid, blankBusiness)
            updateDocument(user.uid, {dineSafe: question})
            navigate(`/business/${bid}`)
        }
    }


    return (<>
        {toast}
        <div className="create-business-protocol flex-col-center-start">
            <div className="container flex-col-start-start">
            
                <h1>Eight Steps to a PASS</h1>
                <p className="subtitle">Please read through the list and check all the boxes that apply to you.</p>
                <br />

                <label>
                    <input type="checkbox" checked={question.havePass} onChange={ e => handleQuestionChange(e, 'havePass') } />
                    <img src="https://www.toronto.ca/wp-content/uploads/2019/10/981a-pass_lrg.png"   alt="" />
                    <span>I have a DineSafe PASS certificate.</span>
                </label>

                <label>
                    <input type="checkbox" checked={question.haveConditionalPass} onChange={ e => handleQuestionChange(e, 'haveConditionalPass') } />
                    <img src="https://www.toronto.ca/wp-content/uploads/2019/10/906e-conditional_lrg.png" alt="" />
                    <span>I have a DineSafe CONDITIONAL PASS certificate.</span>
                </label>


                <span className="title">1. Food Temperature Control</span>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q1_1') } checked={question.q1_1} /><span>Keep cold food below 4°C/40°F</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q1_2') } checked={question.q1_2} /><span>Keep hot food above 60°C/140°F</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q1_3') } checked={question.q1_3} /><span>Keep frozen foods frozen</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q1_4') } checked={question.q1_4} /><span>Provide accurate indicating thermometers  in all temperature controlled units such as refrigerators, freezers, and hot-holding units</span></label>
                
                <span className="subtitle">Toronto Public Health has some additional resources regarding <a href="https://www.toronto.ca/?page_id=439217">specific food processes</a>.</span>             
                <br />
                
                
                <span className="title">2. Protect Food from Contamination</span>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q2_1') } checked={question.q2_1} /><span>Store cooked and ready-to-eat food items on shelves above raw food</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q2_2') } checked={question.q2_2} /><span>Cover food with Iids or plastic wrap</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q2_3') } checked={question.q2_3} /><span>Use proper utensils to reduce direct hand contact with prepared food</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q2_4') } checked={question.q2_4} /><span>Use water that is safe to drink for food preparation</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q2_5') } checked={question.q2_5} /><span>Label chemicals and pesticides and store them away from food and food preparation areas</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q2_6') } checked={question.q2_6} /><span>Keep all food items off the floor on shelves, racks or pallets</span></label>
                <br />
                
                
                
                <span className="title">3. Employee Hygiene & Hand-washing</span>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q3_1') } checked={question.q3_1} /><span>Provide hot and cold running water, soap in a dispenser and paper towels, or a cloth roller towel in a mechanical device and a supply of paper towels at all hand-wash basins</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q3_2') } checked={question.q3_2} /><span>Use hand-wash basins only for hand-washing and not for food preparation or dish-washing</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q3_3') } checked={question.q3_3} /><span><a href="https://www.toronto.ca/community-people/health-wellness-care/health-programs-advice/hand-hygiene/">Wash hands thoroughly before and after handling food</a></span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q3_4') } checked={question.q3_4} /><span>Wear clean outer garments and hair constraints</span></label>
                <br />
                

                <span className="title">4. Maintenance and Sanitation of Food Contact Surfaces & Equipment</span>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q4_1') } checked={question.q4_1} /><span>Use detergent and water followed by sanitizing solution of 2 ml of household bleach per 1 L of water</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q4_2') } checked={question.q4_2} /><span>Follow manufacturers’ directions for aII other types of sanitizers</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q4_3') } checked={question.q4_3} /><span>Keep all food contact surfaces clean and in good condition</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q4_4') } checked={question.q4_4} /><span>Discard and replace cracked utensils or deeply grooved food contact surfaces</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q4_5') } checked={question.q4_5} /><span>Wash all utensils, dishes, and equipment either by hand using the two or three sink method (wash-rinse-sanitize) or in a mechanical dishwasher as required</span></label>
                
                <span className="subtitle">Toronto Public Health has some additional resources regarding <a href="https://www.toronto.ca/?page_id=439218">cleaning and sanitation of food contact equipment</a>.</span>      
                <br />
                

                <span className="title">5. Maintenance and Sanitation of Non-food Contact Surfaces & Equipment</span>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q5_1') } checked={question.q5_1} /><span>Keep surfaces clean</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q5_2') } checked={question.q5_2} /><span>Keep floors, walls, and ceilings clean and in good repair</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q5_3') } checked={question.q5_3} /><span>All surfaces must be smooth, non-absorbent, and easy to clean</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q5_4') } checked={question.q5_4} /><span>Provide adequate Iighting as per the Ontario Building Code</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q5_5') } checked={question.q5_5} /><span>Maintain adequate levels of ventilation</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q5_6') } checked={question.q5_6} /><span>Ensure proper operation and maintenance of mechanical dishwasher and other equipment</span></label>
                <br />
                
                
                <span className="title">6. Maintenance and Sanitation of Washrooms</span>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q6_1') } checked={question.q6_1} /><span>Keep washrooms, toilets, and change rooms clean at all times</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q6_2') } checked={question.q6_2} /><span>Provide toilet paper and a garbage container, as well as a constant supply of hot and cold running water, soap in a dispenser and either a cloth roller towel in a mechanical device, a supply of  paper towels,  a supply of clean single service towels or a hot air dryer at the hand-wash basin</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q6_3') } checked={question.q6_3} /><span>Keep floors, walls, and ceilings clean</span></label>
                <br />
                
                
                <span className="title">7. Storage & Removal of Waste</span>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q7_1') } checked={question.q7_1} /><span>Remove solid and liquid waste from the food preparation area on a daily basis or more often if necessary</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q7_2') } checked={question.q7_2} /><span>Store waste in a sanitary manner</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q7_3') } checked={question.q7_3} /><span>Waste receptacles must be leak-proof, pest-proof, non-absorbent, and have tight fitting lids</span></label>
                <br />
                
                
                <span className="title">8. Pest Control</span>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q8_1') } checked={question.q8_1} /><span>Cover any openings in order to prevent pests from entering the food premises</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q8_2') } checked={question.q8_2} /><span>Eliminate any food or water sources for pests</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q8_3') } checked={question.q8_3} /><span>Consider obtaining a contract with a licensed pest control operator</span></label>
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'q8_4') } checked={question.q8_4} /><span>Ensure pest control records are available onsite for the last 12 months</span></label>
                
                <span className="subtitle">Toronto Public Health has some additional resources regarding <a href="https://www.toronto.ca/?page_id=439219">pest control in food premises</a>.</span>      
                <br />
                

                
                <label> <input type="checkbox" onChange={ e => handleQuestionChange(e, 'accept') } checked={question.accept} /><span>By clicking on this, you agree that you read and understood all of the content above and have truthfully answered all of them.</span></label>
                <br />
                <button onClick={handleCreateClick}>Create my Business</button>
                <br />
            </div>
        </div>

    </>)
}
