import { useParams } from "react-router-dom"
import ImageGallery from 'react-image-gallery';
import { decodeCipher, encodeCipher, getDayText, getDefaultItemImage, getProperItemImage, rngPassword, timeToText } from "../../helper/helper";
import { useDocument } from "../../hooks/useDocument";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import { FaTimes, FaShoppingBasket } from "react-icons/fa";
import { useAuthContext } from "../../hooks/useAuthContext";
import { timestamp } from "../../firebase/config";
import { useFirestore } from "../../hooks/useFirestore";
import { ItemContext } from "./Store"

import '../../helper/Modal.css'

export default function Item() {

    const { businessid, itemid } = useParams()
    const { setDocument, updateDocument } = useFirestore('itemchat')
    const {itemChatDoc, itemChatError} = useContext(ItemContext)


    // console.log(itemChatDoc, itemChatError);
    
    const navigate = useNavigate()
    const { document: itemDoc, error: itemError } = useDocument('items', itemid)
    const { document: businessDoc } = useDocument('business', businessid)
    const { user } = useAuthContext()

    const chatID = encodeCipher({
        businessid,
        userid: user ? user.uid : "guest"
    })


    const { modal, showModal } = useModal()

    const [images, setImages] = useState([])
    const [currentGalleryIndex, setCurrentGalleryIndex] = useState(0);

    const [selectedCustomization, setSelectedCustomization] = useState([])
    const [selectedPrice, setselectedPrice] = useState(null)
    const [isItemPopupOpen, setIsItemPopupOpen] = useState(false)
    const [popupTotal, setPopupTotal] = useState(0)


    // const [chatID, setChatID] = useState(null)



    useEffect(() => {
        setImages([])
        if(itemDoc){
            itemDoc.images.sort(function(x, y) {
                return (x.isProfile === y.isProfile) ? 0 : x.isProfile ? -1 : 1;
            }).forEach((img, index) => {
                setImages(ps => [...ps, {original: img.url, index}])
            })
        }
    }, [itemDoc])

    useEffect(() => {
        // console.log(itemDoc);
        if(itemDoc){
            if(itemDoc.businessid !== businessid){
                navigate(`/store/${businessid}`)
            }
        }
        if(itemError === 'DOCUMENT_NOT_EXIST'){
            navigate(`/store/${businessid}`)
        }

        // eslint-disable-next-line
    }, [businessid, itemid, itemDoc, itemError]);

    const handleGalleryClick =()=>{
        console.log(currentGalleryIndex);
        showModal(
            <div className="singleImage">
                <img src={images[currentGalleryIndex].original} alt="" />
            </div>
        )
    }

    const handleStartOrderClick =()=>{
        console.log('test', selectedCustomization);
        const blankItemChat = {
            businessid: businessid,
            assignedUsers: [ user.uid, businessDoc.uid ],
            owner: businessDoc.uid,
            buyer: user.uid,
            userinfo: {
                [user.uid]: {
                    photoURL: user.photoURL,
                    displayName: user.displayName
                },
                [businessid] : {
                    photoURL: businessDoc.about.logoURL,
                    displayName: businessDoc.about.name
                }
            },
            items: [ itemid ],
            itemOptions: [
                {
                    id: rngPassword(),
                    itemid,
                    customization: selectedCustomization,
                    price: selectedPrice.id
                }
            ],
            messages: [],
            delivery: 0,
            discount: {
                type: 'amount',
                payload: 0,
                note: ''
            },
            verify: [],
            totalAmount: popupTotal,
            new: true,
            lastUpdated: timestamp.fromDate(new Date())
        }
        
        if(itemChatError === 'DOCUMENT_NOT_EXIST'){
            setDocument(chatID, blankItemChat)
        }
        setIsItemPopupOpen(false)


    }

    const handleAddOrderClick =()=>{

        // console.log('update', itemChatDoc)
        let gate = true
        let newItemObj = [...itemChatDoc.items]
        // const newItemChatObj = [...itemChatDoc, {itemOptions: [...itemChatDoc.itemOptions, {
        //     id: rngPassword(),
        //     itemid,
        //     customization: selectedCustomization,
        //     price: selectedPrice.id
        // }]}]
        itemChatDoc.items.forEach(item => {
            if(item === itemid){
                gate = false
            }
        })

        if(gate){
            newItemObj.push(itemid)
            console.log('ADDED NEW ITEM', newItemObj)
        }

        const newUserInfo = {...itemChatDoc.userinfo, [user.uid]: {
            photoURL: user.photoURL,
            displayName: user.displayName
        }}

        updateDocument(chatID, {itemOptions: [...itemChatDoc.itemOptions, {
                id: rngPassword(),
                itemid,
                customization: selectedCustomization,
                price: selectedPrice.id
                }], 
                items: newItemObj,
                userinfo: newUserInfo
            }
        )

        setIsItemPopupOpen(false)

    }
    
    // console.log('appended ', selectedCustomization);

    const handlePriceBoxChange =(e, amt, id)=>{
        if(e.target.checked){
            setPopupTotal((parseFloat(amt) + parseFloat(popupTotal)).toFixed(2))
            setSelectedCustomization(ps => {
                return [...ps, id]
            })
        }else{
            setPopupTotal((parseFloat(popupTotal) - parseFloat(amt)).toFixed(2))
            setSelectedCustomization(ps => {
                return ps.filter(sc => sc !== id)
            })
        }
    }
  
    const handlePriceClick =(id)=>{
        // console.log(id)
        itemDoc.price.forEach(pr => {
            // console.log(pr)
            if(pr.id === id){
                setselectedPrice(pr)
                setIsItemPopupOpen(true)
                setPopupTotal(parseFloat(pr.amount).toFixed(2))
                setSelectedCustomization([])
            }
        })

    }

    return ( 
        <div className="store-item-content flex-col-start-start">
            {modal}
            {itemDoc && <>
                <div className="imgal">
                    <ImageGallery 
                        items={images && images.length ? images : [{original: '/logo.png'}]} 
                        showFullscreenButton={false}
                        useBrowserFullscreen={false}
                        showPlayButton={false}
                        showBullets={images && images.length > 1}
                        renderPlayPauseButton={() => null}
                        onErrorImageURL={getDefaultItemImage()}
                        onSlide={(e)=> setCurrentGalleryIndex(e)}
                        onClick={handleGalleryClick}
                    />
                </div>
                <span className="title">{itemDoc.details.name}</span>
                
                {itemDoc.details.description && <div className="field">
                    <p className="subtitle">description</p>
                    <div className="description">
                        <span>{itemDoc.details.description}</span>
                    </div>
                </div>}
                {itemDoc.details.calories.min > 0 && itemDoc.details.calories.max > 0 && <div className="field">
                    <p className="subtitle">calories</p>
                    <span className="calories">{itemDoc.details.calories.min} - {itemDoc.details.calories.max} Cals</span>
                </div>}
                {itemDoc.details.alert && <div className="field">
                    <p className="subtitle">alert</p>
                    <span className="alert"><img src="/icons/priority_high_white_48dp.svg" alt="" />{itemDoc.details.alert}</span>
                </div>}
                {itemDoc.details.tags.length > 0 && <div className="field">
                    <p className="subtitle">tags</p>
                    <div className="tags">
                        {itemDoc.details.tags.map(tag => <a href="/" key={tag}><b>#</b>{tag}</a> )}
                    </div>
                </div>}

                {itemDoc.availability.length > 0 && 
                    <div className="field">
                        <p className="subtitle">availability</p>
                        <div className="availability flex-col-start-start">
                            {itemDoc.availability.map(av => {
                                let ret
                                let when = av.isAllDay ? <p>All Day</p> : <p>{timeToText(av.start)} - {timeToText(av.end)}</p>

                                if(av.type === "recurring"){
                                    ret = <span key={av.id} className="flex-row-center-between">Every {getDayText(av.day)} {when} </span>
                                }else if(av.type === "specific"){
                                    ret = <span key={av.id} className="flex-row-center-between">{av.day.toDate().toDateString()} {when} </span>
                                }
                                return ret
                            })}
                        </div>
                    </div>
                }

                {itemDoc.price.length > 0 && 
                    <div className="field">
                        <p className="subtitle">price</p>
                        {itemDoc.price.map(pr => {
                            let x = 0
                            itemChatDoc && itemChatDoc.itemOptions.forEach(option => {
                                {/* console.log(option) */}
                                if(pr.id === option.price){
                                    x++
                                }
                            })
                            return <div onClick={()=> handlePriceClick(pr.id)} key={pr.id} className="price flex-col-start-start">
                                <span className="flex-row-center-between">{pr.size} <p>${pr.amount}</p> </span>
                                <p className="note subtitle">{pr.note.length > 0 ? pr.note : pr.size }</p>
                                {x > 0 && 
                                    <div className="cart flex-row-center-end">
                                        <FaShoppingBasket className="basket" />
                                        <p>{x}</p>
                                    </div>
                                }
                            </div>
                        })}
                    </div>
                }

                <div className="field">
                    <p className="subtitle">reviews</p>
                    <div className="blank"></div>
                </div>

                {isItemPopupOpen && 
                    <div className="modal-container-backdrop">
                        <div className="modal-container">
                            <FaTimes onClick={()=> {setIsItemPopupOpen(false); setselectedPrice(null); }} className="modal-close" />
                            <div className="singleImage">
                                <img src={getProperItemImage(itemDoc.images)} alt="" />
                            </div>
                            <span className="title">{itemDoc.details.name} {selectedPrice.size}
                                <label>${selectedPrice.amount}</label>
                            </span>

                            {itemDoc.customization.map(cust => (
                                <div key={cust.id} className="field-checkbox">
                                    <label>
                                        <input type="checkbox" onChange={(e)=> handlePriceBoxChange(e, cust.amount, cust.id)} />
                                        <span>
                                            {cust.size}
                                            {cust.amount === 0 ? <label>FREE</label> : <label>${cust.amount.toFixed(2)}</label>}
                                        </span>
                                    </label>
                                    <p className="subtitle">{cust.note}</p>
                                </div>
                            ))}

                            <div className="field">
                                <span>total amount ($)</span>
                                <input type="text" value={popupTotal} disabled />
                            </div>
                            {user ? businessDoc.uid === user.uid ? 
                                <button onClick={()=> navigate(`/store/${businessid}`)} className="submit">Looks Good</button> 
                                : itemChatDoc && itemChatDoc.completed && !itemChatDoc.assignedUsers.includes(user.uid) ? 
                                <button className="submit">Please ask store owner to close the past order</button>
                                : itemChatDoc && itemChatDoc.completed && itemChatDoc.assignedUsers.includes(user.uid) ? 
                                <button className="submit">Please close the past order first</button>
                                : !itemChatError && itemDoc ?
                                <button onClick={handleAddOrderClick} className="submit">Add to Order</button>
                                :
                                <button onClick={handleStartOrderClick} className="submit">Start Order</button>
                                :
                                <button onClick={()=> navigate('/login')} className="submit">Login or Signup to Order</button>
                            }
                        </div>
                    </div>
                }
                

            </>}

        </div>
    )
}
