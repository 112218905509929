import './Nearby.css'
import { useMap } from '../../hooks/useMap'
// import { Link } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useDocument } from '../../hooks/useDocument'
import { useCollection } from '../../hooks/useCollection'

import { getGeocode  } from 'use-places-autocomplete'
import { useEffect, useState } from 'react'
import usePrompt from '../../hooks/usePrompt'
import { useNavigate } from 'react-router-dom'
import { useGeoFire } from '../../hooks/useGeoFire'

import * as geofire from 'geofire-common'
import { Link } from 'react-router-dom'
import { useIsMobile } from '../../hooks/useIsMobile'


import defaultMarkerIcon from '../../icons/storefront_black_48dp.svg'
import { deliveryObjToText, getDeliveryTypeText, getProperItemImage, kmToMiles } from '../../helper/helper'
import { useGeocode } from '../../hooks/useGeocode'

const torontoLocation = {
    lat: 43.664495, 
    lng: -79.392388
}

export default function Nearby() {
    const { user } = useAuthContext()
    const { document, error, isPending } = useDocument('userinfo', user && user.uid)
    const { prompt, promptChoice } = usePrompt()
    const navigate = useNavigate()
    const { isMobile } = useIsMobile()

    const { getAllBusinessWithinRadius } = useGeoFire()
  
    const [addressCoords, setAddressCoords] = useState({
        lat: 43.664495, 
        lng: -79.392388
    });
    const [userLocation, setUserLocation] = useState(null);
    const [mapMarkers, setMapMarkers] = useState([]);
    const [distance, setDistance] = useState(2);
    const [zoom, setZoom] = useState(15);

    const { geocodeAddress } = useGeocode()

    const [selectedBusiness, setSelectedBusiness] = useState(null);


    const MapCard = ({ business }) => {
        // console.log(business)
        const { document: userinfo } = useDocument('userinfo', business.uid)
        const { documents: items } = useCollection('items', [
            ['businessid', '==', business.id]
        ])

        // console.log(userinfo, items)
        const [currentItems, setcurrentItems] = useState(null);
        const [currentInfo, setcurrentInfo] = useState(null);

        const [distance, setDistance] = useState(null);


        useEffect(() => {
            if(userinfo && items && userLocation){
                setcurrentInfo(userinfo)
                setcurrentItems(items)

                setDistance(geofire.distanceBetween([userLocation.lat, userLocation.lng], [business.about.location.lat, business.about.location.lng]))

            }
            // eslint-disable-next-line
        }, [userinfo, items, userLocation])

        const maxItemsMobile = 3
        const maxItemsDesktop = 5



        return (
            <div className="nearby-mapcard flex-col-center-start">
                <img className="close" onClick={()=> setSelectedBusiness(null)} src="/icons/close_white_48dp.svg" alt=""  />
                <Link to={`/store/${business.id}`} className="store">
                    <img className="banner" src={business.about.bannerURL ? business.about.bannerURL : '/logo.png'} alt="" />
                    <img src={business.about.logoURL ? business.about.logoURL : '/logo.png'} alt="" className="logo" />
                </Link>
                <div className="featured flex-row-center-between">
                    {isMobile && <>
                        {
                            currentItems && currentItems.map((item, index) => {
                                let img = getProperItemImage(item.images)
                                if(index < maxItemsMobile){
                                    return <Link key={item.id} to={`/store/${business.id}/${item.id}`} className="menu-widget">
                                        <img src={img} alt="" />
                                    </Link>
                                }else{
                                    return null
                                }
                            })
                        }
                    </>}
                    {!isMobile && <>
                        {
                            currentItems && currentItems.map((item, index) => {
                                let img = getProperItemImage(item.images)
                                if(index < maxItemsDesktop){
                                    return <Link key={item.id} to={`/store/${business.id}/${item.id}`} className="menu-widget">
                                        <img src={img} alt="" />
                                    </Link>
                                }else{
                                    return null
                                }
                            })
                        }
                    </>}
                </div>
                <div className="about flex-col-start-start">
                    <div className="storename">{business.about.name}</div>
                    <div className={isMobile ? "info flex-col-start-center" : "info flex-row-center-center"}>
                        
                        {currentInfo && currentInfo.premium.status === 'active' && 
                            <span className="flex-row-start-center"> <img src="/icons/premium_white_48dp.svg" alt="" /> Premium Member</span>
                        }
                        <span className="flex-row-start-center"> <img src="/icons/star_white_48dp.svg" alt="" /> 4.4 (800+ ratings) </span>
                        {distance && 
                            <span className="flex-row-start-center"> <img src="/icons/public_white_48dp.svg" alt="" />{parseFloat(kmToMiles(distance)).toFixed(2)} mi</span>
                        }
                        {business && 
                            <span className="flex-row-start-center"><img src="/icons/delivery_dining_white_48dp.svg" alt="" />{deliveryObjToText(business.about.delivery)}</span>
                        }
                    </div>
                </div>
            </div>
        )
    }


    const { isLoaded, Search, mapRef, ZMap } = useMap({
        home: {
            lat: addressCoords && addressCoords.lat, 
            lng: addressCoords && addressCoords.lng,
            isVisible: true,
            onClick: e => console.log(e),
            url: '/logo.png'
        },
        center: addressCoords,
        markerIcon: defaultMarkerIcon,
        isHomeMarker: false,
        zoom: zoom,
        markers: mapMarkers,
        circle: {
            showCircle: false,
            radius: 1
        }

    })

    const handleBusinessMarkerClick =(bid)=>{
        setSelectedBusiness(bid)
        // setAddressCoords(bid.about.location)
    }



    
    const handleSearchAreaClick =()=>{
        try {
            var bounds = mapRef.current.getBounds()
            const center = mapRef.current.center
            const zoom = mapRef.current.zoom
            var start = bounds.getNorthEast();
            // var end = bounds.getSouthWest();
            var distance = geofire.distanceBetween([center.lat(), center.lng()], [start.lat(), start.lng()]) ;
            
            setAddressCoords({
                lat: center.lat(),
                lng: center.lng()
            })
            setZoom(zoom)
            setDistance(distance)

        } catch (error) {
            console.log(error.message);
        }
    }


    useEffect(() => {
        setMapMarkers([])
        // setCurrentBusiness([])
        if(addressCoords){
            getAllBusinessWithinRadius(addressCoords, distance)
            .then(businesslist => {

                let markerObj = []
                businesslist.forEach(business => {
                    markerObj.push({
                        center: business.about.location,
                        key: business.id,
                        url: business.about.logoURL,
                        onClick: handleBusinessMarkerClick,
                        data: business
                    })
                })
                setMapMarkers(markerObj)

                // console.log('ASASA', businesslist, currentBusiness, mapMarkers)
            })
            .catch(err => {
                console.log(err);
            })
        }
        // eslint-disable-next-line
    }, [addressCoords]);

    useEffect(() => {
        if(document){
            // console.log(document)
            geocodeAddress(document.address).then(location => {
                // console.log(location)
                setAddressCoords(location)
                setUserLocation(location)
            })
            .catch(err => {
                console.log(err)
                promptChoice({title: "Your Address isnt set up yet! Would you like to set it up now?"})
                .then(()=>{
                    navigate('/account')
                })
                .catch(()=>{
                    setAddressCoords(torontoLocation)
                    setUserLocation(torontoLocation)
                })
            })
        }else{
            console.log('user is logged out?')
            setAddressCoords(torontoLocation)
            setUserLocation(torontoLocation)
        }
        // eslint-disable-next-line
    }, [document])

    return (
        <div className="nearby-container flex-col-center-end">
            {prompt}
            { isLoaded && !isPending && ZMap}
            {isLoaded && !isPending && <Search isNavigator={true} useCustomButton={{
                status: true,
                text: "Search this area",
                handleClick: handleSearchAreaClick
            }} />}

            {/* {!document.address || document.address === "" && <span>Please Enter your Address in your account settings.</span> } */}
            
            {selectedBusiness && <MapCard business={selectedBusiness} />}
            
            
            
        </div>
        
    )
}
