import { useEffect, useState } from "react"
import { getDayText, timeToText } from "../../../../helper/helper"


export default function BMIAvailabilityWidget({ data, update, dlete }) {

    const [onEdit, setOnEdit] = useState(false)

    const [type, setType] = useState("na")
    // const [specificDate, setSpecificDate] = useState(`${getDateNow().year}-${getDateNow().month}-${getDateNow().day}`)
    const [day, setDay] = useState("")
    const [isAllDay, setIsAllDay] = useState(false)
    const [start, setStart] = useState("08:00")
    const [end, setEnd] = useState("17:00")

    const handleSubmit =()=>{
        update({
            id: data.id,
            isAllDay,
            start,
            end
        })
        setOnEdit(false)
    }
    const handleDelete =()=>{
        dlete(data.id)
        setOnEdit(false)
    }

    useEffect(() => {
        if(isAllDay){
            setStart('00:00')
            setEnd('23:59')
        }else{
            setStart("08:00")
            setEnd("17:00")
        }
    }, [isAllDay])



    useEffect(() => {
        setType(data.type)
        setDay(data.type === "recurring" ? `Every ${getDayText(data.day)}` : data.day.toDate().toDateString() )
        setIsAllDay(data.isAllDay)
        setStart(data.start)
        setEnd(data.end)
    }, [data]);


    return (
        <div className="availability-widget ">
            <div className="header w100 flex-row-center-between">
                <div className="datetime flex-col-start-center">
                    <span>{day}</span>
                    <span className="subtitle">{isAllDay ? "All Day" : `${timeToText(start)} - ${timeToText(end)}` }</span>
                </div>
                {!onEdit && <img onClick={()=> setOnEdit(true)} src="/icons/edit_black_48dp.svg" alt="" />}
                {onEdit && <div>
                    <img onClick={handleDelete} src="/icons/delete_black_48dp.svg" alt="" />
                    <img onClick={handleSubmit} src="/icons/save_black_48dp.svg" alt="" />
                </div>}
               
            </div>
            {onEdit && 
                <div className="editable flex-col-start-start w100">
                    <span className="subtitle">Type</span>
                    <input type="text" value={type} onChange={e => setType(e.target.value)} disabled />
                    
                    <label className="subtitle"><input checked={isAllDay} onChange={()=> setIsAllDay(!isAllDay)} type="checkbox" /> All Day? </label>
                    <div className="double flex-row-center-between w100">
                        <div className="field w100 flex-col-start-center">
                            <span className="subtitle">Start</span>
                            <input type="time" value={start} onChange={e => setStart(e.target.value)} disabled={isAllDay} />
                        </div>
                        <div className="field w100 flex-col-start-center">
                            <span className="subtitle">End</span>
                            <input type="time" value={end} onChange={e => setEnd(e.target.value)} disabled={isAllDay} />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
