import { useMemo, useRef } from "react"
import { useState, useEffect } from "react"
import { useCollection } from '../hooks/useCollection'
import { useDocument } from "../hooks/useDocument"
import { projectFirestore, timestamp } from "../firebase/config"
import { useGeoFire } from "../hooks/useGeoFire"
import { useAuthContext } from '../hooks/useAuthContext'
import { useGeocode } from "../hooks/useGeocode"
import { decodeCipher } from "../helper/helper"


export const useHome =(data)=>{

    const { user } = useAuthContext()
    const [isPending, setIsPending] = useState(true)
    const { getAllBusinessWithinRadius } = useGeoFire()
    const { geocodeAddress } = useGeocode()
    const { document: userinfo } = useDocument('userinfo', user && user.uid)
    
    const [businessOrderAgainDummy, setBusinessOrderAgainDummy] = useState([])

    
    const [items, setItems] = useState([])

    const [businessNear, setBusinessNear] = useState([])
    const [businessNew, setBusinessNew] = useState([])
    const [businessOrderAgain, setBusinessOrderAgain] = useState([])
    const [businessTop, setBusinessTop] = useState([])
    
    let homedata = { businessTop, businessOrderAgain, businessNew, businessNear, items }


    // heirarchy with no selected items : businesstop, businessOrderAgain, businessNear, businessNew
    // heirarchy with selected items : businesstop, businessOrderAgain, items, businessNear, businessNew

    function sortBusinessByRatings(res){
        let totalCount = 0, totalAverage = 0
        res.forEach(b => {
            totalCount += parseFloat(b.itemratings.count)
            totalAverage += parseFloat(b.itemratings.average)
        })

        // console.log(totalCount, totalAverage);
        let newRes = []
        res.forEach(b => {
            newRes.push( {
                ...b,
                infodata: ((b.itemratings.count / totalCount) * 100) + ((b.itemratings.average / totalAverage) * 100)
            })
        })
        return newRes.sort((a,b) => a.infodata < b.infodata ? 1 : -1)
    }
    function removeDuplicateBusiness(businessid, compare){
        let newObj = []
        compare.forEach((bagain, index) => {
            if(bagain.id === businessid){
                newObj = compare.splice(index, 1)
            }
        })
        compare = newObj
    }

    useEffect(() => {
        setIsPending(true)

        homedata.businessTop.forEach(btop => {
            // removeDuplicateBusiness(btop.id, homedata.businessOrderAgain)
            removeDuplicateBusiness(btop.id, homedata.businessNew)
            removeDuplicateBusiness(btop.id, homedata.businessNear)

            let newObj = []
            homedata.items.forEach((item, index) => {
                if(item.businessid === btop.id){
                    newObj = items.splice(index, 1)
                }
            })
            homedata.items = newObj
        })
        
        setIsPending(false)

        console.log('homedata', homedata);

    }, [businessOrderAgain, businessNew, businessNear, items, businessTop])
    

    // getting nearby and order again data
    useEffect(() => {
        setIsPending(true)
        if(userinfo){
            geocodeAddress(userinfo.address).then(data => {
                getAllBusinessWithinRadius(data, 20, 10).then(data => {
                    setBusinessNear(data)
                })
                .catch(err => {
                    console.log(err);
                })
            })
            .catch(err => {
                console.log(err);
            })


            let userinforet = []
            userinfo.orders && userinfo.orders.forEach((or, index) => {
                const cipher = decodeCipher(or.chikkaID)
                if(index === 0){
                    userinforet.push(cipher.businessid)
                }else{
                    let gate = true
                    userinforet.forEach(bid => {
                        if(bid === cipher.businessid){
                            gate = false
                        }
                    })
                    if(gate){
                        userinforet.push(cipher.businessid)
                    }

                    setBusinessOrderAgainDummy(userinforet)
                }
            })

        }

    }, [userinfo])

    //getting items with selected options
    useEffect(() => {
        setIsPending(true)

        const _itemWhere = [...data.category, data.type]
        const itemWhere = [
            "category",
            'array-contains-any',
            _itemWhere
        ]

        let store = projectFirestore.collection('items').where(...itemWhere)

        const unsub = store.onSnapshot(snapshot =>{
            let res = []
            snapshot.docs.forEach(doc => {
                res.push({ ...doc.data(), id: doc.id })
            })
            setItems(res)
            setIsPending(false)
        }, err=>{
            console.log(err)
            setIsPending(false)
        })

        return () => unsub()

    }, [data])

    // getting business with createdAt less than a month ago
    useEffect(() => {
        setIsPending(true)
        const d = new Date()
        d.setDate(d.getDate() - 30)

        // console.log('testdate', d)
        const sdate = timestamp.fromDate(d)

        let store = projectFirestore.collection('business').where("createdAt", '>', sdate).orderBy('createdAt', "desc").orderBy('itemratings.average', "desc").limit(10)

        const unsub = store.onSnapshot(snapshot =>{
            let res = []
            snapshot.docs.forEach(doc => {
                res.push({ ...doc.data(), id: doc.id })
            })

            setBusinessNew(sortBusinessByRatings(res))
            // console.log('AAA',res)
            setIsPending(false)
        }, err=>{
            console.log(err)
            setIsPending(false)
        })

        return () => unsub()

    }, [data])

    // getting business with top ratings
    useEffect(() => {
        setIsPending(true)
        // console.log(data);

        let store = projectFirestore.collection('business').where("itemratings.average", '>=', data.rating).orderBy('itemratings.average', "desc").orderBy('itemratings.count', "desc").limit(10)

        const unsub = store.onSnapshot(snapshot =>{
            let res = []
            snapshot.docs.forEach(doc => {
                res.push({ ...doc.data(), id: doc.id })
            })

            // console.log(newRes);
            setBusinessTop(sortBusinessByRatings(res))

            setIsPending(false)
        }, err=>{
            console.log(err)
            setIsPending(false)
        })

        return () => unsub()

    }, [data])




    useEffect(() => {
        
        businessOrderAgainDummy.forEach(bid => {
            let store = projectFirestore.collection('business').doc(bid)

            store.get().then(snapshot =>{
                setBusinessOrderAgain(ps => [...ps, {...snapshot.data(), id: snapshot.id}])
                setIsPending(false)
            })
            .catch(err => {
                console.log(err)
                setIsPending(false)
            })
        })


    }, [businessOrderAgainDummy])




    return { homedata, isPending }
}