import './Home.css'

// import { useFirestore } from '../../hooks/useFirestore'
// import { useCollection } from '../../hooks/useCollection'
import { useLocation } from 'react-router';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useModal } from '../../hooks/useModal';
import { useNavigate } from 'react-router';
import CategoryWidget from './CategoryWidget';

import { FaPeopleCarry, FaShippingFast, FaStar, FaCoins, FaAngleLeft, FaAngleRight } from "react-icons/fa"
import Select from 'react-select'
import { REACT_SELECT_STYLE, sideScroll } from '../../helper/helper';
import { WEBCATS } from '../../helper/helper';
import PromotionsWidget from './PromotionsWidget';
import RestaurantsWidget from './RestaurantsWidget';
import Modal from '../../helper/Modal';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useHome } from '../../model/useHome';

const errorList = {
    ITEM_INVALID_USER: <><h3 className="error">AN ERROR HAS OCCURED</h3> <h2>ITEM ERROR</h2> <span>Invalid Auth Token Received</span></>,
    ITEM_INVALID_TOKEN: <><h3 className="error">AN ERROR HAS OCCURED</h3> <h2>ITEM ERROR</h2> <span>Invalid Token Received</span></>,
    ITEM_INVALID_ID: <><h3 className="error">AN ERROR HAS OCCURED</h3> <h2>ITEM ERROR</h2> <span>Invalid ID Received</span><span>No special characters allowed</span></>,
    ITEM_LESS_ID_LENGTH: <><h3 className="error">AN ERROR HAS OCCURED</h3> <h2>ITEM ERROR</h2> <span>Invalid ID Received</span><span>ID must be greater than 8 characters</span></>,
    ITEM_GREAT_ID_LENGTH: <><h3 className="error">AN ERROR HAS OCCURED</h3> <h2>ITEM ERROR</h2> <span>Invalid ID Received</span><span>ID must be lesser than 15 characters</span></>,
    
    BUSINESS_LESS_ID_LENGTH: <><h3 className="error">AN ERROR HAS OCCURED</h3> <h2>BUSINESS ERROR</h2> <span>Invalid ID Received</span><span>ID must be greater than 8 characters</span></>,
    BUSINESS_GREAT_ID_LENGTH: <><h3 className="error">AN ERROR HAS OCCURED</h3> <h2>BUSINESS ERROR</h2> <span>Invalid ID Received</span><span>ID must be lesser than 15 characters</span></>,
    BUSINESS_INVALID_AUTH: <><h3 className="error">AN ERROR HAS OCCURED</h3> <h2>BUSINESS ERROR</h2> <span>Invalid Auth Token Received</span></>,
    BUSINESS_INVALID_TOKEN: <><h3 className="error">AN ERROR HAS OCCURED</h3> <h2>BUSINESS ERROR</h2> <span>Invalid Token Received</span></>,
    BUSINESS_REQUIREMENTS_NOT_MET : <><h3 className="error">AN ERROR HAS OCCURED</h3> <h2>BUSINESS ERROR</h2> <span>User does not meet the requirements to create a new business.</span></>,
    STORE_INVALID_ID: <><h3 className="error">AN ERROR HAS OCCURED</h3> <h2>STORE ERROR</h2> <span>Invalid Store ID provided.</span></>,
    STORE_NO_CATEGORIES: <><h3 className="error">AN ERROR HAS OCCURED</h3> <h2>STORE ERROR</h2> <span>The owner of the store has not setup its Items and Categories. Ask them to Set it up now!</span></>,
}
const uidata = [
    // {
    //     label: "TYPE",
    //     options: WEBCATS.TYPE
    // },
    {
        label: "TEXTURE",
        options: WEBCATS.TEXTURE
    },{
        label: "STYLE",
        options: WEBCATS.STYLE
    },{
        label: "SPECIAL",
        options: WEBCATS.SPECIAL
    }
]
const promotionsData = [
    {
        key: "b1",
        img: '/samplefood.jpg',
        title: "Start your day with a Slimey Bagel burnt with Coffee Grinds",
        subtitle: "Breakfast Smarter"
    },{
        key: "b2",
        img: '/samplefood.jpg',
        title: "Crazy Sandwich with expired Bacon mashed into eggs and strawberries",
        subtitle: "Breakfast Dumber"
    },{
        key: "b3",
        img: '/samplefood.jpg',
        title: "Crazy Sandwich with expired Bacon mashed into eggs and strawberries",
        subtitle: "Breakfast Dumber"
    }
]
const restaurant1Data = [
    {
        key: "a1",
        img: '/samplefood.jpg',
        title: "Bagel Express",
        p1: "$$",
        p2: "Free Delivery Over $12",
        p3: "50 Min"
    },{
        key: "a2",
        img: '/samplefood.jpg',
        title: "Ambagel Express",
        p1: "$$",
        p2: "Free Delivery Over $12",
        p3: "50 Min"
    },{
        key: "a3",
        img: '/samplefood.jpg',
        title: "Atiwel Express",
        p1: "$$",
        p2: "Free Delivery Over $12",
        p3: "50 Min"
    },{
        key: "a4",
        img: '/samplefood.jpg',
        title: "Akatok Express",
        p1: "$$",
        p2: "Free Delivery Over $12",
        p3: "50 Min"
    }
]

export default function Home() {

    // const { addDocument } = useFirestore('delivery')
    function useQuery() {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();
    const navigate = useNavigate()
    const conRef = useRef()
    const { isMobile } = useIsMobile()

    const modalCallback =()=>{
        navigate('/')
    }
    const { showModal,  modal } = useModal(modalCallback)
    
    const [storeCategoryValue, setStoreCategoryValue] = useState([])
    const [storeTypeCategoryLabel, setStoreTypeCategoryLabel] = useState('')
    const [storeTypeCategoryValue, setStoreTypeCategoryValue] = useState('')

    const [isDeliveryPopupOpen, setIsDeliveryPopupOpen] = useState(false)
    const [deliveryPopupIcon, setDeliveryPopupIcon] = useState(<FaShippingFast />)
    const [deliveryPopupValue, setDeliveryPopupValue] = useState({ label: 'Free', value: 'free' })
    const [selectedDeliveryOption, setselectedDeliveryOption] = useState('free')
    
    const [isRatingPopupOpen, setIsRatingPopupOpen] = useState(false)
    const [ratingPopupValue, setRatingPopupValue] = useState(4.5)
    const [selectedRatingOption, setSelectedRatingOption] = useState(4.5)

    const [homeData, setHomeData] = useState({
        rating: selectedRatingOption,
        delivery: selectedDeliveryOption,
        type: storeTypeCategoryValue,
        category: storeCategoryValue && storeCategoryValue.map(cat => cat.value)
    })

    


    
    // 'FIX FLICKERING, try USEMEMO'

    const { homedata, isPending: isHomeDataPending } = useHome(homeData)

    useEffect(() => {
        setHomeData({
            rating: selectedRatingOption,
            delivery: selectedDeliveryOption,
            type: storeTypeCategoryValue,
            category: storeCategoryValue && storeCategoryValue.map(cat => cat.value)
        })
    }, [selectedRatingOption, selectedDeliveryOption, storeTypeCategoryValue, storeCategoryValue])

    useEffect(() => {
        // console.log('isHomeDataPending', isHomeDataPending);
        
    }, [isHomeDataPending])
    

    useEffect(() => {
        if(query.has('error')){
            // console.log(errorList[query.get('error')] )
            showModal( errorList[query.get('error')] ? errorList[query.get('error')] : <h3 className="error">AN ERROR HAS OCCURED</h3> )
        }
        // eslint-disable-next-line
    }, [query])


    const scroll =(direction)=>{
        const container = conRef.current
        sideScroll(container, direction);
    }


    const handleStoreCatChange =(option)=>{
        setStoreCategoryValue(option)
    }
    const handleStoreTypeClick =(option, value)=>{

        if(storeTypeCategoryLabel !== option){
            setStoreTypeCategoryValue(value)
            setStoreTypeCategoryLabel(option)
        }else{
            setStoreTypeCategoryValue('')
            setStoreTypeCategoryLabel('')
        }
    }

    const handleDeliveryPopupChange =(option)=>{
        setDeliveryPopupValue(option)
        if(option.value === 'free'){
            setDeliveryPopupIcon(<FaShippingFast />)
        }else if(option.value === 'pickup'){
            setDeliveryPopupIcon(<FaPeopleCarry />)
        }else if(option.value === 'paid'){
            setDeliveryPopupIcon(<FaCoins />)
        }
    }
    const handleDeliveryApplyClick =()=>{
        setselectedDeliveryOption(deliveryPopupValue.value)
        setIsDeliveryPopupOpen(false)
    }
    const handleDeliveryCancelClick =()=>{
        // setselectedDeliveryOption(deliveryPopupValue.value)
        setDeliveryPopupValue({
            label: selectedDeliveryOption.charAt(0).toUpperCase()  + selectedDeliveryOption.slice(1),
            value: selectedDeliveryOption
        })
        if(selectedDeliveryOption.value === 'free'){
            setDeliveryPopupIcon(<FaShippingFast />)
        }else if(selectedDeliveryOption.value === 'pickup'){
            setDeliveryPopupIcon(<FaPeopleCarry />)
        }else if(selectedDeliveryOption.value === 'paid'){
            setDeliveryPopupIcon(<FaCoins />)
        }
        setIsDeliveryPopupOpen(false)
    }
    const handleRatingApplyClick =()=>{
        setIsRatingPopupOpen(false)
        setSelectedRatingOption(ratingPopupValue)
    }
    const handleRatingCancelClick =()=>{
        setIsRatingPopupOpen(false)
        setRatingPopupValue(selectedRatingOption)
    }

    

    return (
        <div className='home-wrapper flex-col-center-start'>
            <div className="home-container flex-col-center-start">
                {modal}
                <div className="category-widget-wrapper">
                    <div ref={conRef} className="category-widget flex-row-center-start">
                        {WEBCATS.TYPE.map(cat => <CategoryWidget key={cat.value} value={storeTypeCategoryLabel} set={handleStoreTypeClick} title={cat.label} icon={cat.icon} v={cat.value} />)}
                     
                    </div>
                    {!isMobile && <>
                        <div className='scroller right flex-row-center-center'>
                            <FaAngleRight className='scroller' onClick={()=> scroll('right')} />
                        </div>
                        <div className='scroller left flex-row-center-center'>
                            <FaAngleLeft className='scroller' onClick={()=> scroll('left')} />
                        </div>
                    </>}
                </div>
                
                <Select 
                    options={uidata}
                    onChange={option => handleStoreCatChange(option)}
                    placeholder="Select Additional Category"
                    isMulti
                    value={storeCategoryValue}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null, ClearIndicator:() => null }}
                    styles={REACT_SELECT_STYLE}
                    menuPosition={'fixed'} 
                    className="react-select"
                />

                <div className="filter-widget flex-row-center-between">

                    {/* <div className="widget flex-row-center-start active" >
                        {deliveryPopupIcon}
                        <span onClick={()=> setIsDeliveryPopupOpen(true)} >{deliveryPopupValue.label}</span>
                    </div> */}

                    <div className="widget flex-row-center-start active" >
                        {deliveryPopupIcon}
                        <span onClick={()=> setIsDeliveryPopupOpen(true)} >{deliveryPopupValue.label}</span>
                        {isDeliveryPopupOpen &&
                            <Modal
                                closeModal={()=> setIsDeliveryPopupOpen(false)}
                            >
                                <div className="home-popup flex-col-start-start">
                                    <span className="title">Delivery</span>
                                    <span className="subtitle">{deliveryPopupValue.label}</span>
                                    <div className="delivery flex-row-center-between">
                                        <Select 
                                            options={[
                                                {
                                                    label: 'Free',
                                                    value: 'free'
                                                },{
                                                    label: 'Pickup',
                                                    value: 'pickup'
                                                },{
                                                    label: 'Paid',
                                                    value: 'paid'
                                                }
                                                // ,{
                                                //     label: 'Free on Distance',
                                                //     value: 'freeondistance'
                                                // },{
                                                //     label: 'Free on Minimum Order',
                                                //     value: 'freeonminimumprice'
                                                // },{
                                                //     label: 'Free on Minimum Order and Distance',
                                                //     value: 'freeonminimumpriceanddistance'
                                                // }
                                            ]}
                                            onChange={option => handleDeliveryPopupChange(option)}
                                            placeholder="Select Delivery Type"
                                            value={deliveryPopupValue}
                                            // defaultValue={deliveryPopupValue}
                                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null, ClearIndicator:() => null }}
                                            styles={REACT_SELECT_STYLE}
                                            menuPosition={'fixed'} 
                                            className="react-select"
                                        />
                                    </div>
                                    <br />
                                    <hr />
                                    <div className="action flex-row-center-end">
                                        <span onClick={handleDeliveryCancelClick}>Cancel</span>
                                        <span onClick={handleDeliveryApplyClick} className="button">Apply Filter</span>
                                    </div>
                                </div>
                            </Modal>
                        }
                    </div>

                    <div className="widget flex-row-center-start active">
                        <FaStar />
                        <span onClick={()=> setIsRatingPopupOpen(true)}>Over {ratingPopupValue}</span>
                        {isRatingPopupOpen && 
                            <Modal
                                closeModal={()=> setIsRatingPopupOpen(false)}
                            >
                                <div className="home-popup  flex-col-start-start">
                                    <span className="title">Ratings</span>
                                    <span className="subtitle">Over {ratingPopupValue}</span>
                                    <div className="rating flex-row-center-between">
                                        <div onClick={()=> setRatingPopupValue(3)} className="ratingwidget flex-col-center-center">
                                            <FaStar className={ratingPopupValue >= 3 && "active"} />
                                            <span>3</span>
                                        </div>
                                        <div onClick={()=> setRatingPopupValue(3.5)} className="ratingwidget flex-col-center-center">
                                            <FaStar className={ratingPopupValue >= 3.5 && "active"} />
                                            <span>3.5</span>
                                        </div>
                                        <div onClick={()=> setRatingPopupValue(4)} className="ratingwidget flex-col-center-center">
                                            <FaStar className={ratingPopupValue >= 4 && "active"} />
                                            <span>4</span>
                                        </div>
                                        <div onClick={()=> setRatingPopupValue(4.5)} className="ratingwidget flex-col-center-center">
                                            <FaStar className={ratingPopupValue >= 4.5 && "active"} />
                                            <span>4.5</span>
                                        </div>
                                        <div onClick={()=> setRatingPopupValue(5)} className="ratingwidget flex-col-center-center">
                                            <FaStar className={ratingPopupValue >= 5 && "active"} />
                                            <span>5</span>
                                        </div>
                                    </div>
                                    <br />
                                    <hr />
                                    <div className="action flex-row-center-end">
                                        <span onClick={handleRatingCancelClick}>Cancel</span>
                                        <span onClick={handleRatingApplyClick} className="button">Apply Filter</span>
                                    </div>
                                </div>
                            </Modal>
                        }
                    </div>

                  

                </div>

                <br />
                <div className="promotions-widget flex-row-center-start">
                    {promotionsData.map(res =>
                        <PromotionsWidget key={res.key} img={res.img} title={res.title} subtitle={res.subtitle}/>
                    )}
                </div>

                {!isHomeDataPending && <>

                    <br /> 
                    {homedata.items.length > 0 && 
                        <RestaurantsWidget title="Results" data={restaurant1Data} itemdata={homedata.items} />
                    }

                    <br /> 
                    {homedata.businessTop.length > 0 && 
                        <RestaurantsWidget title="Top Picks" data={restaurant1Data} businessdata={homedata.businessTop} />
                    }

                    <br /> 
                    {homedata.businessOrderAgain.length > 0 && 
                        <RestaurantsWidget title="Order Again" data={restaurant1Data} businessdata={homedata.businessOrderAgain} />
                    }
                    
                    <br /> 
                    {homedata.businessNear.length > 0 && 
                        <RestaurantsWidget title="Fastest Near You" data={restaurant1Data} businessdata={homedata.businessNear} />
                    }
                    
                    <br /> 
                    {homedata.businessNew.length > 0 && 
                        <RestaurantsWidget title="Rising Stars" data={restaurant1Data} businessdata={homedata.businessNew} />
                    }


                </>}

                
                
                

                <br />
                <br />


            </div>
        </div>
    )
}
