import { useContext, useEffect, useRef } from "react"
import { FaTimes } from "react-icons/fa"
import { useDocument } from "../../hooks/useDocument"
import { useParams } from "react-router-dom"
import { useFirestore } from '../../hooks/useFirestore'
import { useState } from "react"
import { useCollection } from "../../hooks/useCollection"
// import { ItemContext } from "./Store"

import { projectFirestore } from "../../firebase/config"
import StoreBasket from "./StoreBasket"
import { rngPassword } from "../../helper/helper"

export default function StoreOrders({ onClose, data }) {

    const { itemid } = useParams()
    const {itemChatDoc, itemChatError, business} = data

    // console.log('itemChatDoc', itemChatDoc);
    

    const [itemList, setItemList] = useState([])
    const [orderList, setOrderList] = useState([]);
    const { updateDocument } = useFirestore('itemchat')

    // console.log(orderList);

    useEffect(() => {
        if(itemChatDoc && itemChatDoc.items.length > 0){

            let ret = []
            projectFirestore.collection('items').where('itemid', 'in', itemChatDoc.items).onSnapshot(snapshot =>{
                // console.log(snapshot);
                // console.log(snapshot.docs);
                snapshot.docs.forEach(doc => {
                    if(doc.exists){
                        // console.log(doc.data())
                        ret.push({ ...doc.data(), id: doc.id })
                        // setItemList(ps => [...ps, { ...doc.data(), id: doc.id }])
                    }
                })

                setItemList(ret)
            }, err=>{
                console.log(err)
            })

            
        }

    }, [itemChatDoc]);

    useEffect(() => {

        if(itemChatDoc){
            let ret = []
            itemList.forEach(item => {
                let options = []
                itemChatDoc.itemOptions.forEach(itemop => {
                    let price = item.price.filter(pr => pr.id === itemop.price)[0]
                    let customization = []

                    itemop.customization.forEach(ic => {
                        // const z = item.customization.filter(ic2 => ic2.id === ic)
                        // console.log('z', ic, z);
                        item.customization.forEach(ic2 => {
                            // console.log('z', ic, ic2.id, ic2.id === ic);
                            if(ic2.id === ic){
                                customization.push({
                                    id: ic,
                                    name: ic2.size,
                                    amount: parseFloat(ic2.amount)
                                })
                            }
                        })
                        
                    })

                    if(item.itemid === itemop.itemid){
                        options.push({
                            price,
                            customization,
                            id: itemop.id
                        })
                    }
                })
                ret.push({
                    id: item.id,
                    name: item.details.name,
                    options
                })
            })

            // console.log('ret', ret);
            setOrderList(ret)
        }
        
    }, [itemChatDoc, itemList]);


    const modalRef = useRef()

    useEffect(() => {
        document.body.style.overflow = 'hidden'

        return ()=> document.body.style.overflow = 'unset'
    }, [])


 

    const closeClick =()=>{
        onClose()
    }

    const handleClick =e=>{
        if(e.target !== modalRef.current){
            return
        }else{
            onClose();
        }
    }
    const handleBasketDeleteItem =(id)=>{
        // console.log('handleBasketDeleteItem', id);
        const newOptions = itemChatDoc.itemOptions.filter(option => option.id !== id)
        // console.log(newOptions);

        let newItems = [...itemChatDoc.items]
        itemChatDoc.items.forEach(item => {
            let gate = true
            newOptions.forEach(option => {
                // console.log(option.itemid, item, option.itemid === item)
                if(option.itemid === item){
                    gate = false
                }
            })

            if(gate){
                // console.log('remove', item);
                newItems = newItems.filter(zitem => {
                    // console.log(zitem, item, zitem !== item)
                    return zitem !== item
                })
            }
        })

        
        
        // console.log(newItems)

        updateDocument(itemChatDoc.id, {itemOptions: newOptions, items: newItems})
    }
    const handleBasketDeleteCustomization =(id, custid)=>{
        console.log('handleBasketDeleteCustomization', id, custid);
        const selOp = itemChatDoc.itemOptions.filter(option => option.id === id)
        // const newSelOp = {...selOp[0], customization:  selOp[0].customization.filter(a => a !== custid) }
        // const oldItemOptions = itemChatDoc.itemOptions.filter(option => option.id !== id)
        const newItemOptions = [...itemChatDoc.itemOptions.filter(option => option.id !== id), {...selOp[0], customization:  selOp[0].customization.filter(a => a !== custid) }]
        updateDocument(itemChatDoc.id, {itemOptions: newItemOptions})
    }


    return (
        <div onClick={handleClick} ref={modalRef} className="store-orders-container flex-col-center-center">
            {business && 
                <div className="store-orders flex-col-center-start">
                    <FaTimes className="close" onClick={closeClick} />
                    <span className="title">Your Order with <br /> <b>{business.about.name}</b></span>

                    {orderList.length <= 0 && <div className="empty flex-row-center-center">Empty</div> }
                    
                    {orderList && orderList.length > 0 && orderList.map(ol => 
                        <StoreBasket 
                            key={ol.id} 
                            data={ol} 
                            deleteItem={handleBasketDeleteItem} 
                            deleteCustomization={handleBasketDeleteCustomization} 
                        />)}

                </div>
            }
        </div>
    )
}
