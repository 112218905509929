import Geocode from 'react-geocode'

export const useGeocode =()=>{

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
    Geocode.setRegion("ca");

    function geocodeLatlng ({ lat, lng }){
        return new Promise((res, rej)=>{
            Geocode.fromLatLng(lat, lng).then(
                (response) => {
                    res(response.results[0].formatted_address)
                },
                (error) => {
                  console.error(error);
                  rej(error.message)
                }
            );
        });
    }

    function geocodeAddress(address){
        return new Promise((res, rej)=>{
            Geocode.fromAddress(address).then(
                (response) => {
                    res( response.results[0].geometry.location )
                },
                (error) => {
                    console.error(error);
                    rej(error.message)
                }
            );
        });
    }

    return { geocodeLatlng, geocodeAddress }

}