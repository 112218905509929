import './BusinessManageCategory.css'

import { useFirestore } from '../../../hooks/useFirestore'
import { useParams } from 'react-router'
import { useCollection } from '../../../hooks/useCollection'
import BusinessManageCategoryWidget from './BusinessManageCategoryWidget'
import { useEffect, useState } from 'react'
import { rngPassword } from '../../../helper/helper'

export default function BusinessManageCategory({ document }) {
    const { businessid } = useParams()

    const { updateDocument } = useFirestore('business')
    const { documents } = useCollection('items', [ ['businessid', '==', businessid] ])

    // const itemsRef = useRef([]);
    const [categories, setCategories] = useState([])
    const [newCat, setNewCat] = useState("")

    // console.log('categories', categories)

    const handleNewCatClick =()=>{
        setCategories(ps => [...ps, {
            id: rngPassword(),
            name: newCat,
            position: ps.length
        }])
        setNewCat("")
    }

    const deleteCat =(id)=>{
        console.log(id)
        const newCatObj = []
        categories.forEach(cat => {
            if(cat.id !== id){
                newCatObj.push(cat)
            }
        })
        setCategories(newCatObj)
    }

    function getCategoryData(){
        // console.log(categories)
        updateDocument(businessid, {categories})
    }
    useEffect(() => {
        if(document){
            setCategories([...document.categories])
        }
    }, [document])
    

    return (
        <div className="business-manage-container category flex-col-center-center">
            <div className="input-button-combo flex-row-center-between">
                <input value={newCat} onChange={e => setNewCat(e.target.value)} type="text" placeholder="Add New Category" />
                <img onClick={handleNewCatClick} src="/icons/add_circle_white_48dp.svg" alt="" />
            </div>
            <br />
            {document && categories && categories.map(cat => {
                return <BusinessManageCategoryWidget businessid={businessid} setData={setCategories} key={cat.id} cat={cat} documents={documents} deleteCat={deleteCat} />
            })}
            

            <br />
            <button onClick={getCategoryData} className="saveCategory">Save Category Data</button>


        </div>
    )
}
