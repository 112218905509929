import {
    GoogleMap,
    useLoadScript,
    Marker,
    Circle
    // InfoWindow
} from '@react-google-maps/api';
import { useCallback, useMemo, useRef, useState } from 'react';
import mapStyle from '../helper/mapStyle'
import houselocation from '../icons/person_pin_black_48dp.svg'
import defaultMarkerIcon from '../icons/storefront_black_48dp.svg'
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng
} from "use-places-autocomplete";
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption
} from '@reach/combobox'
import "@reach/combobox/styles.css";
import { rngPassword } from '../helper/helper';
// import { getDistanceBetween, getHash } from '../hooks/useGeoFire'


const libraries = ["places"];
const mapContainerStyle = {
    width: "100%",
    height: "100%"
}
const gmapoptions = {
    styles: mapStyle,
    disableDefaultUI: true,
    zoomControl: true
}
// const gmapzoom = 14;

export const useMap =({  markers, circle, center, zoom, markerIcon, markerClick, isHomeMarker, onMapClick })=>{


    // console.log('initLocation', initLocation);

    const [location, setLocation] = useState({
        lat: center.lat, 
        lng: center.lng
    })
    
    // const { selectAutocomplete } = useSelectPlacesAutoComplete(location)

    

    // const center = {
    //     lat: location.lat === null ? 43.653225 : location.lat,
    //     lng: location.lng === null ? -79.383186 : location.lng
    // };


    const mapRef = useRef();
    // const onMapClick = useCallback((e) => {
    //     setLocation({
    //         lat: e.latLng.lat(), 
    //         lng: e.latLng.lng()
    //     });

        
    //     // console.log({
    //     //     lat: e.latLng.lat(), 
    //     //     lng: e.latLng.lng()
    //     // });

    // }, []);
    const onMapLoad = useCallback((map)=>{
        mapRef.current = map;
    }, []);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries
    });

    const panTo = useCallback(({lat, lng}) => {
        mapRef.current.panTo({lat, lng});
        mapRef.current.setZoom(14);
    }, []);

    

    const handleCenterClick =()=>{
        console.log('test');
        
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };
        navigator.geolocation.getCurrentPosition((position)=>{
            // console.log(position);
            const pos ={
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }
            panTo(pos);
            setLocation(pos);
            // console.log('test', pos);
    
        }, (e)=>null, [options]);
    
    }


    // const map =  isLoaded && (
    //     <div className="iframe">
    //         {/* <i onClick={handleCenterClick} className="fas fa-crosshairs" title="get your location" ></i> */}
    //         <img onClick={handleCenterClick} src="/icons/my_location_black_48dp.svg" alt="" />

    //         <GoogleMap 
    //             mapContainerStyle={mapContainerStyle} 
    //             zoom={circle.showCircle ? getZoomOnDistance(circle.radius) : gmapzoom}
    //             center={center}
    //             options={gmapoptions}
    //             onClick={onMapClick}
    //             onLoad={onMapLoad}

    //         >
    //             {home.isVisible && 
    //                 <Marker 
    //                     key="homeaddress"
    //                     position={center}
    //                     icon={{
    //                         url: houselocation,
    //                         scaledSize: new window.google.maps.Size(30,30),
    //                         origin: new window.google.maps.Point(0,0),
    //                         anchor: new window.google.maps.Point(15, 30),
    //                         labelOrigin: new window.google.maps.Point(15, 35),
    //                         cursor: 'pointer'
    //                     }}
    //                     onClick={home.onClick}
    //                 ></Marker>
    //             }
    //             { markers &&
    //                 markers.map(m => 
    //                     <Marker 
    //                         key={m.key}
    //                         position={m.center}
    //                         icon={{
    //                             url: m.url,
    //                             scaledSize: new window.google.maps.Size(30,30),
    //                             origin: new window.google.maps.Point(0,0),
    //                             anchor: new window.google.maps.Point(15, 30),
    //                             labelOrigin: new window.google.maps.Point(15, 35),
    //                             cursor: 'pointer'
    //                         }}
    //                         onClick={m.onClick}
    //                     ></Marker>
    //                 )
    //             }
                
    //             {circle && circle.showCircle &&
    //                 (
    //                     <Circle 
    //                         options={{
    //                             strokeColor: 'transparent',
    //                             // strokeOpacity: 0.8,
    //                             // strokeWeight: 2,
    //                             fillColor: '#daa520',
    //                             fillOpacity: 0.35,
    //                             clickable: false,
    //                             draggable: false,
    //                             editable: false,
    //                             visible: true,
    //                             radius:  circle.radius * 1000,
    //                             zIndex: 1
    //                         }}
    //                         // map,
    //                         center={center}
    //                     />
    //                 )
    //             }

    //         </GoogleMap>
    //     </div>
    // )



    const ZMap = useMemo(() => {

        return isLoaded && <div className="iframe">
            <GoogleMap 
                mapContainerStyle={mapContainerStyle} 
                zoom={zoom}
                center={center}
                options={gmapoptions}
                onLoad={onMapLoad}
                onClick={onMapClick}
            >
                {isHomeMarker && 
                    <Marker 
                        key="homeaddress"
                        position={center}
                        // draggable={false}
                        icon={{
                            url: markerIcon ? markerIcon : houselocation,
                            scaledSize: new window.google.maps.Size(30,30),
                            origin: new window.google.maps.Point(0,0),
                            anchor: new window.google.maps.Point(15, 30),
                            labelOrigin: new window.google.maps.Point(15, 35),
                            cursor: 'pointer'
                        }}
                        onClick={markerClick}
                    ></Marker>
                }
                { markers && markers.length > 0 && 
                    markers.map(m => {
                        return <Marker 
                            key={m.key}
                            position={m.center}
                            icon={{
                                url: m.url ? m.url : defaultMarkerIcon,
                                scaledSize: new window.google.maps.Size(30,30),
                                origin: new window.google.maps.Point(0,0),
                                anchor: new window.google.maps.Point(15, 30),
                                labelOrigin: new window.google.maps.Point(15, 35),
                                cursor: 'pointer'
                            }}
                            onClick={() => m.onClick(m.data)}
                        ></Marker>
                    })
                }
                
                {circle && circle.showCircle &&
                    (
                        <Circle 
                            options={{
                                strokeColor: 'transparent',
                                fillColor: '#daa520',
                                fillOpacity: 0.35,
                                clickable: false,
                                draggable: false,
                                editable: false,
                                visible: true,
                                radius:  circle.radius * 1000,
                                zIndex: 1
                            }}
                            center={center}
                        />
                    )
                }
            </GoogleMap>
        </div>
        // eslint-disable-next-line
    }, [markers, circle, center, zoom, markerIcon, isHomeMarker])

    const Map =({ center, zoom, markerIcon, isHomeMarker, markers, circle })=>{
        return <div className="iframe">
            <img onClick={handleCenterClick} src="/icons/my_location_black_48dp.svg" alt="" />
            <GoogleMap 
                mapContainerStyle={mapContainerStyle} 
                zoom={zoom}
                center={center}
                options={gmapoptions}
                onLoad={onMapLoad}
            >
                {isHomeMarker && 
                    <Marker 
                        key="homeaddress"
                        position={center}
                        // draggable={false}
                        icon={{
                            url: markerIcon ? markerIcon : houselocation,
                            scaledSize: new window.google.maps.Size(30,30),
                            origin: new window.google.maps.Point(0,0),
                            anchor: new window.google.maps.Point(15, 30),
                            labelOrigin: new window.google.maps.Point(15, 35),
                            cursor: 'pointer'
                        }}
                        onClick={markerClick}
                    ></Marker>
                }
                { markers && markers.length > 0 && 
                    markers.map(m => {
                        return <Marker 
                            key={m.key}
                            position={m.center}
                            icon={{
                                url: m.url ? m.url : defaultMarkerIcon,
                                scaledSize: new window.google.maps.Size(30,30),
                                origin: new window.google.maps.Point(0,0),
                                anchor: new window.google.maps.Point(15, 30),
                                labelOrigin: new window.google.maps.Point(15, 35),
                                cursor: 'pointer'
                            }}
                            onClick={() => m.onClick(m.data)}
                        ></Marker>
                    })
                }
                
                {circle && circle.showCircle &&
                    (
                        <Circle 
                            options={{
                                strokeColor: 'transparent',
                                fillColor: '#daa520',
                                fillOpacity: 0.35,
                                clickable: false,
                                draggable: false,
                                editable: false,
                                visible: true,
                                radius:  circle.radius * 1000,
                                zIndex: 1
                            }}
                            center={center}
                        />
                    )
                }

            </GoogleMap>
        </div>
    }

    // function Locate({ panTo }) {
    //     return (
    //       <button
    //         className="locate"
    //         onClick={() => {
    //           navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //               panTo({
    //                 lat: position.coords.latitude,
    //                 lng: position.coords.longitude,
    //               });
    //             },
    //             () => null
    //           );
    //         }}
    //       >
    //         <img src="/compass.svg" alt="compass" />
    //       </button>
    //     );
    // }

    function Search({ isNavigator, useCustomButton }) {
        const {
          ready,
          value,
          suggestions: { status, data },
          setValue,
          clearSuggestions,
        } = usePlacesAutocomplete({
          requestOptions: {
            location: { lat: () => 43.6532, lng: () => -79.3832 },
            radius: 100 * 1000,
          },
        });
      
        // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
      
        const handleInput = (e) => {
          setValue(e.target.value);
        };
      
        const handleSelect = async (address) => {
          setValue(address, false);
          clearSuggestions();
      
          try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            panTo({ lat, lng });
          } catch (error) {
            console.log("😱 Error: ", error);
          }
        };
      
        return (
          <div className="search">
            <Combobox onSelect={handleSelect}>
              <ComboboxInput
                value={value}
                onChange={handleInput}
                disabled={!ready}
                placeholder="Search Location"
              />
                {isNavigator && <img onClick={handleCenterClick} src="/icons/my_location_black_48dp.svg" alt="" />}
                {useCustomButton && useCustomButton.status && <button onClick={useCustomButton.handleClick}>{useCustomButton.text}</button> }
              <ComboboxPopover portal={false}>
                <ComboboxList >
                  {status === "OK" &&
                    data.map(({ id, description }) => (
                      <ComboboxOption key={rngPassword()} value={description} />
                    ))}
                </ComboboxList>
              </ComboboxPopover>
            </Combobox>
          </div>
        );
    }






    // if(!isLoaded) return null


    return { isLoaded, Search, location, Map, mapRef, ZMap }


}