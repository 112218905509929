import { useIsMobile } from '../../../../hooks/useIsMobile'
import { useEffect, useState } from 'react'
import BMIDetails from './BMIDetails'
import BMICategory from './BMICategory'
import BMIImages from './BMIImages'
import BMIPrice from './BMIPrice'
import BMICustomization from './BMICustomization'
import BMIAvailability from './BMIAvailability'
import BMIGuest from './BMIGuest'
import BMIReview from './BMIReview'
// import BMISave from './BMISave'
import BMIShare from './BMIShare'

// import './BusinessManageItems.css'
import { useAuthContext } from '../../../../hooks/useAuthContext'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom';
import { textId } from '../../../../helper/helper'

import { useFirestore } from '../../../../hooks/useFirestore'
import { useDocument } from '../../../../hooks/useDocument'
import usePrompt from '../../../../hooks/usePrompt'

const blankItem = {
	details: {
		name: "",
		description: "",
		alert: "",
		tags: [],
		calories: {
			min: 0,
			max: 0
		},
	},
	category: [],
	images: [],
	price: [],
	availability: [],
	customization: [],
	ratings: []
}
// const blankItemGroupChat = {
// 	owner: "",
// 	businessid: "",
// 	assignedUsers: [],
// 	userinfo : {},
// 	itemid: "",
// 	messages: []
// }


export default function Wizard() {
    const { user } = useAuthContext()
    const { token } = useParams()
    const navigate = useNavigate();
    const [bid, setBid] = useState(null)
    const [iid, setIid] = useState(null)
    const { prompt, promptChoice } = usePrompt()
    

    const { setDocument, deleteDocument } = useFirestore('items')
    const { document, error } = useDocument('items', iid)

    const { isMobile } = useIsMobile()

    const [isSideBarShow, setisSideBarShow] = useState(false)
    const [activeTab, setActiveTab] = useState('details')

    const [ready, setReady] = useState(false)


    const handleTabClick =(t)=>{
        setActiveTab(t)
        setisSideBarShow(false)
    }
    const showSideBar =()=>{
        setTimeout(() => {
            setisSideBarShow(true)
        }, 200);
    }


    const handleDeleteItemClick =()=>{
        promptChoice({title: "Permanently Delete the item?"})
        .then(()=>{
            deleteDocument(iid)
            navigate(`/business/${bid}`)
        })
        .catch(()=>{
            console.log('user cancelled')
        })
    }
    

    useEffect(() => {
        try{
            const atob = str => Buffer.from(str, 'base64').toString('binary');
            const encodedToken = unescape(atob(token));
            const decodedToken = JSON.parse(encodedToken);
            setBid(decodedToken.bid)
            setIid(decodedToken.iid)
            // console.log(decodedToken)
            if(token && user && decodedToken){
                // console.log('Data Loaded.')
                if(decodedToken.token !== user.uid){
                    navigate(encodeURIComponent('/?error=ITEM_INVALID_USER'))
                }
                // encodeURIComponent()

                if(textId(decodedToken.iid)){
                    navigate('/?error=ITEM_INVALID_ID')
                    // console.log('string error');
                }
                // console.log('decodedToken.iid.length', decodedToken.iid.length);
                
                if(decodedToken.iid.length < 8){
                    navigate('/?error=ITEM_LESS_ID_LENGTH')
                    // console.log('lesser length error')
                }
                if(decodedToken.iid.length > 15){
                    navigate('/?error=ITEM_GREAT_ID_LENGTH')
                    // console.log('higher length error');
                    
                }
                if(decodedToken.iid.length <= 15 && decodedToken.iid.length >= 8){
                    // setDocument(businessid, blankBusiness)
                    // console.log('set document');
                    const newItem = {...blankItem, businessid: decodedToken.bid, itemid: decodedToken.iid}
                    // const newItemGroupChat = {...blankItemGroupChat, owner: user.uid, itemid: decodedToken.iid, businessid: decodedToken.bid, assignedUsers: [user.uid], userinfo: { [user.uid]: {photoURL: user.photoURL, displayName: user.displayName} }}

                    if(error === "DOCUMENT_NOT_EXIST"){
                        // console.log('created Document', newItemGroupChat);
                        setDocument(decodedToken.iid, newItem)
                        // setGroupChatDocument(decodedToken.iid, newItemGroupChat)
                        setReady(true)
                    }else{
                        // console.log('Document Exists')
                        setReady(true)
                    }
                    
                }
            }
        }catch (err) {
            navigate('/?error=ITEM_INVALID_TOKEN')
        }
        // eslint-disable-next-line
    }, [token, user, error]);


    const nav = <div className={isMobile ? "nav flex-col-center-start" : "nav flex-row-center-even"}>
        <img className={activeTab === "details" ? "active" : ""} onClick={()=> handleTabClick('details')} src="/icons/info_white_48dp.svg" alt="" />
        <img className={activeTab === "category" ? "active" : ""} onClick={()=> handleTabClick('category')} src="/icons/menu_book_white_48dp.svg" alt="" />
        <img className={activeTab === "images" ? "active" : ""} onClick={()=> handleTabClick('images')} src="/icons/image_white_48dp.svg" alt="" />
        <img className={activeTab === "price" ? "active" : ""} onClick={()=> handleTabClick('price')} src="/icons/attach_money_white_48dp.svg" alt="" />
        <img className={activeTab === "customization" ? "active" : ""} onClick={()=> handleTabClick('customization')} src="/icons/dashboard_customize_white_48dp.svg" alt="" />
        <img className={activeTab === "availability" ? "active" : ""} onClick={()=> handleTabClick('availability')} src="/icons/fact_check_white_48dp.svg" alt="" />
        <img className={activeTab === "guest" ? "active" : ""} onClick={()=> handleTabClick('guest')} src="/icons/visibility_white_48dp.svg" alt="" />
        <img className={activeTab === "review" ? "active" : ""} onClick={()=> handleTabClick('review')} src="/icons/reviews_white_48dp.svg" alt="" />
        <img className={activeTab === "share" ? "active" : ""} onClick={()=> handleTabClick('share')} src="/icons/share_white_48dp.svg" alt="" />
        <img className={activeTab === "delete" ? "active" : ""} onClick={handleDeleteItemClick} src="/icons/delete_white_48dp.svg" alt="" />
        <img className={activeTab === "save" ? "active" : ""} onClick={()=> navigate(`/business/${bid}`)} src="/icons/clear_white_48dp.svg" alt="" />
        {isMobile && <img onClick={()=> setisSideBarShow(false)} src="/icons/menu_white_48dp.svg" alt="" />}
    </div>

    return (
        
        <div className="wizard-container flex-col-center-start">
            {prompt}
            {ready && document && !error && error !== "FAILED_DOCUMENT" && 
                <div className="wizard-wand">
                    <div className="content">
                        {activeTab === 'details' ? <BMIDetails businessid={bid} itemid={iid} /> : null}
                        {activeTab === 'category' ? <BMICategory businessid={bid} itemid={iid} /> : null}
                        {activeTab === 'images' ? <BMIImages businessid={bid} itemid={iid} /> : null}
                        {activeTab === 'price' ? <BMIPrice businessid={bid} itemid={iid} /> : null}
                        {activeTab === 'customization' ? <BMICustomization businessid={bid} itemid={iid} /> : null}
                        {activeTab === 'availability' ? <BMIAvailability businessid={bid} itemid={iid} /> : null}
                        {activeTab === 'guest' ? <BMIGuest businessid={bid} itemid={iid} /> : null}
                        {activeTab === 'review' ? <BMIReview businessid={bid} itemid={iid} /> : null}
                        {/* {activeTab === 'save' ? <BMISave businessid={bid} itemid={iid} /> : null} */}
                        {activeTab === 'share' ? <BMIShare businessid={bid} itemid={iid} /> : null}
                    </div>
                    { !isMobile &&
                        nav
                    } 
                    { isMobile && isSideBarShow &&
                        nav
                    } 
                    {!isSideBarShow && isMobile && <div onClick={showSideBar} onTouchStart={showSideBar} className="sidebarOpener flex-row-center-center"><div className="h"></div></div> }
                    
                </div>
                
            }
        </div>
       
    )
}
