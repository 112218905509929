import { useRef, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useCollection } from "../../hooks/useCollection"
import StoreItemsList from "./StoreItemsList"


export default function StoreItems({ business }) {

    const navigate = useNavigate()
    const { documents: items } = useCollection('items', [
        ['businessid', '==', business.id]
    ])

    const [currentCategory, setCurrentCategory] = useState('')

    const [isCatPopupOpen, setIsCatPopupOpen] = useState(false)

    const storeCatNavRef = useRef()
    

    useEffect(() => {
        if(business){
            if(business.categories.length <= 0){
                navigate('/?error=STORE_NO_CATEGORIES')
            }
            setCurrentCategory(business.categories.length > 0 ? business.categories[0].id : {id: 'null', name: 'Empty'})
        }
        // eslint-disable-next-line
    }, [business])

    useEffect(() => {
        console.log(currentCategory);
        const el = document.getElementById(`store-category-ref-${currentCategory}`)
        if(el){
            el.scrollIntoView({behavior: "smooth", block: "end", inline: "end"});
        }
    }, [currentCategory])

    useEffect(() => {
        // console.log('storeCatNavRef.current', storeCatNavRef.current);
        function myFunction() {
            if (window.pageYOffset >= sticky) {
                // console.log('add sticky');
                storeCatNavRef.current && storeCatNavRef.current.classList.add("sticky")
            } else {
                // console.log('remove sticky');
                storeCatNavRef.current && storeCatNavRef.current.classList.remove("sticky");
            }
        }

        if(storeCatNavRef.current){
            window.onscroll = function() {myFunction()};
            var sticky = storeCatNavRef.current.offsetTop;
        }

        return ()=> window.removeEventListener('scroll', myFunction);

    }, [storeCatNavRef])


    return (<>
        <div ref={storeCatNavRef} className="store-category-nav flex-row-center-start">
            <img onClick={()=> setIsCatPopupOpen(!isCatPopupOpen)} src="/icons/menu_maincolor_48dp.svg" alt="" />
            <div className="cat-container flex-row-center-start">
                {
                    business.categories.map(category => {
                        return <span key={category.id} onClick={()=> setCurrentCategory(category.id)} className={currentCategory === category.id ? "active" : ""} >{category.name}</span>
                    })   
                }
            </div>
            {isCatPopupOpen && 
                <div className="cat-popup flex-col-start-start">
                    {
                        business.categories.map(category => {
                            return <div key={category.id} onClick={()=> setCurrentCategory(category.id) } className={currentCategory === category.id ? "widget active" : "widget"}>
                                <span>{category.name}</span>
                                <p>12</p>
                            </div>
                        })   
                    }
                </div>
            }
        </div>
        <div className="store-item-container flex-col-center-start">

            {items && 
                business.categories.map((category, index) => {
                    return <StoreItemsList 
                        key={category.id} 
                        category={category} 
                        items={items} 
                        business={business}
                    />
                })
            }

        </div>
    </>)
}
