import { useEffect, useRef, useState } from 'react'
import './Account.css'

import { firebaseAuth, projectAuth, projectStorage } from '../../firebase/config'
import { useAuthContext } from '../../hooks/useAuthContext'
import { getMaxFileSize, testUsername } from '../../helper/helper'
import { useFirestore } from '../../hooks/useFirestore'
import { useDocument } from '../../hooks/useDocument'
// import { getGeocode } from 'use-places-autocomplete'
import { useToast } from '../../hooks/useToast'

export default function Account() {

    const { toast, showToast } = useToast()
    const { user, dispatch } = useAuthContext()

    const { updateDocument, testUsernameExists } = useFirestore('userinfo')
    const { document } = useDocument('userinfo', user.uid)

    const [displayName, setDisplayName] = useState("")
    const [phone, setPhone] = useState("")
    const [address, setAddress] = useState("")
    const [email, setEmail] = useState("")
    const [username, setUsername] = useState("")


    const [passwordOld, setPasswordOld] = useState("")
    const [passwordNew, setPasswordNew] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [passwordError, setPasswordError] = useState(null)

    const [profilePicture, setProfilePicture] = useState('/favicon.ico')
    const [thumbnail, setThumbnail] = useState()
    const [thumbnailError, setThumbnailError] = useState(null)

    
    const [emailNew, setEmailNew] = useState("")
    const [emailPassword, setEmailPassword] = useState("")
    const [emailError, setEmailError] = useState(null)

    const fileRef = useRef()


    
    useEffect(() => {
        if(document){
            if(document.address){
                setAddress(document.address)
                setUsername(document.username)
                setPhone(document.phone)
            }
        }
    }, [document])


    useEffect(() => {
        if(user){
            setDisplayName(user.displayName ? user.displayName : "")
            setEmail(user.email ? user.email : "")
            setPhone(user.phoneNumber ? user.phoneNumber : "")

            setProfilePicture(user.photoURL ? user.photoURL : '/favicon.ico')
        }
    }, [user])



    const setupRecaptcha =()=>{
        window.recaptchaVerifier = new firebaseAuth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                onSignInSubmit();
            }
        });
    }
    const onSignInSubmit =(e)=>{
        e.preventDefault()
        setupRecaptcha()
        // const phoneNumber = '+16471112345';
        // const appVerifier = window.recaptchaVerifier;
        // phoneLogin(phoneNumber, appVerifier)
    }


    const handleUpdateProfileClick=(e)=>{
        e.preventDefault()
        // console.log({
        //     displayName,
        //     phoneNumber: phone
        // })
        if(username !== document.username){
            // console.log('username changed')
            if(testUsername(username) && username.length <= 15 && username.length >= 3){

                // console.log('username valid', username)
                testUsernameExists(username).then((data)=>{
                    updateDocument(user.uid, {address, phone})
                    // console.log('username already exists');
                    showToast({message: 'username already exists'})
                    setUsername(document.username)
                })
                .catch(() => {
                    // console.log('username dont exists');
                    updateDocument(user.uid, {address, phone, username})
                    showToast({message: 'saved'})
                })
    
            }else{
                // console.log('username invalid')
                showToast({message: 'invalid username ( 3 - 15 characters, letters and numbers only) '})
                setUsername(document.username)
            }
        }else{
            // console.log('username not changed');
            updateDocument(user.uid, {address, phone})
            showToast({message: 'saved'})
        }
        

        if(displayName !== user.displayName){
            console.log('displayname changed.. updating')
            user.updateProfile({
                displayName
            })
        }
        // updateDocument(user.uid, {address, phone, username})
        // user.linkWithPhoneNumber()
    }

    const handleChangePasswordSubmit =(e)=>{
        e.preventDefault()
        

        const user = projectAuth.currentUser;
        const credential = firebaseAuth.EmailAuthProvider.credential(
            projectAuth.currentUser.email,
            passwordOld
        )
        user.reauthenticateWithCredential(credential).then(function() {
            console.log('this user has given correct old password')
            if(passwordNew === passwordConfirm){
                user.updatePassword(passwordNew)
                .then(()=>{
                    console.log('hooray');
                    setPasswordOld("")
                    setPasswordNew("")
                    setPasswordConfirm("")
                    setPasswordError('Password was changed')
                    // user.reauthenticateWithCredential(credential)
                })
                .catch((error)=>{
                    if(error.code === 'auth/weak-password'){
                        setPasswordError('Password is too weak')
                    }
                    if(error.code === 'auth/requires-recent-login'){
                        setPasswordError('You need to be recently logged in in order to change password')
                    }
                })
            }
        }).catch(function(error) {
            setPasswordError('Old password entered is wrong.')
            console.log('this user has given wrong old password ', error.message )
        });

        
    }

    const handleChangeEmailSubmit =(e)=>{
        e.preventDefault()
            projectAuth
            .signInWithEmailAndPassword(user.email, emailPassword)
            .then(function(userCredential) {
                userCredential.user.updateEmail(emailNew)
                .then(()=>{
                    setEmailError('Email Changed')
                    setEmailNew("")
                    setEmailPassword("")
                    // user.reauthenticateWithCredential(userCredential)
                })

            })
            .catch(function(error){
                if(error.code === 'auth/invalid-email'){
                    setEmailError('Please provide a valid Email')
                }
                if(error.code === 'auth/email-already-in-use'){
                    setEmailError('Email is already in use')
                }
                if(error.code === 'auth/requires-recent-login'){
                    setEmailError('You need to be recently logged in in order to change password')
                }
            });
    }

    const handleProfileChange =(e)=>{
        setThumbnailError(null)
        const selected = e.target.files[0];
        // console.log('file', f);
        var reader = new FileReader();
        reader.readAsDataURL(selected);
        reader.onloadend = function (e) {
            setProfilePicture(reader.result);
        };

        if(!selected){
            setThumbnailError("Please select a file.")
            return
        }
        if(!selected.type.includes("image")){
            setThumbnailError("Selected File must be an image.")
            return
        }
        console.log(selected.size );
        if(selected.size > getMaxFileSize()){
            
            setThumbnailError("Your Image is too Powerful!")
            return
        }

        setThumbnail(selected)
    }


    const handleProfilePictureSaveClick = async (e)=> {
        console.log(thumbnail)
        const oldPhotoURL = user.photoURL
        const uploadPath = `profilepics/${user.uid}/${thumbnail.name}`
        if(!thumbnailError){
            projectStorage.ref(uploadPath).put(thumbnail)
            .then(img => {
                img.ref.getDownloadURL()
                .then(photoURL => {
                    user.updateProfile({
                        photoURL
                    })
                    if(oldPhotoURL){
                        projectStorage.refFromURL(oldPhotoURL).delete()
                    }
                    projectAuth.currentUser.reload()
                    .then(()=>{
                        dispatch({
                            type: 'LOGIN',
                            payload: projectAuth.currentUser
                        })
                    })
                })
            })
            .catch(error => {
                console.log(error)
            })
        }
        
    }


    

    return (
        <div className="flex-col-center-start ">
            {toast}
            <div className="account-container">
            
                <form onSubmit={handleUpdateProfileClick} className="account-form w100 flex-col-center-start">
                    <span className="header">Profile</span>
                    <div className="content flex-col-center-start">
                        
                        <div className="formwidget">
                            <span>Username</span>
                            <div className="username-con flex-row-center-start">
                                <span className="img">@</span>
                                <input type="text" onChange={e=> setUsername(e.target.value)} value={username}/>
                            </div>
                        </div>
                        <div className="formwidget">
                            <span>Display Name</span>
                            <input type="text" onChange={e=> setDisplayName(e.target.value)} value={displayName}/>
                        </div>
                        <div className="formwidget">
                            <span>Address</span>
                            <input type="text" onChange={e=> setAddress(e.target.value)} value={address} />
                        </div>
                        <div className="formwidget">
                            <span>Mobile Number</span>
                            <input type="tel" onChange={e=> setPhone(e.target.value)} value={phone} />
                        </div>
                        <button onClick={handleUpdateProfileClick} className="submit-btn">Save</button>
                    </div>
                </form>

                <form onSubmit={handleUpdateProfileClick} className="account-form w100 flex-col-center-start">
                    <span className="header">Profile Picture</span>
                    <div className="content flex-col-center-start">
                        <div className="formwidget">
                            {/* <span>Profile Picture</span> */}
                            <div onClick={()=> fileRef.current.click()} className="imgcon">
                                <img  src={profilePicture} alt="" />
                                <span className="flex-row-center-center">Change</span>
                            </div>
                            {thumbnailError && <p className="error">{thumbnailError}</p> }
                            <input onChange={handleProfileChange} ref={fileRef} type="file" style={{display: 'none'}} />
                        </div>
                        {/* <button className="submit-btn" onClick={()=> fileRef.current.click()}>Select Picture</button> */}
                        {!thumbnailError && thumbnail && <button onClick={handleProfilePictureSaveClick} className="submit-btn">Save</button>}
                    </div>
                </form>


                

                {/* {this.state.isEmailVerified ? null : <AccountEmailVerify />} */}
                {/* {!isPhoneVerified && <AccountPhoneVerify />} */}
                
                <form onSubmit={handleChangeEmailSubmit} className="account-form w100 flex-col-center-start">
                    {/* <span className="header">Change Email {user.emailVerified && "nice"}</span> */}
                    <span className="header">Change Email</span>
                    <div className="content flex-col-center-start">
                        <div className="formwidget">
                            <span>Old Email</span>
                            <input type="email" value={email} disabled />
                        </div>
                        <div className="formwidget">
                            <span>New Email</span>
                            <input type="email" value={emailNew} onChange={e=> setEmailNew(e.target.value)} required/>
                        </div>
                        <div className="formwidget">
                            <span>Enter your password</span>
                            <input type="password" value={emailPassword} onChange={e=> setEmailPassword(e.target.value)} required/>
                        </div>
                        {emailError && 
                            <span className="error">{emailError}</span>
                        }
                        <button className="submit-btn">Change Email</button>
                    </div>
                </form>

                <form onSubmit={handleChangePasswordSubmit} className="account-form w100 flex-col-center-start">
                    <span className="header">Change Password</span>
                    <div className="content flex-col-center-start">
                        <div className="formwidget">
                            <span>Old Password</span>
                            <input type="password" value={passwordOld} onChange={e=> setPasswordOld(e.target.value)} required/>
                        </div>
                        <div className="formwidget">
                            <span>New Password</span>
                            <input type="password" value={passwordNew} onChange={e=> setPasswordNew(e.target.value)} required/>
                        </div>
                        <div className="formwidget">
                            <span>Confirm New Password</span>
                            <input type="password" value={passwordConfirm} onChange={e=> setPasswordConfirm(e.target.value)} required/>
                        </div>
                        {passwordError && 
                            <span className="error">{passwordError}</span>
                        }
                        <button className="submit-btn">Change Password</button>
                    </div>
                </form>

                

                <div className="account-form w100 flex-col-center-start">
                    <span className="header">Deactivate Account</span>
                    <div className="content flex-col-center-start">
                        <div className="formwidget">
                            <span>Enter Password</span>
                            <input type="password" />
                        </div>
                        <span className="mintitle">By Clicking on <b>Confirm</b>, you agree to archive your account and all of its information from us.</span>
                        <button className="submit-btn">Confirm</button>
                    </div>
                </div>

                
                

                <br />

            </div>
        
        </div>
    )
}
