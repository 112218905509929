


import BusinessListWidget from './BusinessListWidget';

export default function BusinessList({ business }) {

    

    return (
        <>
            {business.map(business => 
                <BusinessListWidget key={business.id} business={business} />
            )}
        </>
    )
}
