
import { useIsMobile } from '../../hooks/useIsMobile'
import { FaEllipsisH, FaList, FaSmile, FaPaperPlane, FaTrashAlt } from "react-icons/fa";
import Picker from 'emoji-picker-react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { rngPassword } from '../../helper/helper';
import { useAuthContext } from '../../hooks/useAuthContext';
import { timestamp } from '../../firebase/config';
import { useChikkas } from '../../model/useChikkas';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useFirestore } from '../../hooks/useFirestore';


export default function ChikkaMessage({ dispatch, state }) {
    const { isMobile } = useIsMobile()
    const { user } = useAuthContext()
    const { chikkas, addItemMessage} = useChikkas()
    const { updateDocument } = useFirestore('itemchat')
    // const [chosenEmoji, setChosenEmoji] = useState(null);
    const [showEmojiPicker, setshowEmojiPicker] = useState(false);
    const [messageContent, setMessageContent] = useState("")
    const [selectedChikka, setSelectedChikka] = useState(null);

    const [messageIcon, setMessageIcon] = useState(null);
    const [messageTitle, setMessageTitle] = useState(null);

    
    const messagesEndRef = useRef()
    
    // console.log(selectedChikka);

    const messageContentRef = useRef()

    

    useEffect(() => {
        if(state.currentMessage){
            // console.log(chikkas)
            if(chikkas){
                
                const sc = chikkas.filter(ck => ck.id === state.currentMessage)[0]
                if(sc){
                    // console.log(sc)
                    sc.messages.sort((a, b)=> {return new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate())})
                    setSelectedChikka(sc)
                    // console.log(sc);
                    if(sc.owner === user.uid){
                        setMessageIcon(sc.userinfo[sc.buyer].photoURL)
                        setMessageTitle(sc.userinfo[sc.buyer].displayName)
                    }else{
                        setMessageIcon(sc.userinfo[sc.businessid].photoURL)
                        setMessageTitle(sc.userinfo[sc.businessid].displayName)
                    }
                }else{
                    window.location.reload(false);
                }
            }
        }
    }, [state, chikkas]);



    const onEmojiClick = (event, emojiObject) => {
        // console.log('emojiObject', emojiObject);
        // setChosenEmoji(emojiObject);
        setshowEmojiPicker(false)
        setMessageContent(ps=> `${ps}${emojiObject.emoji}`)
        messageContentRef.current.focus()
    };

    function saveMessage(){
        const msgObj = {
			id: rngPassword(),
			sender: user.uid,
			message: messageContent,
			createdAt: timestamp.fromDate(new Date()),
			read: [user.uid]
		}
        if(selectedChikka.assignedUsers.length >= 2 && messageContent !== ''){
            console.log('proceed')
            addItemMessage(state.currentMessage, msgObj)
        }
        setMessageContent("")
    }


    const handleKeyPress =(e)=>{

        if(!isMobile){
            if(e.which === 13 && !e.shiftKey) {
                e.preventDefault();
                saveMessage()
            }
        }

    }

    const handleMessageContentSubmit =(e)=>{
        e.preventDefault()
        saveMessage()
    }

    const handleDeleteSingleMessageClick =(id)=>{
        // console.log(id)
        let newMsgObj = [...selectedChikka.messages]
        newMsgObj.forEach((msg, index) => {
            if(msg.id === id){
                newMsgObj.splice(index, 1)
            }
        })
        // console.log(newMsgObj)
        updateDocument(selectedChikka.id, {messages: newMsgObj})
    }

    return (
        <div className="chikka-message flex-col-center-start">
            <div className="header flex-row-center-between">
                <div className="profile flex-row-center-start">
                    <img src={messageIcon} alt="" />
                    <span className="title">{messageTitle}</span>
                </div>
                <div className="actions flex-row-center-end">
                    {isMobile ? <div className="sbg">
                        <FaList onClick={()=> dispatch({type: 'TOGGLE_LIST'})} />
                    </div> : null}
                    <div className="sbg">
                        <FaEllipsisH onClick={()=> dispatch({type: 'TOGGLE_OPTION'})} />
                    </div>
                </div>
                {/* <div className="verify flex-col-center-start">
                    <span className="title">KanChookan Changed the Delivery Price to $15 Confirm this change?</span>
                    <div className="action w100 flex-row-center-even">
                        <button>No</button>
                        <button>Yes</button>
                    </div>
                    <span className='subtitle more'>+2 more</span>
                </div> */}

            </div>
            <div className="message-container">
                
                {selectedChikka && selectedChikka.assignedUsers.length < 2 &&
                    <div key={`cancelledstuifff`} className="message-time ">
                        <span className="subtitle error">chat has been disabled</span>
                    </div>
                }

                {selectedChikka && selectedChikka.messages.map((msg, index) => {
                    const sender = msg.sender === user.uid ? 'owner' : 'user'
                    const img = msg.sender === user.uid ? null : msg.sender === selectedChikka.owner ? selectedChikka.userinfo[selectedChikka.businessid].photoURL : selectedChikka.userinfo[selectedChikka.buyer].photoURL
                    
                    {/* console.log(selectedChikka.messages) */}
                    let time
                    if(index === selectedChikka.messages.length - 1){
                        time = formatDistanceToNow(msg.createdAt.toDate(), {includeSeconds: true, addSuffix: true})
                    }
                

                    if(index < selectedChikka.messages.length - 1){
                        const bfore = new Date(selectedChikka.messages[index + 1].createdAt.toDate())
                        const now = new Date( selectedChikka.messages[index].createdAt.toDate() )
                        {/* console.log(bfore, now) */}
                        {/* console.log('z', formatDistanceToNow(now, {includeSeconds: true, addSuffix: true})) */}
                        {/* console.log(bfore.getHours(), now.getHours() )  */}

                        if(bfore.getHours() < now.getHours() || (bfore.getMinutes() > ( now.getMinutes() + 10 ) && bfore.getHours() === now.getHours()) ){
                            time = formatDistanceToNow(now, {includeSeconds: true, addSuffix: true})
                            {/* console.log('time', time) */}
                        }
                    }

                    
                    return (<Fragment key={msg.id}>
                        
                        <div ref={index === selectedChikka.messages.length - 2 ? messagesEndRef : null} key={msg.id} className={`message-widget ${sender}`}>
                            {img && <img src={img} alt="" />}
                            {sender === 'owner' && 
                                <div className="actions flex-row-center-center">
                                    <FaTrashAlt onClick={()=> handleDeleteSingleMessageClick(msg.id)} />
                                    
                                </div>
                            }
                            <span>{msg.message}</span>
                        </div>
                        {time && <div key={`time-${msg.id}`} className="message-time">
                            <span className="subtitle">{time}</span>
                        </div>
                        }
                        
                    </Fragment>)

                    
                })}

                {<div className="message-time">
                    <span className="subtitle">this is the start of your converstation</span>
                </div>}
                

                
            </div>
            
            <div className="message-sender flex-row-center-between">
                {showEmojiPicker && <Picker onEmojiClick={onEmojiClick} disableAutoFocus={true} groupVisibility={{ flags: false, symbols: false, }}  />}
                <div className='form flex-row-center-center' >
                    {/* <input 
                        type="text" 
                        ref={messageContentRef} 
                        value={messageContent} 
                        onChange={e=> setMessageContent(e.target.value)} 
                        // onKeyPress={handleKeyPress} 
                        className='sender'
                    /> */}
                    <textarea 
                        ref={messageContentRef} 
                        value={messageContent} 
                        onChange={e=> selectedChikka.assignedUsers.length >= 2 ? setMessageContent(e.target.value) : null} 
                        onKeyPress={handleKeyPress} 
                        className='sender'>
                    </textarea>
                
                    <FaPaperPlane onClick={handleMessageContentSubmit} />
                </div>
                <FaSmile onClick={()=> setshowEmojiPicker(!showEmojiPicker)} />
            </div>
        </div>
    )
}
