import { useEffect, useState } from "react"
import { projectFirestore } from "../firebase/config"


export const useDocument =(collection, id)=>{
    const [document, setDocument] = useState(null)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(true)

    // realtime data for the document
    useEffect(() => {
        let unsub = ()=>{}
        try {
            const ref = projectFirestore.collection(collection).doc(id)

            unsub = ref.onSnapshot(snapshot => {
                if(snapshot.data()){
                    setDocument({...snapshot.data(), id: snapshot.id})
                    setError(null)
                    setIsPending(false)
                }else{
                    setError('DOCUMENT_NOT_EXIST')
                    setIsPending(false)
                }
            }, (error)=>{
                console.log(error.message)
                setError('FAILED_DOCUMENT')
                setIsPending(false)
            })
        } catch (error) {
            setError('FAILED_DOCUMENT')
            setIsPending(false)
        }

        return () => unsub()

    }, [collection, id]);

    return { document, error, isPending }

}