import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

//components
// import Home from './pages/home/Home'
import Login from './pages/login/Login'
import Signup from './pages/signup/Signup'
import Navbar from './components/Navbar'
import Home from './pages/home/Home'
import { useAuthContext } from './hooks/useAuthContext'
import Contact from './pages/contact/Contact'
import Account from './pages/account/Account'
import Business from './pages/business/Business'
import BusinessManage from './pages/business/manage/BusinessManage'
import BusinessCreate from './pages/business/BusinessCreate'
import Wizard from './pages/business/manage/items/Wizard'
// import Test from './helper/Test'
import Nearby from './pages/nearby/Nearby'
import Store from './pages/store/Store'
import Item from './pages/store/Item'
import Chikkas from './pages/chikkas/Chikkas'




function App() {
  const { authIsReady, user } = useAuthContext()


  return (
    
    <div className="App">
    {authIsReady && 
      <div id="content">
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={ <Home /> } />
            <Route path="/store/:businessid" element={ <Store /> } >
              <Route path="/store/:businessid/:itemid" element={<Item /> } />
            </Route>
            <Route path="/nearby" element={<Nearby />} />
            <Route path="/contact" element={ <Contact />} />
            <Route path="/login" element={ user !== null ? <Navigate to="/" /> : <Login /> } />
            <Route path="/signup" element={ user !== null ? <Navigate to="/" /> : <Signup />  } />

            <Route path="/account" element={ user !== null ? <Account /> :  <Navigate to="/login" /> } />
            <Route path="/business" element={ user !== null ? <Business /> :  <Navigate to="/login" /> } />
            <Route path="/chikkas" element={ user !== null ? <Chikkas /> :  <Navigate to="/login" /> } />
            <Route path="/business/:businessid" element={ user !== null ? <BusinessManage /> :  <Navigate to="/login" /> } />
            <Route path="/businesscreate/:businessid" element={ user !== null ? <BusinessCreate /> :  <Navigate to="/login" /> } />
            <Route path="/itemwizard/:token/" element={ user !== null ? <Wizard /> :  <Navigate to="/login" /> } />
            
            <Route path="*" element={ <Navigate to="/" />  } />
          </Routes>
        </BrowserRouter>
      </div>
    }
    </div>
  );
}

export default App
