import { getProperItemImage } from "../../helper/helper";
import { Link } from "react-router-dom";

export default function StoreItemsList({ category, items, business }) {

    return <>
        <span className="title" id={`store-category-ref-${category.id}`}>{category.name}</span>
        <div className="store-cat-con">
            {items.map(item => {
                let ret
                if(item.category.includes(category.id)){
                    const priceList = item.price.sort((a,b) => a.amount - b.amount);
                    const img = getProperItemImage(item.images)

                    ret = <Link key={item.id} to={`/store/${business.id}/${item.id}`} className="store-item-widget flex-row-start-between">
                        <div className="details flex-col-start-start">
                            <span className="name">{item.details.name}</span>
                            <span className="description">{item.details.description}</span>
                            <span className="calories">Calories {item.details.calories.min} - {item.details.calories.max} </span>
                            {
                                !item.price.length ?
                                <span className="price">price not set up</span>
                                : item.price.length === 1 ? 
                                <span className="price">${priceList[0].amount}</span>
                                : <span className="price">${priceList[0].amount} - ${priceList[priceList.length - 1].amount}</span>
                            }
                        </div>
                        <img src={img} alt="" />
                        {item.details.alert.length > 0 && 
                            <img className="alert" title={item.details.alert} src="/icons/priority_high_white_48dp.svg" alt="" />
                        }
                    </Link>
                }
                return ret
            })}
        </div>
    </>
}
