import { WEBCATS } from "../helper/helper"
import Select from 'react-select'
import { useEffect, useState } from "react"
import { REACT_SELECT_STYLE } from "../helper/helper"


export const useWebCat =(defaultValue=[])=>{

    const uidata = [
        {
            label: "TYPE",
            options: WEBCATS.TYPE
        },{
            label: "TEXTURE",
            options: WEBCATS.TEXTURE
        },{
            label: "STYLE",
            options: WEBCATS.STYLE
        },{
            label: "SPECIAL",
            options: WEBCATS.SPECIAL
        }
    ]

    // console.log(defaultValue)

    const [value, setValue] = useState(null)

    useEffect(() => {
        if(defaultValue){
            setValue(defaultValue)
        }
    }, [defaultValue])


    // console.log(value)

    const handleChange =(option)=>{
        // console.log(option)
        if(option.length > 4){
            // console.log('test');
            return
        }else{
            setValue(option)
        }
    }

    // styles link // https://react-select.com/styles#provided-styles-and-state
    const webcatui = <Select 
        options={uidata}
        onChange={option => handleChange(option)}
        placeholder="Select up to 4 categories"
        isMulti
        value={value}
        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null, ClearIndicator:() => null }}
        styles={REACT_SELECT_STYLE}
        menuPosition={'fixed'} 
    />


    return { webcatui, value }

}

