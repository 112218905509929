import { useEffect, useState } from "react"
import CustomForm from "../../../../helper/CustomForm"
import Select from "react-select"
import { getDateNow, REACT_SELECT_STYLE, rngPassword } from "../../../../helper/helper"
import BMIAvailabilityWidget from "./BMIAvailabilityWidget"
import { timestamp } from "../../../../firebase/config"
import { useFirestore } from "../../../../hooks/useFirestore"
import { useDocument } from "../../../../hooks/useDocument"
import usePrompt from "../../../../hooks/usePrompt"

const recurringDaysObj = [
    {value: 'sun', label: 'Sunday'},
    {value: 'mon', label: 'Monday'},
    {value: 'tue', label: 'Tuesday'},
    {value: 'wed', label: 'Wednesday'},
    {value: 'thu', label: 'Thursday'},
    {value: 'fri', label: 'Friday'},
    {value: 'sat', label: 'Saturday'}
]

export default function BMIAvailability({ businessid, itemid }) {
    const { updateDocument } = useFirestore('items')
    const { document } = useDocument('items', itemid)
    const { promptChoice, prompt } = usePrompt()

    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [type, setType] = useState("na")
    const [specificDate, setSpecificDate] = useState(`${getDateNow().year}-${getDateNow().month}-${getDateNow().day}`)
    const [recurringDay, setRecurringDay] = useState([])
    const [isAllDay, setIsAllDay] = useState(false)
    const [startTime, setStartTime] = useState("08:00")
    const [endTime, setEndTime] = useState("17:00")
    const [createError, setCreateError] = useState(null)


    

    const handlePopupSubmit =(e)=>{
        e.preventDefault()
       if(type !== "na"){
            let availabilityObj = []
            if(type === "recurring"){
                recurringDay.forEach(day => {
                    availabilityObj.push({
                        id: rngPassword(),
                        type,
                        day: day.value,
                        isAllDay,
                        start: startTime,
                        end: endTime
                    })
                })
            }else{
                const d = new Date(specificDate)
                d.setDate(d.getDate() + 1)
                availabilityObj.push({
                    id: rngPassword(),
                    type,
                    day: timestamp.fromDate( d ),
                    isAllDay,
                    start: startTime,
                    end: endTime
                })
            }

            updateDocument(itemid, {availability: [...document.availability, ...availabilityObj]})
            .then(()=>{
                setType("na")
                setSpecificDate(`${getDateNow().year}-${getDateNow().month}-${getDateNow().day}`)
                setRecurringDay([])
                setIsAllDay(false)
                setStartTime("08:00")
                setEndTime("17:00")
                setIsPopupOpen(false)
            })
            .catch(err=>{
                console.log(err)
            })
       }else{
           setCreateError('Please Select Type First.')
       }

    }


    useEffect(() => {
        if(isAllDay){
            setStartTime('00:00')
            setEndTime('23:59')
        }else{
            setStartTime("08:00")
            setEndTime("17:00")
        }
    }, [isAllDay])

    useEffect(() => {
        const x = setTimeout(() => {
            if(createError){
                setCreateError(null)
            }
        }, 2000); 

        return () => clearTimeout(x)
    }, [createError])

    useEffect(() => {
        if(isPopupOpen){
            setType('na')
        }
    }, [isPopupOpen])

    const getUpdateData =(data)=>{
        console.log(data);
        let gate = false
        const newObj = document.availability.map(av => {
            console.log(av);
            if(av.id === data.id){
                //we have a match
                // console.log(av, data);
                if(av.isAllDay !== data.isAllDay){
                    gate = true
                    console.log('trigger isAllDay');
                }
                if(av.start !== data.start){
                    gate = true
                    console.log('trigger start');
                }
                if(av.end !== data.end){
                    gate = true
                    console.log('trigger end');
                }

                return {...av, isAllDay: data.isAllDay, start: data.start, end: data.end}
            }else{
                return av
            }
        })

        if(gate){
            updateDocument(itemid, {availability: [...newObj]})
            console.log('updated');
            
        }
        // console.log(gate);
        // console.log(newObj)
    }

    const getDeleteData =(id)=>{
        console.log(id)
        promptChoice({title: "Confirm Schedule Delete?"})
        .then(()=>{
            const newObj = []
            document.availability.forEach(av => {
                console.log(av);
                if(av.id !== id){
                    newObj.push(av)
                }
            })
            updateDocument(itemid, {availability: [...newObj]})
        })
        .catch(err=>{
            console.log(err);
        })
        
    }
 
    // useEffect(() => {
    //     if(document){
    //         let newObj = []
    //         document.availability.forEach(av => {
    //             if(av.type === 'recurring'){
    //                 newObj.push({
    //                     value: av.day,
    //                     label: getDayText(av.day),
    //                     isDisabled: true
    //                 })
    //             }
    //         })
    //         setRecurringDay(newObj)
    //     }
    // }, [document, isPopupOpen, type])

    return (
        <div className="wizard-widget availability flex-col-center-start">
            {prompt}
            <h2>Availability</h2>
            <br />
            <div className="availability-create w100">
                <button onClick={()=> setIsPopupOpen(!isPopupOpen)} className="submit">{isPopupOpen ? "Close" : "Create Schecdule"}</button>
                {isPopupOpen && 
                    <CustomForm 
                        handleSubmit={handlePopupSubmit}
                        className="availability-create-popup"
                        title="Availability Schedule"
                    >
                        <div className="field">
                            <span>Select Type</span>
                            <select value={type} onChange={e=> setType(e.target.value)} >
                                <option value="na" disabled>Select one</option>
                                <option value="recurring">Recurring Days</option>
                                <option value="specific">Specific Days</option>
                            </select>
                        </div>
                        {type === 'specific' && 
                            <div className="field">
                                <span>Select Specific Date</span>
                                <input value={specificDate} onChange={e=> setSpecificDate(e.target.value)} type="date" />
                            </div>
                        }
                        {type === 'recurring' && 
                            <div className="field">
                                <span>Select Recurring Days</span>
                                <Select 
                                    options={recurringDaysObj}
                                    onChange={option => setRecurringDay(option)}
                                    placeholder="Select up to 4 categories"
                                    isMulti
                                    value={recurringDay}
                                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null, ClearIndicator:() => null }}
                                    styles={REACT_SELECT_STYLE}
                                    menuPosition={'fixed'} 
                                />
                            </div>
                        }
                        {type !== "na" && <>
                            <div className="field">
                                <span> 
                                    <label> <input type="checkbox" checked={isAllDay} onChange={()=> setIsAllDay(!isAllDay)} /> Check if Item is served all day</label>
                                </span>
                            </div>
                            <div className="asd flex-row-center-between">
                                <div className="field">
                                    <span>Start Time</span>
                                    <input type="time" value={startTime} onChange={e=> setStartTime(e.target.value)} disabled={isAllDay} />
                                </div>
                                <div className="field">
                                    <span>End Time</span>
                                    <input type="time" value={endTime} onChange={e=> setEndTime(e.target.value)} disabled={isAllDay} />
                                </div>
                            </div>
                            {createError && <span className="subtitle error">{createError}</span> }
                            <button onClick={handlePopupSubmit} >Save</button>
                        </>}
                    </CustomForm>
                }
            </div>
            {!isPopupOpen && 
                <div className="availability-widget-con w100 flex-col-start-start">
                    
                    {document && document.availability.map(av => <BMIAvailabilityWidget key={av.id} data={av} update={getUpdateData} dlete={getDeleteData} /> )}

                </div>
            }
        </div>
    )
}
