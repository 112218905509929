
import { useRef, useState } from "react";
import { FaCaretDown, FaCaretLeft, FaTrash } from "react-icons/fa"


export default function StoreBasket({ data, deleteItem, deleteCustomization }) {

    // console.log('data', data)
    const [isOpen, setIsOpen] = useState(true);
    const [headerStyle, setHeaderStyle] = useState({borderRadius: '10px 10px 0px 0px'})
    const [caret, setCaret] = useState(<FaCaretDown  />);
    
    const handleOpenClick =()=>{
        if(isOpen){
            setHeaderStyle({borderRadius: '10px'})
            setCaret( <FaCaretLeft /> )
        }else{
            setHeaderStyle({borderRadius: '10px 10px 0px 0px'})
            setCaret( <FaCaretDown /> )
        }

        setIsOpen(!isOpen)
    }

    const handlePriceDelete =(id, chikkaID)=>{
        // console.log('delete item', id, chikkaID);
        deleteItem(id, chikkaID)
        
    }
    const handleCustDelete =(id, custid, chikkaID)=>{
        // console.log('delete customization', id);
        deleteCustomization(id, custid, chikkaID)
    }

    return (
        <div className="widget flex-col-center-start">
            <div style={headerStyle} onClick={handleOpenClick} className="header flex-row-center-between">
                <span>{data.name}</span>
                {caret}
            </div>
            {isOpen && <>

                {data && data.options.map(d => {
                    let total = parseFloat(d.price.amount)
                    d.customization.forEach(c => {
                        total += parseFloat(c.amount)
                    })
                    return (
                        <div key={d.id} className="option flex-col-center-start">
                            <div className="opwid flex-row-center-between">
                                <span>{d.price.size}</span>
                                <div onClick={()=> handlePriceDelete(d.id, data.chikkaID)} className="action flex-row-center-between">
                                    <p className="subtitle">${d.price.amount}</p>
                                    <FaTrash className="delete" />
                                </div>
                            </div>
                            {d.customization.map(cust => (
                                <div key={cust.id} className="opwid flex-row-center-between">
                                    <span>{cust.name}</span>
                                    <div onClick={()=> handleCustDelete(d.id, cust.id, data.chikkaID)} className="action flex-row-center-between">
                                        <p className="subtitle">{cust.amount > 0 ? `$${cust.amount}` : "FREE"}</p>
                                        <FaTrash className="delete" />
                                    </div>
                                </div>
                            ))}
                            <div className="opwid subtotal flex-row-center-end">
                                <p className="subtitle">${total.toFixed(2)}</p>
                            </div>
                        </div>
                    )
                })}
                
                

                
            </> }
            

        </div>
    )
}
