import { useEffect, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';

// import LeftNav from 'react-image-gallery/src/controls/LeftNav';

// import { useIsMobile } from '../../../../hooks/useIsMobile';
import { useDocument } from '../../../../hooks/useDocument';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useFirestore } from '../../../../hooks/useFirestore';
import { getDefaultItemImage, getMaxFileSize } from '../../../../helper/helper';
import usePrompt from '../../../../hooks/usePrompt'

export default function BMIImages({ businessid, itemid }) {
    // const { isMobile } = useIsMobile()
    const { user } = useAuthContext()
    const { document } = useDocument('userinfo', user.uid)
    const { document: itemDoc } = useDocument('items', itemid)
    const { updateDocument, addFile, deleteFile, response } = useFirestore('items')
    // const { addFile, deleteFile, response: fileResponse } = useFireStorage()
    const { prompt, promptChoice } = usePrompt()
    const [profileIndex, setProfileIndex] = useState(null)

    const fileRef = useRef()


    const [images, setImages] = useState([])
    // console.log(images)
    

    // const [galleryStartIndex, setGalleryStartIndex] = useState(0)
    const [currentGalleryIndex, setCurrentGalleryIndex] = useState(0)
    const [imageError, setImageError] = useState(null)

    // console.log(profileIndex, currentGalleryIndex)

    const handleImageDeleteClick =()=>{
        const filetodelete = images.length === 1 ? images[0] : images[currentGalleryIndex]
        // console.log('index on: ', currentGalleryIndex, 'delete: ', filetodelete)

        promptChoice({title: "Confirm Delete Image?"})
        .then(()=>{
            deleteFile(filetodelete.original)
            const newItemObj = []
            images.forEach((img, index) => {
                if(currentGalleryIndex !== index){
                    // newItemObj.push(img)
                    if(profileIndex === index){
                        newItemObj.push({
                            url: img.original,
                            isProfile: true
                        })
                    }else{
                        newItemObj.push({
                            url: img.original,
                            isProfile: false
                        })
                    }
                }else{
                    return null
                }
            });
            // console.log(newItemObj)
            updateDocument(itemid, {images : newItemObj})
            setCurrentGalleryIndex(0)
        })
        .catch(()=>{
            setImageError("User Cancelled")
        })
    }
    const handleImageStarClick =()=>{
        // console.log(currentGalleryIndex)
        promptChoice({title: "Make this the Items Profile Image?"})
        .then(()=>{
            const newItemObj = images.map((img, index) => {
                if(index === currentGalleryIndex){
                    return {
                        url: img.original,
                        isProfile: true
                    }
                }else{
                    return {
                        url: img.original,
                        isProfile: false
                    }   
                }
            })
            // console.log(newItemObj)
            updateDocument(itemid, {images : newItemObj})
            setCurrentGalleryIndex(0)
        })
        .catch(()=>{
            setImageError("User Cancelled")
        })
    }

    

    const handleImageFileChange =(e)=>{
        setImageError(null)
        const selected = e.target.files[0];
   
        if(!selected){
            setImageError("Please select a file.")
            return
        }
        if(!selected.type.includes("image")){
            setImageError("Selected File must be an image.")
            return
        }
        console.log(selected.size );
        if(selected.size > getMaxFileSize()){
            
            setImageError("Your Image is too Powerful!")
            return
        }

        if(user && document){
            // console.log('inside');
            
            let gate = 0
            if(document.premium.status === "active"){
                gate = 10
            }else{
                gate = 5
            }
            
            if(images.length >= gate){
                setImageError(gate > 5 ? 'Maximum number of images reached' : 'Maximum number of images reached, consider upgrading to Premium!')
                return
            }else{
                promptChoice({title: "Confirm Image Upload?"})
                .then(()=>{
                    const uploadPath = `items/${user.uid}/${selected.name}`
                    addFile(uploadPath, selected)
                    .then(url => {
                        // setImages(ps => [...ps, { original: url }])
                        const newItemObj = [...itemDoc.images]
                        newItemObj.push({
                            isProfile: false,
                            url: url
                        })
                        updateDocument(itemid, {images : newItemObj})
                        setCurrentGalleryIndex(0)
                    })
                    .catch(error => {
                        console.log(error)
                    })
                })
                .catch(()=>{
                    setImageError("User cancelled upload")
                })

            }
        }
    }

    useEffect(() => {
        setImages([])
        setProfileIndex(null)
        if(itemDoc){
            itemDoc.images.sort(function(x, y) {
                // true values first
                return (x.isProfile === y.isProfile) ? 0 : x.isProfile ? -1 : 1;
                // false values first
                // return (x === y)? 0 : x? 1 : -1;
            }).forEach((img, index) => {
                if(img.isProfile){
                    setProfileIndex(index)
                    // setGalleryStartIndex(index)
                    // setGalleryStartIndex(0)
                }
                setImages(ps => [...ps, {original: img.url, index}])
            })
        }
    }, [itemDoc])

    useEffect(() => {
        const x = setTimeout(() => {
            if(imageError){
                setImageError(null)
            }
        }, 2000); 

        return () => clearTimeout(x)
    }, [imageError])


    // console.log(profileIndex , currentGalleryIndex, profileIndex === currentGalleryIndex)
    const _renderCustomControls =()=> {
        return <>
            <img onClick={handleImageDeleteClick} className="imgal-delete" src="/icons/delete_white_48dp.svg" alt="" />
            <img className={profileIndex === currentGalleryIndex ? "imgal-star active" : "imgal-star" } onClick={handleImageStarClick} src="/icons/star_white_48dp.svg" alt="" />
        </>
    }
    

    return (
        <div className="wizard-widget images flex-col-center-start">
            {prompt}
            <h2>Images</h2>
            <br />
            <div className="wizard-imgal">
                {/* { !isMobile && images && !response.isPending && 
                    <ImageGallery 
                        items={images && images.length ? images : [{original: '/logo.png'}] } 
                        showFullscreenButton={false}
                        useBrowserFullscreen={false}
                        showPlayButton={false}
                        showBullets={images.length > 0}
                        onSlide={(e)=> setCurrentGalleryIndex(e)}
                        onErrorImageURL={'/logo.png'}
                        renderCustomControls={images.length > 0 && _renderCustomControls}
                    />
                }
                { isMobile && images && !response.isPending && 
                    <ImageGallery 
                        items={images && images.length ? images : [{original: '/logo.png'}]} 
                        showFullscreenButton={false}
                        useBrowserFullscreen={false}
                        showPlayButton={false}
                        showBullets={images && images.length > 0}
                        renderRightNav={() => null }
                        onSlide={(e)=> setCurrentGalleryIndex(e)}
                        onErrorImageURL={'/logo.png'}
                        renderCustomControls={images.length > 0 && _renderCustomControls}
                    />
                } */}
                <ImageGallery 
                    items={images && images.length ? images : [{original: '/logo.png'}]} 
                    showFullscreenButton={false}
                    useBrowserFullscreen={false}
                    showPlayButton={false}
                    showBullets={images && images.length > 0}
                    renderPlayPauseButton={() => null}
                    // onImageLoad={()=> console.log('RESTART')}
                    // renderLeftNav={isMobile ? () => null  : (onClick, disabled) => <button style={{color: 'red'}}>HELLO</button> }
                    // renderRightNav={isMobile ? () => null  : (onClick, disabled)=> <img src="/icons/arrow_right_white_48dp.svg" onClick={onClick} disabled={disabled} /> }
                    onSlide={(e)=> setCurrentGalleryIndex(e)}
                    onErrorImageURL={getDefaultItemImage()}
                    renderCustomControls={images.length > 0 && !response.isPending && _renderCustomControls}
                />
                {/* {images && images.length > 0 && response.isPending && 
                    <div className="loader">
                        Loading...
                    </div>
                } */}
            </div>
            {imageError && <p className="subtitle error">{imageError}</p>}
            {images && images.length === 0 && <p className="subtitle error">You have not uploaded any image for the item.</p> }
            {profileIndex === null && <p className="subtitle error">You have not selected the items Profile Image. Please use the star icon to select one now.</p>}
            <p className="subtitle">Users may only upload a maximum of 5 image per item. However, Premium users will be able to upload a total of 10 images for each item.</p>
            <br />
            <input onChange={handleImageFileChange} ref={fileRef} type="file" style={{display: 'none'}} />
            
            {response.isPending &&  <button onClick={()=> console.log(response)} className="submit">Loading..</button> }
            {document && !response.isPending && images.length >= (document.premium.status === 'active' ? 10 : 5 ) && <button className="submit" disabled>Maximum Image Reached</button> }
            {document && !response.isPending && images.length < (document.premium.status === 'active' ? 10 : 5 ) &&
                <button onClick={()=> fileRef.current.click()} className="submit">Add Item Image</button>
            }
            <br />
            {/* {images && images.map(img => <img key={img.original} src={img.original} /> )} */}
        </div>
    )
}
