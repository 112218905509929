import { projectFirestore } from "../firebase/config"

import * as geofire from 'geofire-common'

export const useGeoFire = () => {


    // function getAllLocationsWithinRadius({ center, distance, collection }){
    //     // const center = [43.748618, -79.486343];
    //     const ret = []
    //     const radiusInM = distance * 1000;
    
    //     const bounds = geofire.geohashQueryBounds(center, radiusInM);
    //     const promises = [];
    //     for (const b of bounds) {
    //     const q = projectFirestore.collection(collection)
    //         .orderBy('geohash')
    //         .startAt(b[0])
    //         .endAt(b[1]);
    
    //         promises.push(q.get());
    //     }
    
    //     // console.log('promises', promises)
    //     // Collect all the query results together into a single list
    //     Promise.all(promises).then((snapshots) => {
    //         const matchingDocs = [];
    //         for (const snap of snapshots) {
    //             // console.log('snap', snap)
    
    //             for (const doc of snap.docs) {
    //                 const location = doc.get('location')
    //                 const lat = location.lat
    //                 const lng = location.lng
    
    //                 const distanceInKm = geofire.distanceBetween([lat, lng], center);
    //                 const distanceInM = distanceInKm * 1000;
    //                 if (distanceInM <= radiusInM) {
    //                     matchingDocs.push(doc);
    //                 }
    //             }
    //         }
    
    //         return matchingDocs;
    //     }).then((matchingDocs) => {
    //     // Process the matching documents
    //     // ...
    //         // console.log(matchingDocs)
    //         matchingDocs.forEach(doc => {
    //             console.log(doc.data())
    //             ret.push(doc.data())
    //         })
    //     });
    //     return ret
    // }

    function getAllBusinessWithinRadius(_center, distance, limit=200){
        return new Promise((res, rej)=>{
            const center = [_center.lat, _center.lng];
            const ret = []
            const radiusInM = distance * 1000;
        
            const bounds = geofire.geohashQueryBounds(center, radiusInM);
            const promises = [];
            for (const b of bounds) {
            const q = projectFirestore.collection('business')
                .orderBy('geohash')
                .startAt(b[0])
                .endAt(b[1])
                .limit(limit)
                ;
        
                promises.push(q.get());
            }
            Promise.all(promises).then((snapshots) => {
                const matchingDocs = [];
                for (const snap of snapshots) {
                    for (const doc of snap.docs) {
                        const about = doc.get('about')
                        const location = about.location
                        const lat = location.lat
                        const lng = location.lng
        
                        const distanceInKm = geofire.distanceBetween([lat, lng], center);
                        const distanceInM = distanceInKm * 1000;
                        if (distanceInM <= radiusInM) {
                            matchingDocs.push(doc);
                        }
                    }
                }
                return matchingDocs;
            }).then((matchingDocs) => {
                matchingDocs.forEach(doc => {
                    // console.log(doc)
                    ret.push({...doc.data(), id: doc.id })
                })
                res(ret)
            })
            .catch(err => {
                rej(err.message)
            })
        });
    }

    function getHash(latlng){
        return geofire.geohashForLocation( [latlng.lat, latlng.lng] );
    }

    function getDistanceBetween(location1, location2){
        return geofire.distanceBetween(location1, location2);
    }

    return { getAllBusinessWithinRadius, getHash, getDistanceBetween }
}