import { useWebCat } from "../../../../hooks/useWebCat"
import Select from 'react-select'
import { getCategoryLabelFromValue, REACT_SELECT_STYLE } from "../../../../helper/helper"
import { useDocument } from "../../../../hooks/useDocument"
import { useEffect, useState } from "react"
import { useFirestore } from "../../../../hooks/useFirestore"

export default function BMICategory({ businessid, itemid }) {

    
    const { document } = useDocument('business', businessid)
    const { document: item } = useDocument('items', itemid)

    const [storeCategoryData, setStoreCategoryData] = useState([])
    const [storeCategoryValue, setStoreCategoryValue] = useState([])
    const [webCategoryValue, setWebCategoryValue] = useState([])


    const { webcatui, value: webcatValue } = useWebCat(webCategoryValue)
    const { updateDocument } = useFirestore('items')

    // console.log(item)


    const handleStoreCatChange =(option)=>{
        console.log(option);
        setStoreCategoryValue(option)
    }

    const saveCategoriesClick =()=>{
        const doc = []
        console.log(storeCategoryValue)
        storeCategoryValue.length && storeCategoryValue.forEach(cat => {
            doc.push(cat.value)
        })
        webcatValue.length && webcatValue.forEach(cat => {
            doc.push(cat.value)
        })

        updateDocument(itemid, {category: doc})
        // console.log(doc)
    }

    // console.log(storeCategoryValue, webCategoryValue)

    useEffect(() => {
        setStoreCategoryData([])

        if(document){
            document.categories.forEach(data => {
                // console.log(data)
                setStoreCategoryData(ps => [...ps, {...ps, 
                    value: data.id,
                    label: data.name
                }])
            })
        }
    }, [document])

    useEffect(() => {
        setWebCategoryValue([])
        setStoreCategoryValue([])

        if(item && storeCategoryData){
            item.category.length > 0 && item.category.forEach(cat => {
                // console.log(cat.length, cat.length > 2);
                if(cat.length > 2){
                    storeCategoryData.forEach(data => {
                        if(data.value === cat){
                            // console.log('from store', data.label, cat);
                            setStoreCategoryValue(ps => [...ps, {...ps, 
                                value: cat,
                                label: data.label
                            }])
                        }
                    })
                }else{
                    const label = getCategoryLabelFromValue(cat);
                    // console.log('from web', label, cat); 
                    setWebCategoryValue(ps => [...ps, {...ps, 
                        value: cat,
                        label
                    }])
                }
            })
        }
    }, [item, storeCategoryData])

    return (
        <div className="wizard-widget category flex-col-center-start">
            <h2>Categories</h2>
            <br />
            <span style={{alignSelf: 'flex-start'}}>Lezzt Categories</span>
            <div className="webcat flex-row-center-between">
                {webcatui}
            </div>
            <p className="subtitle" style={{alignSelf: 'flex-start'}}>Select up to 4 categories</p>
            <br />
            <span style={{alignSelf: 'flex-start'}}>Your Business's Categories</span>
            <div className="webcat flex-row-center-between">
                <Select 
                    options={storeCategoryData}
                    onChange={option => handleStoreCatChange(option)}
                    placeholder="Select up to 4 categories"
                    isMulti
                    value={storeCategoryValue}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null, ClearIndicator:() => null }}
                    styles={REACT_SELECT_STYLE}
                    menuPosition={'fixed'} 
                />
            </div>
            <p className="subtitle" style={{alignSelf: 'flex-start'}}>Select as many</p>
            <br />
            <button onClick={saveCategoriesClick} className="submit">Save Categories</button>

        </div>
    )
}
