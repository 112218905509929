import { useEffect, useState } from "react"


export default function BMIPriceWidget({ data, passData, handleDelete }) {

    const [isOnEdit, setIsOnEdit] = useState(false)

    const [size, setSize] = useState("")
    const [note, setNote] = useState("")
    const [amount, setAmount] = useState(0)

    const handleSubmit = ()=>{
        passData({
            id: data.id,
            size,
            note,
            amount
        })
        setIsOnEdit(false)
    }

    const handleDeleteClick =()=>{
        handleDelete(data.id)
        setIsOnEdit(false)
    }

    useEffect(() => {
        if(data){
            setSize(data.size)
            setNote(data.note)
            setAmount(data.amount)
        }
    }, [data])

    

    return (
        <div className={`price-widget ${isOnEdit ? "onEdit" : "" }`}>
            <div className="header w100 flex-row-center-between">
                <input value={size} onChange={e=> setSize(e.target.value)} type="text" placeholder="Item Name" disabled={!isOnEdit} />
                {!isOnEdit && <>
                    <img onClick={()=> setIsOnEdit(true)} src="/icons/edit_black_48dp.svg" alt="" />
                </>}
                {isOnEdit && <>
                    <img onClick={handleDeleteClick} src="/icons/delete_black_48dp.svg" alt="" />
                    <img onClick={handleSubmit} src="/icons/save_black_48dp.svg" alt="" />
                </>}
            </div>
            {isOnEdit && 
                <div className="editable w100 flex-col-center-start">
                    <p className="subtitle" style={{alignSelf: "flex-start"}}>Note</p>
                    <textarea value={note} onChange={e=> setNote(e.target.value)} rows="1" placeholder="eg. includes 2 bottles of water, extra cheese etc., " ></textarea>
                    <p className="subtitle" style={{alignSelf: "flex-start"}}>Amount</p>
                    <div className="amount w100 flex-row-center-between">
                        <img src="/icons/attach_money_black_48dp.svg" alt="" />
                        <input value={amount} onChange={e=> setAmount(e.target.value)} type="number" />
                    </div>
                </div>
            }

        </div>
    )
}
