import { useState } from "react"
import { useEffect } from "react"
import { FaTimes } from "react-icons/fa"
import { useChikkas } from "../../model/useChikkas"
import StoreBasket from "../store/StoreBasket"
import { useFirestore } from "../../hooks/useFirestore"
import { useDocument } from "../../hooks/useDocument"
import { useAuthContext } from '../../hooks/useAuthContext'
import usePrompt from '../../hooks/usePrompt'
import { useNavigate } from "react-router-dom"
import { decodeCipher, handleBusinessItemRating, rngPassword } from "../../helper/helper"
import { projectFirestore, timestamp } from "../../firebase/config"
import ChikkaRatingWidget from "./ChikkaRatingWidget"

export default function ChikkaOptions({ dispatch, state }) {

    const cipher = decodeCipher(state.currentMessage)
    const { user } = useAuthContext()
    
    const { orderList, chikkas } = useChikkas()
    const { updateDocument, deleteDocument } = useFirestore('itemchat')
    const { updateDocument: updateBusinessDoc } = useFirestore('business') 
    const { updateDocument: updateUserInfoDoc } = useFirestore('userinfo') 
    const { updateDocument: updateItemDoc } = useFirestore('items') 
    const { document: businessDoc } = useDocument('business', cipher.businessid)
    const { document: userinfoDoc } = useDocument('userinfo', user.uid)

    const { prompt, promptChoice } = usePrompt()
    const navigate = useNavigate()

    const [currentOrder, setCurrentOrder] = useState(null)
    const [currentChikka, setCurrentChikka] = useState(null)

    const [deliveryAmount, setDeliveryAmount] = useState(0)
    const [discountType, setDiscountType] = useState('')
    const [discountPayload, setDiscountPayload] = useState(0)
    const [orderTotal, setOrderTotal] = useState(0)
    const [orderSubTotal, setOrderSubTotal] = useState(0)
    const [orderDiscount, setOrderDiscount] = useState(0)

    const [starItem, setStarItem] = useState('')
    
    const { document: itemDoc } = useDocument('items', starItem)

    

    useEffect(() => {
        if(chikkas){
            chikkas.forEach(chikka => {
                if(chikka.id === state.currentMessage){
                    setCurrentChikka(chikka)
                    setDeliveryAmount(chikka.delivery)
                    setDiscountType(chikka.discount.type)
                    setDiscountPayload(chikka.discount.payload)
                }
            })
        }
    }, [chikkas]);

    useEffect(() => {
        // console.log(orderList)
        if(orderList && orderList.length > 0){
            orderList.forEach(ol => {
                // console.log(ol, ol[0].chikkaID, state.currentMessage, ol.chikkaID === state.currentMessage);
                if(ol[0].chikkaID === state.currentMessage){
                    // console.log(ol)
                    setCurrentOrder(ol)
                    let x = 0
                    ol.forEach(order => {
                        order.options.forEach(option => {
                            x += parseFloat(option.price.amount)
                            option.customization.forEach(cust => {
                                x += parseFloat(cust.amount)
                            })
                        })
                    })
                    setOrderSubTotal(x)
                    x += parseFloat(deliveryAmount)
                    if(discountType === 'amount'){
                        x -= parseFloat(discountPayload)
                    }else if(discountType === 'percent'){
                        // console.log(discountPayload, discountPayload / 100)
                        // console.log(parseFloat((discountPayload / 100) * x))
                        const l = parseFloat((discountPayload / 100) * x)
                        setOrderDiscount(l)
                        x -= l
                    }
                    setOrderTotal(x)
                }
            })
        }
    }, [orderList, deliveryAmount, discountType, discountPayload]);


    const handleBasketDeleteItem =(id, chikkaID)=>{
        // console.log('handleBasketDeleteItem', id);
        let itemChatDoc
        chikkas.forEach(ol => {
            if(ol.id === chikkaID){
                itemChatDoc =  ol
            }
        })
        const newOptions = itemChatDoc.itemOptions.filter(option => option.id !== id)
        // console.log(newOptions);

        let newItems = [...itemChatDoc.items]
        itemChatDoc.items.forEach(item => {
            let gate = true
            newOptions.forEach(option => {
                // console.log(option.itemid, item, option.itemid === item)
                if(option.itemid === item){
                    gate = false
                }
            })

            if(gate){
                // console.log('remove', item);
                newItems = newItems.filter(zitem => {
                    // console.log(zitem, item, zitem !== item)
                    return zitem !== item
                })
            }
        })
        // console.log(newOptions, newItems)
        updateDocument(itemChatDoc.id, {itemOptions: newOptions, items: newItems})
    }
    const handleBasketDeleteCustomization =(id, custid, chikkaID)=>{
        // console.log('handleBasketDeleteCustomization', orderList, id, custid, chikkaID);
        let itemChatDoc
        chikkas.forEach(ol => {
            if(ol.id === chikkaID){
                itemChatDoc =  ol
            }
        })
        const selOp = itemChatDoc.itemOptions.filter(option => option.id === id)
        // const newSelOp = {...selOp[0], customization:  selOp[0].customization.filter(a => a !== custid) }
        // const oldItemOptions = itemChatDoc.itemOptions.filter(option => option.id !== id)
        const newItemOptions = [...itemChatDoc.itemOptions.filter(option => option.id !== id), {...selOp[0], customization:  selOp[0].customization.filter(a => a !== custid) }]
        updateDocument(itemChatDoc.id, {itemOptions: newItemOptions})
    }

    function isOwner(){
        // console.log(user.uid, currentChikka.owner, user.uid === chikkas.owner)
        return user.uid === currentChikka.owner
    }
    

    // console.log(currentChikka);

    const handleDeliveryUpdateClick =()=>{
        // console.log(currentChikka)
        if(!isNaN(deliveryAmount) && currentChikka.assignedUsers.length >= 2){
            updateDocument(currentChikka.id, {delivery: deliveryAmount})
        }
    }
    const handleDiscountUpdateClick =()=>{
        // console.log(currentChikka)
        if(!isNaN(discountPayload) && currentChikka.assignedUsers.length >= 2){
            updateDocument(currentChikka.id, {discount: {
                type: discountType,
                payload: discountPayload,
                note: ''
            }})
        }
    }
    const handleCancelOrderClick =()=>{
        const title = isOwner() ? currentChikka.assignedUsers.length >= 2 ? 'Cancelling Order will Delete the order and the chat. Proceed?' : "Confirm Delete Order" : 'Cancelling Order will leave the chat. Proceed?'
        console.log(title)
        promptChoice({title})
        .then(()=>{
            if(isOwner()){
                console.log('owner wants to cancel order')
                deleteDocument(currentChikka.id)
                dispatch({type: 'CANCEL_ORDER'})
            }else{
                console.log('buyer wants to cancel order')
                const ownerid = currentChikka.owner
                updateDocument(currentChikka.id, {assignedUsers: [ownerid] })
                dispatch({type: 'CANCEL_ORDER'})
            }

        })
        .catch(()=>{
            console.log('user cancelled')
        })
    }

    const handleOrderCompleteClick =()=>{
        promptChoice({title: "Are you sure you want to complete the order?"})
        .then(()=>{
            const storeOrderObj = {
                id: rngPassword(),
                chikkaID: currentChikka.id,
                order: currentOrder,
                delivery: currentChikka.delivery,
                discount: currentChikka.discount,
                buyer: currentChikka.buyer,
                buyerInfo: currentChikka.userinfo[currentChikka.buyer],
                createdAt: timestamp.fromDate(new Date()),
            }

            let newItemRatingObj = []
            currentChikka.items.forEach(item => {
                let name = ''
                orderList.forEach(order => {
                    order.forEach(ord => {
                        if(ord.id === item){
                            name = ord.name
                        }
                    })
                })
                newItemRatingObj.push({
                    itemid: item,
                    name,
                    rated: false
                })
            })
            let newUserInfoOrderObj = []
            if(userinfoDoc.orders){
                if(userinfoDoc.orders.length > 0){
                    newUserInfoOrderObj = [...userinfoDoc.orders, storeOrderObj]
                }
            }else{
                newUserInfoOrderObj = [storeOrderObj]
            }

            updateUserInfoDoc(currentChikka.buyer, {orders: newUserInfoOrderObj})
            // console.log(orderList)
            const newBusinessOrder = [...businessDoc.orders, storeOrderObj]
            updateBusinessDoc(cipher.businessid, {orders: newBusinessOrder})
            // updateUserInfoDoc()
            updateDocument(currentChikka.id, {completed: true, ratings: newItemRatingObj})
            // console.log(newItemRatingObj)
        })
        .catch(()=>{
            console.log('user cancelled')
            
            
        })
    }

    const handleCloseOrderClick =()=>{
        promptChoice({title: 'You wont be able to access the chat once closed. Proceed?'})
        .then(()=>{
            if(isOwner()){
                console.log('owner wants to close order')
                if(currentChikka.assignedUsers.length >= 2){
                    const buyerid = currentChikka.buyer
                    updateDocument(currentChikka.id, {assignedUsers: [buyerid] })
                    dispatch({type: 'CANCEL_ORDER'})
                }else{
                    deleteDocument(currentChikka.id)
                }
                dispatch({type: 'CANCEL_ORDER'})
            }else{
                console.log('buyer wants to close order')
                if(currentChikka.assignedUsers.length >= 2){
                    const ownerid = currentChikka.owner
                    updateDocument(currentChikka.id, {assignedUsers: [ownerid] })
                }else{
                    deleteDocument(currentChikka.id)
                }
                dispatch({type: 'CANCEL_ORDER'})
            }

        })
        .catch(()=>{
            console.log('user cancelled')
        })
    }

    const handleChikkaGetRating =(stars, itemid, name)=>{
        // console.log(stars, itemid)
        setStarItem(itemid)
        const rngID = rngPassword()
        // console.log(itemDoc)
        itemDoc && promptChoice({title: `rate ${stars} stars to ${name}? Cannot undo later. Proceed?`})
        .then(()=> {
            // console.log('proceed');
            const itemRating = {
                id: rngID,
                uid: user.uid,
                rating: stars,
                createdAt: timestamp.fromDate(new Date())
            }
            const newItemRatingObj = [...itemDoc.ratings, itemRating]
            // console.log(newItemRatingObj)
            updateItemDoc(itemid, {ratings: newItemRatingObj})

            const newChikkaRatingObj = [...currentChikka.ratings]
            newChikkaRatingObj.forEach((r, index) => {
                if(r.itemid === itemid){
                    newChikkaRatingObj[index].rated = true
                }
            })
            updateDocument(currentChikka.id, {ratings: newChikkaRatingObj})
            

            const newBusinessRatingObj = handleBusinessItemRating(businessDoc.itemratings, {
                id: rngID,
                itemid,
                stars,
                name,
                createdAt: timestamp.fromDate(new Date()),
                uid: user.uid,
            })
            // const newBusines
            // console.log(newBusinessRatingObj)
            updateBusinessDoc(cipher.businessid, {itemratings: newBusinessRatingObj })


        })
        .catch(()=>{
            console.log('user cancelled')
        })
    }

    // console.log(currentChikka)

    return (
        <div className="chikka-options">
            {prompt}
            <FaTimes className="close" onClick={()=> dispatch({type: 'TOGGLE_OPTION'})} />
            
            <div className="item-details flex-col-center-start">
                <button onClick={()=> navigate(`/store/${currentChikka.businessid}`)} >Visit Store</button>
               
               {currentChikka && user.uid === currentChikka.buyer && currentChikka.completed && 
                    <div className="ratings flex-col-center-start">
                        <span className="title">Rate your Order</span>
                    
                        {currentChikka.ratings.map(ratings => {
                            return <ChikkaRatingWidget key={ratings.itemid} itemid={ratings.itemid} name={ratings.name} rated={ratings.rated} getRating={handleChikkaGetRating} />
                        })}
                        <span className="footer error">You will not be able to rate items once order is closed.</span>
                    </div>
                }

                {currentOrder && currentOrder.map(ol => {
                    return <StoreBasket 
                        key={ol.id} 
                        data={ol} 
                        deleteItem={handleBasketDeleteItem} 
                        deleteCustomization={handleBasketDeleteCustomization} 
                    />
                })}

                {currentChikka && <>

                    {isOwner() && <>
                        <div className="option flex-col-center-start">
                            <span className="title">Delivery ($)</span>
                            <input type="number" value={deliveryAmount} onChange={e=> setDeliveryAmount(e.target.value)} />
                            <button onClick={handleDeliveryUpdateClick}>Update</button>
                        </div>

                        <div className="option flex-col-center-start">
                            <span className="title">Discount</span>
                            <div className="type flex-row-center-between">
                                <span onClick={()=> setDiscountType('amount')} className={discountType === 'amount' ? "active" : ""}>amount ($)</span>
                                <span onClick={()=> setDiscountType('percent')} className={discountType === 'percent' ? "active" : ""}>percent (%)</span>
                            </div>
                            <input type="number" value={discountPayload} onChange={e=> setDiscountPayload(e.target.value)} />
                            <button onClick={handleDiscountUpdateClick}>Update</button>
                        </div>

                        <div className="option flex-col-center-start">
                            <span className="title">Order Total</span>
                            <div className="subtitle w100 flex-row-center-between">
                                <span>Subtotal</span>
                                <span>${orderSubTotal.toFixed(2)}</span>
                            </div>
                            <div className="subtitle w100 flex-row-center-between">
                                <span>Delivery</span>
                                <span>${deliveryAmount}</span>
                            </div>
                            <div className="subtitle discount w100 flex-row-center-between">
                                <span>Discount</span>
                                <span>${orderDiscount.toFixed(2)}</span>
                            </div>
                            <input type="number" value={orderTotal.toFixed(2)} onChange={e=> setOrderTotal(e.target.value)} disabled/>
                        </div>

                        {!currentChikka.completed && <button onClick={handleCancelOrderClick} className="cancel">{currentChikka.assignedUsers.length >= 2 ? 'Cancel Order' : 'Delete Order'}</button>}
                        {currentChikka.assignedUsers.length >= 2 && !currentChikka.completed && <button onClick={handleOrderCompleteClick} className="complete">Complete Order</button>}
                        
                        {currentChikka.completed && <span className="note error">Your Customer wont be able to start a new order until you close the current order.</span> }
                        {currentChikka.completed && <button onClick={handleCloseOrderClick} >Close Order</button> }
                    </>}

                    {!isOwner() && <>
                        <div className="option flex-col-center-start">
                            <span className="title">Delivery ($)</span>
                            <input type="text" value={deliveryAmount === 0 ? 'Free Delivery' : deliveryAmount} readOnly />
                        </div>

                        {discountPayload > 0 && 
                            <div className="option flex-col-center-start">
                                <span className="title">Discount</span>
                                <div className="type flex-row-center-between">
                                    <span className={discountType === 'amount' ? "active" : ""}>amount ($)</span>
                                    <span className={discountType === 'percent' ? "active" : ""}>percent (%)</span>
                                </div>
                                <input type="number" value={discountPayload} readOnly disabled />
                            </div>
                        }

                        <div className="option flex-col-center-start">
                            <span className="title">Order Total</span>
                            <div className="subtitle w100 flex-row-center-between">
                                <span>Subtotal</span>
                                <span>${orderSubTotal.toFixed(2)}</span>
                            </div>
                            <div className="subtitle w100 flex-row-center-between">
                                <span>Delivery</span>
                                <span>${deliveryAmount}</span>
                            </div>
                            {discountPayload > 0 && 
                                <div className="subtitle discount w100 flex-row-center-between">
                                    <span>Discount</span>
                                    <span>${orderDiscount.toFixed(2)}</span>
                                </div>
                            }
                            <input type="number" value={orderTotal.toFixed(2)} readOnly disabled/>
                        </div>

                        {!currentChikka.completed && <button onClick={handleCancelOrderClick} className="cancel">Cancel Order</button>}

                        
                        {currentChikka.completed && <button onClick={handleCloseOrderClick} >Close Order</button> }
                    </>}


                    <div className="blank-widget"></div>

                </>}

            </div>
            
        </div>
    )
}
