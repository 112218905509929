import { useEffect, useState } from "react"
import { useDocument } from "../../../../hooks/useDocument"
import { useFirestore } from "../../../../hooks/useFirestore"



export default function BMIDetails({ businessid, itemid }) {

    
    const { updateDocument } = useFirestore('items')
    const { document, error } = useDocument('items', itemid)


    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [alert, setAlert] = useState("")
    const [caloriesMin, setCaloriesMin] = useState("")
    const [caloriesMax, setCaloriesMax] = useState("")
    const [tags, setTags] = useState([])

    const [currentTags, setCurrentTags] = useState([])


    useEffect(() => {
        
        if(document){
            setName(document.details.name)
            setDescription(document.details.description)
            setAlert(document.details.alert)
            setCaloriesMin(document.details.calories.min)
            setCaloriesMax(document.details.calories.max)
            setTags(document.details.tags ? document.details.tags : [])
            setCurrentTags(document.details.tags.map(tag => `${tag}`))
        }
    }, [document]);

    function getNewDetailsData(){
        return {
            name,
            description,
            alert,
            calories: {
                min: parseInt(caloriesMin),
                max: parseInt(caloriesMax)
            },
            tags
        }
    }

    const handleTagChange =(e)=>{
        setTags(e.target.value.split(',').filter(t => {  return t !== '' }))
        setCurrentTags(e.target.value)
    }

    

    const handleSubmit =()=>{
        // console.log(getNewDetailsData())
        updateDocument(itemid, {details: getNewDetailsData()})
    }

    return (
        <div className="wizard-widget flex-col-center-start">
            {document && !error && error !== 'FAILED_DOCUMENT' ? <>
                <h2>Details</h2>
                <br />
                <div className="field-single">
                    <span>Item Name</span>
                    <input value={name} onChange={e => setName(e.target.value)} type="text" placeholder="Max 100 characters" />
                </div>
                <div className="field-single">
                    <span>Description</span>
                    <textarea value={description} onChange={e => setDescription(e.target.value)} placeholder="Max 500 characters"></textarea>
                </div>
                <div className="field-single">
                    <span>Alert</span>
                    <input value={alert} onChange={e => setAlert(e.target.value)} placeholder="eg. ( may include peanuts and eggs )" />
                    <p className="subtitle alert">Indicate if there are any allergen ingredients present in your item.</p>
                </div>
                <div className="field-double">
                    <span>Calories</span>
                    <div className="fields w100 flex-row-center-between">
                        <input type="number" placeholder="Min" value={caloriesMin} onChange={e => setCaloriesMin(e.target.value)} />
                        <input type="number" placeholder="Max" value={caloriesMax} onChange={e => setCaloriesMax(e.target.value)}/>
                    </div>
                    <p className="subtitle info">Approximate Calorie Content of the item</p>
                </div>
                <div className="field-single">
                    <span>Tags</span>
                    <textarea onChange={handleTagChange} placeholder="eg. ( Soup,Spicy,Beef,Asian ) without the parenthesis and spaces." value={currentTags}></textarea>
                    <p className="subtitle info">Tags will be used for searching optimization. Use a comma ( , ) separator for each tag. Improper use of tag will result in items not showing up on searches. Please Message us if you need further assistance.</p>
                </div>
                <button className="submit" onClick={handleSubmit} >Save Details</button>
            </> : <div className="error">something went wrong.. Please refresh the page. if the problem persists, please contact us!</div>
            }
        </div>
    )
}
