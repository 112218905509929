import './BusinessManageItems.css'
import { Link } from 'react-router-dom'
import { getProperItemImage, rngPassword } from '../../../../helper/helper'
import { useParams } from 'react-router'
import { useAuthContext } from '../../../../hooks/useAuthContext'
import { useCollection } from '../../../../hooks/useCollection'
import { useEffect, useState } from 'react'

export default function BusinessManageItems() {
    const { user } = useAuthContext()
    const { businessid } = useParams()
    const { documents } = useCollection('items',[['businessid', '==', businessid]])

    const [isListView, setIsListView] = useState(true)

    const [filteredDocs, setFilteredDocs] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    function createNewWizardToken(iid){
        const btoa = str => new Buffer.from(str, 'binary').toString('base64');
        const tokenObj = {
            token: user.uid,
            iid: iid,
            bid: businessid
        }
        const tokenstr = JSON.stringify(tokenObj);
        return escape(btoa(tokenstr));
    }

    // console.log(filteredDocs)

    useEffect(() => {
        setFilteredDocs(null)

        if(documents){
            setFilteredDocs(documents.filter(doc => doc.details.name.toLowerCase().includes(searchQuery.toLocaleLowerCase())))
        }

    }, [documents, searchQuery])    

    return (
        <div className=" items business-manage-container flex-col-center-center">
            <div className="items-header-search flex-row-center-between">
            <input value={searchQuery} onChange={e=> setSearchQuery(e.target.value) } type="text" placeholder='Search Items' />
                <img src="/icons/search_black_48dp.svg" alt="" />
            </div>
            <div className="items-header-nav flex-row-center-center">
                <div onClick={()=> setIsListView(true)} className={`${isListView ? "active" : ""} list flex-row-center-center`}>
                    <span>List View</span>
                    <img src="/icons/view_list_white_48dp.svg" alt="" />
                </div>
                <span className="divider"></span>
                <div onClick={()=> setIsListView(false)} className={`${isListView ? "" : "active"} grid flex-row-center-center`}>
                    <img src="/icons/grid_view_white_48dp.svg" alt="" />
                    <span>Grid View</span>
                </div>
            </div>

            {isListView && documents &&
                <div className=" items-container-list flex-col-center-start">
                    <Link to={`/itemwizard/${createNewWizardToken(rngPassword())}`} className="widget add">
                        <img src="/icons/add_circle_white_48dp.svg" alt="" />
                        <span>Add Item</span>
                    </Link>
                    {
                        filteredDocs && filteredDocs.map(item => {
                            let img = getProperItemImage(item.images)

                            return <Link key={item.id} to={`/itemwizard/${createNewWizardToken(item.id)}`} className="widget">
                                <img src={img} alt=""/>
                                <span>{item.details.name.length ? item.details.name : "Unnamed Item"}</span>
                            </Link>
                        })
                    }
                </div> 
            } 

            {!isListView && documents && 
                <div className=" items-container">
                    <Link to={`/itemwizard/${createNewWizardToken(rngPassword())}`} className="widget add">
                        <img src="/icons/add_circle_white_48dp.svg" alt="" />
                        <span>Add Item</span>
                    </Link>
                    {
                        filteredDocs && filteredDocs.map(item => {
                            let img = '' 
                            if(item.images.length > 0){
                                item.images.forEach(image => {
                                    if(image.isProfile){
                                        img = image.url
                                    }
                                })
                                if(img === ''){
                                    img = item.images[0].url
                                }
                            }else{
                                img = "/logo.png"
                            }

                            return <Link key={item.id} to={`/itemwizard/${createNewWizardToken(item.id)}`} className="widget">
                                <img src={img} alt=""/>
                                <span>{item.details.name.length ? item.details.name : "Unnamed Item"}</span>
                            </Link>
                        })
                    }
                </div>  
            }
            
            

        </div>
    )
}
