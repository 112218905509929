import React from 'react'

function PromotionsWidget(props) {
    return (
        <div className="widget">
            <div className="fgcolor flex-col-start-start">
            <span className="title">{props.title}</span>
            <span className="subtitle">{props.subtitle}</span>
            </div>
            <img src={props.img} alt="" />
        </div>
    )
}

export default PromotionsWidget
