import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { getProperItemImage } from "../../../helper/helper";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useToast } from "../../../hooks/useToast";


export default function BusinessManageCategoryWidget({ cat, documents, setData, businessid, deleteCat }) {

    const { toast, showToast } = useToast(2000)
    // console.log(documents);
    const { user } = useAuthContext()
    const [name, setName] = useState(cat.name)

    const deleteCatClick =(catid)=>{
        const x = documents.filter(item => {
            return item.category.includes(catid)
        }).length
        if(x){
            console.log('cannot delete, theres still items here.')
            showToast({message: "Cannot Delete while theres still items in the Category."})
        }else{
            // console.log('proceed delete')
            deleteCat(catid)
        }
    }

    function createNewWizardToken(iid){
        const btoa = str => new Buffer.from(str, 'binary').toString('base64');
        const tokenObj = {
            token: user.uid,
            iid: iid,
            bid: businessid
        }
        const tokenstr = JSON.stringify(tokenObj);
        return escape(btoa(tokenstr));
    }

    useEffect(() => {
        if(cat && documents){
            setData(ps => {
                // console.log('ps', ps, name, cat.id)
                const newarr = ps.map(element => {
                    // console.log(element)
                    if(element.id === cat.id){
                        return {...element, name}
                    }else{
                        return {...element}
                    }
                });
                // console.log('newarr', newarr);
                return newarr
            })
        }
        // eslint-disable-next-line
    }, [name])

    return (
        <div className="category-widget flex-col-center-start">
            {toast}
            <div className="header flex-row-center-between w100">
                <input type="text" value={name} onChange={e => setName(e.target.value)} />
                <img onClick={()=> deleteCatClick(cat.id)} src="/icons/delete_black_48dp.svg" alt="" />
            </div>
            <div className="content">
                {documents && documents.map(item => {
                    if(item.category.includes(cat.id)){
                        let img = getProperItemImage(item.images)
                        
                        return <Link to={`/itemwizard/${createNewWizardToken(item.id)}`} key={item.id} className="widget flex-col-center-center">
                            <img src={img} alt=""/>
                            <span>{item.details.name.length ? item.details.name : "no name"}</span>
                        </Link>
                    }else{
                        return null
                    }
                })}
                {documents && documents.filter(item => {
                    return item.category.includes(cat.id)
                }).length ? "" : <div className="empty">Empty</div> }
            </div>
            
        </div>
    )
}
