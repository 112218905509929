import { useState } from "react"
import CustomForm from "../../../../helper/CustomForm"
import BMIPriceWidget from "./BMIPriceWidget"
import { useFirestore } from "../../../../hooks/useFirestore"
import { rngPassword } from "../../../../helper/helper"
import { useDocument } from "../../../../hooks/useDocument"

export default function BMIPrice({ businessid, itemid }) {

    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const { updateDocument } = useFirestore('items')
    const { document } = useDocument('items', itemid)

    const [size, setSize] = useState("")
    const [note, setNote] = useState("")
    const [amount, setAmount] = useState(0)

    // console.log(document)


    const handlePopupSubmit =(e)=>{
        e.preventDefault()
        const priceObj = {
            id: rngPassword(),
            size,
            note,
            amount: parseFloat(amount)
        }

        updateDocument(itemid, {price: [...document.price, priceObj]})
        .then(()=>{
            setSize("")
            setNote("")
            setAmount(0)
            setIsPopupOpen(false)
        })
        .catch(err=>{
            console.log(err)
        })
    }

    const handlePriceUpdateSubmit =(priceObj)=>{
        // console.log(priceObj)
        let gate = false
        const newPriceObj = document.price.map(price => {
            if(price.id === priceObj.id){
                // console.log(price, priceObj);
                if(price.size !== priceObj.size){
                    gate = true
                }
                if(price.note !== priceObj.note){
                    gate = true
                }
                if(price.amount !== priceObj.amount){
                    gate = true
                }
                return priceObj
            }else{
                return price
            }
        })
        if(gate){
            updateDocument(itemid, {price: newPriceObj})
            console.log('updated')
        }
    }

    const handlePriceDeleteSubmit =(priceId)=>{
        // console.log(priceObj)
        const newPriceObj = []
        document.price.forEach(price => {
            if(price.id !== priceId){
                newPriceObj.push(price)
            }
        })
        updateDocument(itemid, {price: newPriceObj})
        // console.log(newPriceObj)
    }

    return (
        <div className="wizard-widget price flex-col-center-start">
            <h2>Prices</h2>
            <br />
            <div className="price-create w100">
                <button onClick={()=> setIsPopupOpen(!isPopupOpen)} className="submit">{isPopupOpen ? "Close" : "Create Size Portioning"}</button>
                {isPopupOpen && 
                    <CustomForm 
                        handleSubmit={handlePopupSubmit}
                        className="price-create-popup"
                        title="Create Size"
                    >
                        <div className="field">
                            <span>Size Name</span>
                            <input value={size} onChange={e=> setSize(e.target.value)} type="text" placeholder="eg. ( small, medium, large )" />
                        </div>
                        <div className="field">
                            <span>Short Note</span>
                            <textarea value={note} onChange={e=> setNote(e.target.value)} placeholder="eg. includes 2 bottles of water, extra cheese etc., " ></textarea>
                        </div>
                        <div className="field">
                            <span>Price ($)</span>
                            <input value={amount} onChange={e=> setAmount(e.target.value)} type="number" />
                        </div>
                        <button>Save</button>
                    </CustomForm>
                }
            </div>
            {!isPopupOpen && 
                <div className="price-widget-con w100 flex-col-center-start">
                    
                    {document && document.price.map(price => (
                        <BMIPriceWidget key={price.id} data={price} passData={handlePriceUpdateSubmit} handleDelete={handlePriceDeleteSubmit} />
                    ))}

                </div>
            }
        </div>
    )
}
