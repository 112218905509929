
import './Chikkas.css'

import { useReducer, useState } from 'react'
import ChikkaMessage from './ChikkaMessage'
import ChikkaOptions from './ChikkaOptions'
import ChikkaList from './ChikkaList'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useAuthContext } from '../../hooks/useAuthContext'


import { useChikkas } from '../../model/useChikkas'
import { useEffect } from 'react'

export const chikkaReducer =(state, action)=>{
    switch (action.type) {
        case 'TOGGLE_LIST':
            return { ...state, isListOpen: !state.isListOpen, currentMessage: null, messageType: null }
        case 'TOGGLE_OPTION':
            return { ...state, isOptionOpen: !state.isOptionOpen }
        case 'SET_CURRENT_MESSAGE':
            return { ...state, isListOpen: false, isOptionOpen: false, currentMessage: action.payload.id, messageType: action.payload.type }
        case 'CANCEL_ORDER':
            return { ...state, isListOpen: true, isOptionOpen: false, currentMessage: null, messageType: null }


        default:
            return state;
    }
}





export default function Chikkas() {
    const { isMobile } = useIsMobile()

    const { user } = useAuthContext()
    
    const [state, dispatch] = useReducer(chikkaReducer, {
        isListOpen: true,
        isOptionOpen: false,
        currentMessage: null,
        messageType: null
    })

    
    const { chikkas } = useChikkas()
    
    // console.log(isReady);

    useEffect(() => {
        if(chikkas){
            chikkas.forEach(chikka => {
                // if(chikka.id === state.currentMessage){
                //     setIsReady(true)
                // }
            })
        }
    }, [chikkas]);

    // const [isListOpen, setIsListOpen] = useState(true)
    // const [isOptionOpen, setIsOptionOpen] = useState(false)

    

    return (
        <div className='chikkas-wrapper flex-col-center-start'>

            <div className="chikkas-container flex-row-start-start">
                {state.isListOpen || !isMobile ? 
                    <ChikkaList dispatch={dispatch} state={state}  /> : null
                }
                
                {state.isOptionOpen &&
                    <ChikkaOptions dispatch={dispatch} state={state}  />
                }

                {state.currentMessage &&
                    <ChikkaMessage dispatch={dispatch} state={state}  />
                }

            </div>
        </div>
    )
}
