
import RestaurantWidgetCard from './RestaurantWidgetCard';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useRef } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile'
import { deliveryObjToText, sideScroll } from '../../helper/helper';

export default function RestaurantsWidget({ title, data, businessdata, itemdata }) {
    const { isMobile } = useIsMobile()
    const conRef = useRef()
    const scroll =(direction)=>{
        const container = conRef.current
        sideScroll(container, direction);
    }

    // console.log(businessdata);

    return (
        <div className="restaurants-widget flex-col-center-center">
            <div className="header flex-row-center-between">
                <span className="title">{title}</span>
                
                    <div className="actions flex-row-center-center">
                        <span>See All</span>
                        {!isMobile && <>
                            <FaAngleLeft className='enabled' onClick={()=> scroll('left')} />
                            <FaAngleRight className='enabled' onClick={()=> scroll('right')} />
                        </>}
                    </div>
                
            </div>
            <div ref={conRef} className={`content flex-${isMobile ? "col" : "row"}-center-start`}>
                {businessdata && businessdata.map(bid => {
                    {/* console.log(bid.id)
                    console.log(bid)
                    console.log(bid.itemratings.average) */}
                    return (
                        <>
                            {bid &&
                                <RestaurantWidgetCard 
                                    key={`${bid.id}-${title}`} 
                                    id={bid.id} 
                                    img={bid.about.bannerURL ? bid.about.bannerURL : "/defaultBanner.jpg"} 
                                    title={bid.about.name} 
                                    p1={parseFloat(bid.itemratings.average).toFixed(2)} 
                                    p2={`${bid.itemratings.count} ratings`}
                                    p3={deliveryObjToText(bid.about.delivery)} 
                                />
                            }
                        </>
                    )
                })}
            </div>
        </div>
    )
}


